import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@headlessui/react";
import Button from "../../../Button";
import BorderedText from "../../../BorderedText";
import { XIcon } from "@heroicons/react/outline";
import Modal from "../../../Modal/Modal";
import OverlappingTextInput from "../../../FormInputs/OverlappingTextInput";
import useAuth from "../../../../utils/useAuth";
import usePhase from "../../../../utils/usePhase";
import { useParams } from "react-router-dom";
import {
  createMediaFolder,
  updateMediaFolder,
} from "../../../../services/media";
import { toast } from "react-toastify";
import { REPORT_TYPE_BACKEND_MAPPER } from "../../../ClusterReportItems/constants";

const CreateOrEditFolder = ({ isOpen, handleSuccess, folder, onClose }) => {
  const [submitting, setSubmitting] = useState(false);
  const [folderName, setFolderName] = useState("");
  const auth = useAuth();
  const currentPhase = usePhase();
  const { currentPermission } = auth;
  const { clusterId, pcId, visitId, reportType } = useParams();

  useEffect(() => {
    setFolderName(folder?.name || "");
  }, [folder]);

  const handleInputChange = (e) => setFolderName(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    let func = folder ? updateMediaFolder : createMediaFolder;
    let funcParams = {
      phase: currentPhase,
      currentPermission,
      clusterId,
      pcId,
      visitId,
      reportType: reportType ? reportType : REPORT_TYPE_BACKEND_MAPPER.MEDIA,
      data: {
        name: folderName,
      },
      ...(folder && {
        folderId: folder.id,
      }),
    };
    let response = await func(funcParams);
    setSubmitting(false);
    if (response.isAxiosError) {
      toast.error("Something went wrong");
      return;
    }
    if (response && handleSuccess) {
      handleSuccess(response);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 lg:max-w-screen-lg sm:max-w-sm sm:w-full sm:p-6">
        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
          <Button
            type="button"
            aria-hidden="true"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </Button>
        </div>
        <div className="flex items-center justify-center mb-10">
          <BorderedText text={folder ? "Edit Folder" : "Create Folder"} />
        </div>

        <form onSubmit={handleSubmit}>
          <OverlappingTextInput
            onChange={handleInputChange}
            maxLength={200}
            placeholder="Folder Name"
            required={true}
            value={folderName}
          />
          <div className="flex items-center justify-center mt-10">
            <Button.Rounded disabled={submitting}>Save and Next</Button.Rounded>
          </div>
        </form>
      </Dialog.Panel>
    </Modal>
  );
};

CreateOrEditFolder.propTypes = {
  folder: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleSuccess: PropTypes.func,
};

CreateOrEditFolder.defaultProps = {
  folder: null,
  handleSuccess: () => {},
};

export default CreateOrEditFolder;
