import React, { useEffect, useState } from "react";
import BorderedText from "../../../../components/BorderedText";
import TaskItemTable from "./task-item-table";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import classNames from "classnames";

function TaskItem({ task }) {
  if (!task) return null;
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const { completed_count, id, title } = task;

  const cn = classNames(
    "bg-white px-5 py-6 rounded-xl",
    !completed_count && "print:hidden"
  );

  return (
    <div className={cn}>
      <div
        onClick={toggleIsOpen}
        className="flex items-center justify-between cursor-pointer"
      >
        <BorderedText className={"flex-1 break-after-avoid"} text={title} />
        <div className="flex items-center shrink-0 justify-between">
          {!!completed_count && (
            <p className="bg-[#FF9955] px-5 py-1 text-base rounded-2xl text-white">
              Meeting Done {completed_count}
            </p>
          )}
          {isOpen ? (
            <ChevronUpIcon
              className="ml-9 h-5 print:hidden w-5"
              aria-hidden="true"
            />
          ) : (
            <ChevronDownIcon
              className="ml-9 h-5 w-5 print:hidden"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="mt-5 print:hidden">
          <TaskItemTable task={task} />
        </div>
      )}
      <div className="mt-5 hidden print:block">
        <TaskItemTable task={task} />
      </div>
    </div>
  );
}

export default TaskItem;
