import classNames from "classnames";
import React from "react";
import TaskAction from "./task-action";
import TaskLink from "./task-link";

const String = ({ value }) => {
  return <p>{value}</p>;
};

const Items = {
  string: String,
  link: TaskLink,
  action: TaskAction,
};

function TaskItemTable({ task }) {
  const { headers, data } = task;
  const headerItems = headers.map((header, i) => {
    return (
      <th
        key={i}
        className="p-4 sticky print:static print:top-0 top-[80px] align-top text-center text-base mulish text-white border-r-2 border-white bg-[#4471B0]"
      >
        {header.name}
      </th>
    );
  });

  const rows = data.map((rowData, i) => {
    const row = rowData.map((rowItem, j) => {
      const Comp = Items[rowItem.type] || null;
      if (!Comp) return null;
      return (
        <td className="border-r-2 px-2 py-4 text-base border-white" key={j}>
          <Comp {...rowItem} />
        </td>
      );
    });

    const cn = classNames(
      "bg-[#F3F7FF] text-center",
      i < data.length - 1 && "border-b border-b-[#9DAED2]"
    );
    return (
      <tr className={cn} key={i}>
        {row}
      </tr>
    );
  });

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>{headerItems}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

export default TaskItemTable;
