import CalenderIcon from "images/calendar.svg";
import ParliamentImage from "images/parliament.svg";
import PencilIcon from "images/pencil.svg";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BorderedText from "../../components/BorderedText";
import Button from "../../components/Button";
import RemoveConfirmation from "../../components/RemoveConfirmation";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import LokSabhaVisitCreateOrUpdateForm from "../../components/LokSabha/LokSabhaVisitCreateOrUpdateForm";
import NotAuthorized from "../../components/NotAuthorized";
import Title from "../../components/Title";
import { parliamentConstituencies } from "../../services";
import { dateFormat } from "../../utils/helper";
import DustbinIcon from "images/dustbin-2.svg";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../utils/internalLinks";
import useAuth from "../../utils/useAuth";
import usePhase from "../../utils/usePhase";

const LokSabhaVisits = ({ visits, onDelete }) => {
  const { clusterId, pcId } = useParams();
  const currentPhase = usePhase();
  const visitsItem = visits.map((visit, index) => {
    const { id: visitId, start_date, end_date, percentage_complete } = visit;
    const visitCount = index + 1;
    const startDate = dateFormat(new Date(start_date), "dd-MM-yyyy");
    const endDate = dateFormat(new Date(end_date), "dd-MM-yyyy");
    const link = PARLIAMENT_CONSTITUENCY_LINKS.VISIT(
      clusterId,
      pcId,
      visitId,
      currentPhase
    );

    const handleDelete = () => onDelete(visitId);
    return (
      <div
        className="flex relative mt-5 first:mt-6 rounded-lg px-6 py-[18px] box-border justify-between border border-gray-400"
        key={visitId}
      >
        <div className="flex items-center">
          <p className="flex-initial w-32 mr-16 text-sm poppins font-semibold">
            Visit - {visitCount}
          </p>
          <p className="flex text-sm w-full">
            <img
              className="mr-3 w-4 h-4 block"
              src={CalenderIcon}
              alt="visit date"
            />
            <span className="font-semibold text-gray-500 mr-3">Date-</span>
            <span className="font-semibold text-[#163560]">{startDate}</span>
            <span className="font-semibold text-gray-500 mx-3">to</span>
            <span className="font-semibold text-[#163560]">{endDate}</span>
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <Button
            className="flex p-[7px] bg-[#F1F5FF] border border-[#4A6D9D] items-center box-border max-h-[34px]"
            as={Link}
            to={link}
          >
            <span className="text-sm poppins font-semibold text-[#163560] shrink-0">
              Edit
            </span>
            <img
              className="block w-4 h-4 object-contain ml-2.5"
              src={PencilIcon}
              alt="update"
            />
          </Button>
          <Button
            className="bg-[#F9ECEC] p-1.5 border border-[#EE2121]"
            onClick={handleDelete}
          >
            <img src={DustbinIcon} alt="delete" />
          </Button>
        </div>
      </div>
    );
  });

  return visitsItem;
};

const LokSabha = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const currentPhase = usePhase();
  const { currentPermission } = auth;
  const { clusterId, pcId } = useParams();
  const [pcDetails, setPcDetails] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [visits, setVisits] = useState([]);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [visitToBeRemoved, setVisitToBeRemoved] = useState(null);

  useEffect(() => {
    const getParliamentConstituentiesAndVisits = async () => {
      if (!currentPermission || !currentPhase || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      const getPcData =
        await parliamentConstituencies.fetchParliamentryConstituenciesDetails({
          currentPermission,
          clusterId,
          pcId,
          phase: currentPhase,
        });
      if (
        getPcData &&
        getPcData.isAxiosError &&
        getPcData?.response?.status === 401
      ) {
        setFetching(false);
        setIsUnauthorized(true);
        return;
      }
      const getPcVisits =
        await parliamentConstituencies.fetchParliamentryConstituenciesVists({
          currentPermission,
          clusterId,
          pcId,
          phase: currentPhase,
        });
      setFetching(false);
      setPcDetails(getPcData || null);
      setVisits(getPcVisits?.data?.data || []);
    };
    getParliamentConstituentiesAndVisits();
  }, [currentPermission, currentPhase]);

  const onSubmit = (newVisit) => {
    const newLink = PARLIAMENT_CONSTITUENCY_LINKS.VISIT(
      clusterId,
      pcId,
      newVisit.id,
      currentPhase
    );
    toast("Visit successfully created.");
    navigate(newLink);
  };

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  if (!pcDetails) return null;

  const toggleVisitDeleteModal = (visitId = null) =>
    setVisitToBeRemoved(visitId);

  const handleVisitDelete = async () => {
    if (deleting) return;
    setDeleting(true);
    const response =
      await parliamentConstituencies.deleteParliamentryConstituenciesVisit({
        currentPermission,
        clusterId,
        pcId,
        visitId: visitToBeRemoved,
        phase: currentPhase,
      });
    setDeleting(false);
    if (!response) {
      toast.error("Something went wrong");
      return;
    }
    setVisits(visits.filter((visit) => visit.id !== visitToBeRemoved));
    toggleVisitDeleteModal();
  };

  const removeDescription =
    visits.length === 1
      ? "Are you sure you want to delete last visit? This will remove all data for this Lok Sabha?"
      : "Are you sure you want to remove the visit?";
  return (
    <div>
      <Title
        title={`Lok Sabha - ${pcDetails.name}`}
        imageSrc={ParliamentImage}
        description="See earlier visits or add a new visit"
      />
      <div className="mt-9 bg-white rounded-lg shadow-md box-border p-5">
        <div className="pb-7 flex items-center">
          <LokSabhaVisitCreateOrUpdateForm afterSubmit={onSubmit} />
        </div>
        {visits && !!visits.length && (
          <div className="pt-7 border-t border-t-gray-200">
            <BorderedText text="Previous Visits-" />
            <LokSabhaVisits onDelete={toggleVisitDeleteModal} visits={visits} />
          </div>
        )}
      </div>
      <RemoveConfirmation
        onCancel={toggleVisitDeleteModal}
        onDelete={handleVisitDelete}
        title="Remove Visit"
        deleteBtnText={deleting ? "Deleting..." : "Delete"}
        deleting={deleting}
        description={removeDescription}
        isOpen={!!visitToBeRemoved}
      />
    </div>
  );
};

export default LokSabha;
