import React, { useState } from "react";
import { PencilIcon } from "@heroicons/react/outline";
import RadioGroup from "../../../../components/RadioGroup";
import { Field, Form, Formik } from "formik";
import TextArea from "../../../../components/FormInputs/TextArea";
import Button from "../../../../components/Button";
import useAuth from "../../../../utils/useAuth";
import { useVistarakReportTaskReview } from "../../../../libs/vistarak/hooks";
import { toast } from "react-toastify";
import ShowMoreText from "../../../../components/ShowMoreText";
import classNames from "classnames";

const RADIO_ITEMS = [
  {
    id: "approved",
    name: "Approved",
  },
  {
    id: "rejected",
    name: "Rejected",
  },
];

function TaskForm({ status, value, meetingId, onCancel }) {
  const auth = useAuth();
  const { currentPermission } = auth;

  const createCmd = useVistarakReportTaskReview({ currentPermission });
  return (
    <Formik
      initialValues={{
        approve:
          status === "not_reviewed" || status === "approved"
            ? "approved"
            : status,
        remark: value || "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        createCmd.mutate(
          {
            data: {
              approve: values.approve === "approved",
              remark: values.remark.trim(),
            },
            meetingId,
          },
          {
            onSuccess: (response) => {
              onCancel();
            },
            onError: () => {
              toast.error("Something went wrong!");
            },
            onSettled: () => setSubmitting(false),
          }
        );
      }}
    >
      {({ values, setFieldValue, isSubmitting }) => {
        const handleInputChange = (value) => setFieldValue("approve", value);
        return (
          <Form>
            <RadioGroup
              name="approve"
              className="flex-col space-x-0 space-y-2 items-start px-2"
              onChange={handleInputChange}
              value={values.approve}
              items={RADIO_ITEMS}
            />
            <Field
              maxLength={2000}
              name="remark"
              className="mt-2"
              as={TextArea}
            />
            <div>
              <Button.Rounded disabled={isSubmitting}>Save</Button.Rounded>
              <Button
                disabled={isSubmitting}
                className="ml-2"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

function TaskAction(props) {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const openForm = () => setIsFormOpen(true);
  const closeForm = () => setIsFormOpen(false);

  const { status_color_code, is_edit, status, value } = props;

  const style = {
    color: status_color_code,
  };

  const cn = classNames(
    "items-center flex",
    status === "not_reviewed" ? "justify-center" : "justify-between"
  );

  return (
    <div>
      {isFormOpen ? (
        <TaskForm
          status={status}
          value={value}
          meetingId={props.meeting_id}
          onCancel={closeForm}
        />
      ) : (
        <div>
          <div className={cn}>
            {status !== "not_reviewed" && (
              <p style={style} className="capitalize">
                {status}
              </p>
            )}
            {is_edit && (
              <PencilIcon
                onClick={openForm}
                className="w-5 h-5 cursor-pointer print:hidden"
              />
            )}
          </div>
          <div className="mt-4">
            <ShowMoreText
              textClassName="text-sm text-justify font-medium"
              linkClassName="text-sm font-medium"
            >
              <p className="text-sm text-justify font-medium">{value}</p>
            </ShowMoreText>
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskAction;
