import React, { useEffect, useState } from "react";
import qs from "qs";
import { useParams } from "react-router-dom";
import {
  useVistarakReportTaskOverview,
  useVistarakReportTaskReport,
} from "../../../libs/vistarak/hooks";
import FullPageLoader from "../../../components/Loaders/FullPageLoader";
import Overview from "./components/overview";
import Tasks from "./components/tasks";
import useAuth from "../../../utils/useAuth";
import { ERROR_STATUS_CODE } from "../../../utils/constants";
import NotAuthorized from "../../../components/NotAuthorized";
import Button from "../../../components/Button";

function Report() {
  const auth = useAuth();
  const { currentPermission } = auth;
  const [filter, setFilter] = useState(null);
  const [hasCheckedFilters, setHasCheckedFilters] = useState(false);
  const [taskStatus, setTaskStatus] = useState(null);

  useEffect(() => {
    const items = qs.parse(window.location.search.replace("?", ""));
    setFilter(items);
    setHasCheckedFilters(true);
  }, []);

  const { pcId, taskType } = useParams();

  const { data, isLoading, error } = useVistarakReportTaskOverview({
    currentPermission,
    params: {
      filter,
    },
    taskType: taskType,
    pcId,
    options: {
      enabled: !!hasCheckedFilters && !!taskType && !!pcId,
    },
  });

  const { data: tasks, isLoading: loading } = useVistarakReportTaskReport({
    currentPermission,
    params: {
      type: taskStatus,
      filter,
    },
    taskType,
    pcId,
    options: {
      enabled: !!hasCheckedFilters && !!taskType && !!taskStatus && !!pcId,
    },
  });

  useEffect(() => {
    const handleFirstTask = () => {
      if (!data) return;
      let firstTaskType = data?.filter((_) => _.type === "task_toggle")[0];
      const firstTaskKey = firstTaskType?.items[0].key;
      if (firstTaskKey && !taskStatus) {
        setTaskStatus(firstTaskKey);
      }
    };
    handleFirstTask();
  }, [data, taskStatus]);

  if (
    error?.isAxiosError &&
    error?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED
  ) {
    return <NotAuthorized />;
  }

  if (isLoading) return <FullPageLoader />;
  if (!data) return null;

  const onTaskStatusChange = (task) => {
    setTaskStatus(task);
  };

  const handlePrint = () => window.print();

  return (
    <div>
      <Overview
        taskStatus={taskStatus}
        onTaskStatusChange={onTaskStatusChange}
        data={data}
      />
      <div className="mt-10">
        {loading && (
          <div className="bg-white p-4 rounded-xl">
            <FullPageLoader />
          </div>
        )}
        <Tasks tasks={tasks} />
      </div>
      {tasks && (
        <div className="mt-10 flex items-center print:hidden justify-center">
          <Button.Download onClick={handlePrint} />
        </div>
      )}
    </div>
  );
}

export default Report;
