import classNames from "classnames";
import React from "react";

const OverlappingTextInput = ({ placeholder, value, className, ...props }) => {
  const labelClassname = classNames("absolute ease-in duration-100 -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900",
    value ? 'opacity-100' : 'opacity-0');
  const containerClassName = classNames("relative border border-black rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600",
    className ? className : '');
  return (<div className={containerClassName}>
  <label
    htmlFor={placeholder}
    className={labelClassname}
  >
    { placeholder }
  </label>
  <input
    type="text"
    name={placeholder}
    id={placeholder}
    placeholder={placeholder}
    value={value}
    {...props}
    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
  />
</div>)
}

export default OverlappingTextInput;