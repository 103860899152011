import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { fetchParliamentryConstituenciesPercentageDetails } from "../../services/parliamentaryConstituencies";
import useAuth from "../../utils/useAuth";
import usePhase from "../../utils/usePhase";
import Modal from "../Modal/Modal";
import FullPageLoader from "../Loaders/FullPageLoader";
import classNames from "classnames";
import { XIcon } from "@heroicons/react/outline";
import { ERROR_STATUS_CODE } from "../../utils/constants";
import NotAuthorized from "../NotAuthorized";

function PCPercentageModal({ onClose, isOpen, pcId, clusterId }) {
  const phase = usePhase();
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const auth = useAuth();
  const { currentPermission } = auth;
  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const getPercentageInformation = async () => {
      if (fetching || !pcId || !clusterId) return;
      setFetching(true);
      setIsUnauthorized(false);
      const response = await fetchParliamentryConstituenciesPercentageDetails({
        phase,
        currentPermission,
        clusterId,
        pcId,
      });
      setFetching(false);
      if (response.isAxiosError) {
        if (response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED) {
          setIsUnauthorized(true);
          return;
        }
        toast.error("Something went wrong...");
        return;
      }
      setData(response);
    };
    getPercentageInformation();
  }, [pcId, clusterId, currentPermission, phase]);

  const tableHeaders = data?.headers
    ? data.headers.map((header, i) => {
        const headerId = `header-${i}`;
        const { name } = header;
        return (
          <th
            className="p-2 first:w-24 sticky top-0 last:rounded-tr-lg align-top text-center first:rounded-tl-lg border-r-2 border-[#d6d6d6] bg-[#e5ebfe] last:border-r-0"
            key={headerId}
          >
            {name}
          </th>
        );
      })
    : null;
  const tableRows = data?.rows
    ? data?.rows.map((rowData, i) => {
        const rowId = `row-${i}`;
        const rowItems = rowData.map((row, j) => {
          const rowItemId = `row-${j}`;
          const { rowSpan, name, center, color } = row;
          const cellStyle = {
            backgroundColor: color || "rgb(245,247,255)",
          };
          const className = classNames(
            "border-[#d6d6d6] border-2 p-3 text-sm",
            center && "text-center"
          );
          return (
            <td
              className={className}
              key={rowItemId}
              style={cellStyle}
              rowSpan={rowSpan || 1}
            >
              {name}
            </td>
          );
        });
        return <tr key={rowId}>{rowItems}</tr>;
      })
    : null;

  const table =
    tableHeaders || tableRows ? (
      <table className="break-inside-avoid table-fixed w-full relative">
        {tableHeaders}
        {tableRows}
      </table>
    ) : null;

  const dialogClassname = classNames(
    "relative max-h-96 overflow-hidden overflow-y-auto bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all lg:max-w-screen-lg sm:max-w-sm sm:w-full",
    fetching ? "px-4 pt-5 pb-4 " : ""
  );

  const cancelClassName = classNames(
    "-top-4 -right-4 text-right absolute z-30",
    fetching && "hidden"
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="relative lg:max-w-screen-lg sm:max-w-sm sm:w-full">
        <div className={cancelClassName}>
          <button
            type="button"
            className="bg-[#ED5F61] rounded-full p-1.5 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Dialog.Panel className={dialogClassname}>
          {fetching && <FullPageLoader />}
          {isUnauthorized && <NotAuthorized handleClick={onClose} />}
          {table}
        </Dialog.Panel>
      </div>
    </Modal>
  );
}

export default PCPercentageModal;
