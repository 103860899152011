import React, { useEffect, useState } from "react";
import HelpIcon from "images/help-icon.svg";
import HelpImage from "images/help-image.svg";

const Help = () => {
    const [helpOpen, setHelpOpen] = useState(false);

    useEffect(() => {
        if (helpOpen) {
            console.log('adding')
            document.addEventListener('click', toggleHelp)
        }
        return () => {
            console.log('cleanup')
            document.removeEventListener('click', toggleHelp)
        }
    }, [helpOpen]);

    const toggleHelp = () => setHelpOpen(!helpOpen);

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleHelp();
    }
    return (<div className="flex print:hidden">
        <div className="cursor-pointer flex items-center" onClick={handleClick}>
            <img width="29" height="31" src={HelpIcon} alt="hep" />
            <p className="ml-4 text-[#61BE7B] font-semibold	poppins">Help</p>
        </div>
        {
            helpOpen
            && <a href="tel:+917503758155" className="absolute block mt-12 w-[400px]">
                <img src={HelpImage} alt="contact vivek at +917503758155" />
            </a>
        }
    </div>)
}

export default Help;