import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";

const EditBtn = ({ link }) => {
  return (
    <Button
      as={Link}
      className="bg-[#FFF5BE] inline-flex cursor-pointer items-center justify-center text-center w-28 h-9 poppins text-xl font-medium"
      to={link}
    >
      Edit
    </Button>
  );
};

export default EditBtn;
