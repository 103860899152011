import React, { useState } from "react";
import Button from "../Button";

const AgendaList = ({ meetingAgendas, shouldAlwaysOpen }) => {
  if (!meetingAgendas.length) return null;
  const [open, setOpen] = useState(true);

  const handleExandedView = () => setOpen(!open);

  if (!shouldAlwaysOpen) {
    if (!open) return <p className="cursor-pointer" onClick={handleExandedView}>Click to expand</p>
  }

  const agendaItems = meetingAgendas.map((agenda, index) => {
    const { name, agenda_status } = agenda;
    const buttonStyle = {
        ...(agenda_status?.color && {
          borderColor: agenda_status.color,
          color: agenda_status.color,
          backgroundColor: `${agenda_status.color}26`
        })
      }
    return (<li key={`agenda-${index}`} className="flex items-center justify-between mb-3">
        <p>{name}</p>
        { agenda_status && <Button style={buttonStyle} className="shrink-0 inline-flex items-center px-2 py-1 border text-sm font-semibold rounded focus:outline-none">{agenda_status.name}</Button> }
    </li>)
  });
  return (<ul className="list-none p-0 m-0 cursor-pointer" onClick={handleExandedView}>{agendaItems}</ul>)
}

export default React.memo(AgendaList);