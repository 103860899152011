import React from "react";
import classNames from "classnames";
 
const CircleStepper = ({ steps, currentStep, percentage }) => {
  const stepsItems = steps.map((step, index) => {
    const isLast = index === steps.length - 1;
    const isFirst = index === 0;
    const accomplished = index <= currentStep;
    const circleClassName = classNames("lg:w-9 z-10 lg:h-9 w-6 relative h-6 rounded-full text-center justify-center text-white font-bold flex items-center",
        accomplished ? 'bg-[#5384FC]' : 'bg-ls-dark-gray')
    const textClassName = classNames("mt-3.5 relative z-10 lg:text-base text-sm",
        accomplished ? "text-[#282622]" : 'text-ls-dark-gray',
        isLast ? "w-24"  : "");
    const containerClassName = classNames("relative",
      isLast ? '-ml-[2px]' : 'flex-1');
    const bgClassName = classNames("w-full absolute top-3 lg:top-4 z-1",
      isFirst ? 'ml-[2px]' : '',
      currentStep > index ? 'h-0.5 bg-[#5384FC]' : 'h-px bg-ls-dark-gray');
    return <div className={containerClassName} key={`stepper-${index}`}>
      {
        !isLast
        && <div className={bgClassName}></div>
      }
      <div className={circleClassName}>{index + 1}</div>
      <p className={textClassName}>{step.name}</p>
    </div>
    });
  return (<div className="flex relative items-start justify-between">
    {stepsItems}
  </div>)
  }

export default CircleStepper;