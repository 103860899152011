import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import ClusterReportsPDFItems from "../../components/ClusterReportItems/ClusterReportsPDFItems";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import NotAuthorized from "../../components/NotAuthorized";
import Title from "../../components/Title";
import { fetchClusterReportPDF } from "../../services";
import { ERROR_STATUS_CODE } from "../../utils/constants";
import useAuth from "../../utils/useAuth";
import usePhase from "../../utils/usePhase";

const useMutationObserver = (
  ref,
  callback,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  }
) => {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
  }, [callback, options]);
};

const ClusterReportPDF = () => {
  const auth = useAuth();
  const { clusterId, pcId } = useParams();
  const phase = usePhase();
  const { currentPermission } = auth;
  const [hasPrint, setHasPrint] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [report, setReport] = useState(null);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const mutationRef = useRef();
  useMutationObserver(mutationRef, handlePrint);

  useEffect(() => {
    const getClusters = async () => {
      if (!currentPermission || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      let response = await fetchClusterReportPDF({
        currentPermission,
        clusterId,
        pcId,
        phase,
      });
      setFetching(false);
      if (response.isAxiosError) {
        if (response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED) {
          setIsUnauthorized(true);
          return;
        }
        toast.error("Something went wrong...");
        return;
      }
      setReport(response);
    };
    getClusters();
  }, [currentPermission]);

  useEffect(() => {}, [report]);

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  if (!report) return null;

  function handlePrint() {
    if (!hasPrint) {
      setHasPrint(true);
      if (window && window.print) {
        // creating an hack to downloaded images for pdf
        if (window.scrollTo && document?.body?.scrollHeight) {
          window.scrollTo(0, document.body.scrollHeight / 2);
          window.scrollTo(0, document.body.scrollHeight / 3);
          window.scrollTo(0, document.body.scrollHeight / 2);
          window.scrollTo(0, document.body.scrollHeight);
          window.scrollTo(0, 0);
        }
        setTimeout(() => {
          window.print();
        }, 1000);
      }
    }
  }

  return (
    <div ref={mutationRef}>
      <Title imageSrc={report.icon || ""} title={report.title || ""} />
      <ClusterReportsPDFItems report={report.report || []} />
    </div>
  );
};

export default ClusterReportPDF;
