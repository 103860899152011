import React from "react";

const MetaBar = ({ meta, className }) => {
  if (!meta || (meta && !meta.length)) return null;
  const metaItems = meta.map(({ title, value, description, color }) => {
    const colorStyle = color ? { color } : {};
    value = value === 0 ? "0" : value;
    return (
      <div
        className="flex-1 flex flex-col justify-between border-r border-gray-400 last:border-0 pl-3 pr-3 first:pl-0 last:pr-0"
        key={title}
      >
        <p style={colorStyle} className="text-xs text-[#FF9955]">
          {title}
        </p>
        <p className="text-lg	font-bold mt-1.5 whitespace-pre	 poppins">
          {value || description}
        </p>
      </div>
    );
  });
  return (
    <div
      className={`bg-white py-6 px-10 box-border mt-8 rounded-lg flex items-stretch justify-between ${
        className || ""
      }`}
    >
      {metaItems}
    </div>
  );
};

export default React.memo(MetaBar);
