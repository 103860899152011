import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

function Breadcrumbs({ items }) {
  if (!items?.length) return null;
  const breadcrumbsItems = items.map((breadcrumb, i) => {
    const { link, title } = breadcrumb;
    const active = i === items.length - 1;
    const cn = classNames(
      "font-semibold",
      active ? "opacity-100" : "opacity-75"
    );
    return (
      <div key={i} className="flex items-center">
        {active ? (
          <p className={cn}>{title}</p>
        ) : (
          <Link className={cn} to={link || "/"}>
            {title}
          </Link>
        )}
        {!active && (
          <p className="mx-2 font-semibold text-base text-[#4D4D4D]">/</p>
        )}
      </div>
    );
  });
  return (
    <div className="flex flex-wrap items-center mb-6 print:hidden">
      {breadcrumbsItems}
    </div>
  );
}

export default Breadcrumbs;
