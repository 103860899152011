import React from "react";
import Title from "../../../../components/Title";
import { dateFormat } from "../../../../utils/helper";
import useAuth from "../../../../utils/useAuth";
import { APP_PERMISSION_BACKEND_MAPPER } from "../../../../utils/internalLinks";

function TaskTitle(props) {
  const auth = useAuth();
  const { currentPermission } = auth;
  const today = dateFormat(new Date(), "dd-MM-yyyy");

  const title =
    currentPermission?.name ===
    APP_PERMISSION_BACKEND_MAPPER.VS_INCHARGE_DASHBOARD
      ? "VSPY"
      : "LSPY";
  return (
    <div>
      <div className="block print:hidden">
        <Title {...props} />
      </div>
      <div className="print:flex hidden justify-between items-center">
        {(props.title || props.taskTitle) && (
          <>
            <h2 className="poppins text-3xl font-semibold">
              {title} Report - {props.taskTitle || props.title}
            </h2>
            <p className="text-xl font-bold">Downloaded on: {today}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default TaskTitle;
