import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import BorderedText from "../../../components/BorderedText";
import Button from "../../../components/Button";
import TextArea from "../../../components/FormInputs/TextArea";
import Success from "../../../components/Modals/Success/Success";
import RadioGroup from "../../../components/RadioGroup";
import { toast } from "react-toastify";
import Title from "../../../components/Title";
import {
  fetchOverAllFeedBackForm,
  updateOverAllFeedBackForm,
} from "../../../services/overallFeedback";
import useAuth from "../../../utils/useAuth";
import {
  OVERALL_FEEDBACK_LINK,
  PARLIAMENT_CONSTITUENCY_LINKS,
} from "../../../utils/internalLinks";
import FullPageLoader from "../../../components/Loaders/FullPageLoader";
import NotAuthorized from "../../../components/NotAuthorized";
import {
  ERROR_STATUS_CODE,
  REPORT_TYPE_BACKEND_MAPPER,
} from "../../../utils/constants";
import { countWords } from "../../../utils/helper";
import usePhase from "../../../utils/usePhase";
import classNames from "classnames";
import scrollIntoView from "scroll-into-view";

const DEFAULT_PLACEHOLDER = "Please mention your observation & Feedback";

const OverallFeedback = () => {
  const auth = useAuth();
  const { currentPermission } = auth;
  const navigate = useNavigate();
  const { clusterId, pcId, visitId, reportType } = useParams();
  const phase = usePhase();
  const [fetching, setFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState([]);
  const [successFormShow, setSuccessFormShow] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [searchParams] = useSearchParams();
  const [hasScrolled, setHasScrolled] = useState(false);
  const currentQuestionId = searchParams.get("qId");

  const updateFormValues = (newId, newData) => {
    setForm(
      form.map((item) => {
        if (item.id !== newId) return item;
        return {
          ...item,
          ...newData,
        };
      })
    );
  };

  const transformForm = (form) => {
    return form.map((item) => {
      if (item?.field_options?.field_type !== "radio") {
        return item;
      }
      return {
        ...item,
        field_options: {
          ...item.field_options,
          value: item.field_options.value
            ? item.field_options.value
            : item.field_options.field_items[0],
        },
      };
    });
  };

  useEffect(() => {
    const getOverallFeedbackForm = async () => {
      if (!currentPermission || !phase || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      const response = await fetchOverAllFeedBackForm({
        phase,
        currentPermission,
        clusterId,
        pcId,
        visitId,
        reportType: reportType || REPORT_TYPE_BACKEND_MAPPER.OVERALL_FEEDBACK,
      });
      setFetching(false);
      if (response.isAxiosError) {
        if (response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED) {
          setIsUnauthorized(true);
          return;
        }
        toast.error("Something went wrong!");
        return;
      }
      if (response && response.length) {
        setForm(response);
      }
    };
    getOverallFeedbackForm();
  }, [currentPermission, phase]);

  useEffect(() => {
    const scrollToItem = () => {
      if (hasScrolled || !form?.length) return;
      setHasScrolled(true);
      let ele = document.querySelector(".scroll-item");
      if (ele) {
        scrollIntoView(ele, { time: 0 });
      }
    };
    scrollToItem();
  }, [form, hasScrolled]);

  const formItems = form.map((formItem) => {
    const haveRadio =
      formItem.field_options?.field_type === "radio" &&
      formItem.field_options?.field_items;
    const haveComment = formItem?.comment;

    const handleCommentChange = (e) => {
      const newData = {
        comment: {
          ...formItem.comment,
          value: e.target.value,
        },
      };
      // checking validity
      if (
        formItem.comment.required &&
        formItem.comment.word_count &&
        countWords(e.target.value) < formItem.comment.word_count
      ) {
        newData.comment[
          "error"
        ] = `Please enter minimum ${formItem.comment.word_count} words`;
      } else {
        delete newData.comment.error;
      }
      updateFormValues(formItem.id, newData);
    };

    const handleRadioChange = (newValue) => {
      const newData = {
        field_options: {
          ...formItem.field_options,
          value: formItem.field_options?.field_items.filter(
            (field) => field.id === parseInt(newValue)
          )[0],
        },
      };
      updateFormValues(formItem.id, newData);
    };

    const currentWordCount = haveComment
      ? countWords(formItem.comment?.value || "")
      : undefined;

    const currentWordCountMessage =
      typeof currentWordCount !== "undefined"
        ? `${currentWordCount} word${currentWordCount <= 1 ? "" : "s"}`
        : null;

    const className = classNames(
      "mt-11 first:mt-0",
      currentQuestionId && parseInt(currentQuestionId) === formItem.id
        ? "bg-yellow-50 scroll-item"
        : ""
    );
    return (
      <div key={formItem.id} className={className}>
        <div className="flex items-center">
          <div className="flex items-center">
            <BorderedText text={formItem.name} />
            {formItem.required && <sup className="text-xl text-red-500">*</sup>}
          </div>
          {formItem.error && (
            <p className="text-xs text-red-500 ml-4">{formItem.error}</p>
          )}
        </div>
        <div className="px-2.5 box-border">
          {haveRadio && (
            <RadioGroup
              onChange={handleRadioChange}
              name={formItem.id}
              className="mt-6"
              value={
                formItem.field_options.value
                  ? formItem.field_options.value.id
                  : ""
              }
              items={formItem.field_options.field_items}
            />
          )}
          {haveComment && (
            <>
              {!!currentWordCountMessage && (
                <p className="-mb-4 text-right text-xs text-gray-500">
                  {currentWordCountMessage}
                </p>
              )}
              <TextArea
                required={formItem.comment.required}
                className="mt-6"
                onChange={handleCommentChange}
                value={formItem.comment.value || ""}
                placeholder={
                  formItem.comment.placeholder || DEFAULT_PLACEHOLDER
                }
              />
              {formItem.comment.error && (
                <p className="text-right text-xs text-red-500">
                  {formItem.comment.error}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    );
  });

  const checkFormValid = () => {
    let isValid = true;
    const formItems = form.map((item) => {
      // checking comments
      const hasComment = item.comment;
      const commentRequired = hasComment && item.comment.required;
      const hasWordCount = hasComment && item.comment.word_count;
      const isValidComment =
        !commentRequired ||
        (commentRequired &&
          item.comment.value &&
          (!hasWordCount ||
            (item.comment.word_count &&
              countWords(item.comment.value) >= item.comment.word_count)));
      if (!isValidComment) {
        isValid = false;
        item.comment.error = hasWordCount
          ? `Please enter minimum ${item.comment.word_count} words`
          : `Required`;
      }
      // checking radio options
      if (item?.field_options?.field_type !== "radio") {
        return item;
      }
      const isRequired = item.required;
      const hasValue = item.field_options.value;
      const isValidFormField = !isRequired || (isRequired && hasValue);
      if (!isValidFormField) {
        isValid = false;
      }
      return {
        ...item,
        field_options: {
          ...item.field_options,
        },
        ...(isRequired &&
          !hasValue && {
            error: "Please select one option from below",
          }),
      };
    });
    setForm(formItems);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = checkFormValid();
    if (!isValid) return;
    if (isSubmitting) return;
    setIsSubmitting(true);
    const data = form;
    updateOverAllFeedBackForm({
      phase,
      currentPermission,
      clusterId,
      pcId,
      visitId,
      reportType: reportType || REPORT_TYPE_BACKEND_MAPPER.OVERALL_FEEDBACK,
      data,
    }).then((response) => {
      setIsSubmitting(false);
      if (response.isAxiosError) {
        toast.error("Error submitting form");
        return;
      }
      setSuccessFormShow(true);
    });
  };

  const handleSuccessFormClose = () => {
    setSuccessFormShow(false);
    navigate(
      OVERALL_FEEDBACK_LINK.ALL({
        reportType: reportType || REPORT_TYPE_BACKEND_MAPPER.OVERALL_FEEDBACK,
        clusterId,
        pcId,
        visitId,
        phase,
      })
    );
  };

  if (isUnauthorized) return <NotAuthorized />;

  return (
    <div>
      <Title title="Overall Lok Sabha Feedback" />
      {fetching ? (
        <div className="mt-5">
          <FullPageLoader />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="bg-white px-7 py-9 shadow mt-8 rounded-lg	">
            {formItems}
          </div>
          <div className="mt-8 flex justify-end">
            <Button.Rounded disabled={isSubmitting} type="submit">
              Submit
            </Button.Rounded>
          </div>
        </form>
      )}

      <Success
        isOpen={successFormShow}
        title="Form submitted successfully"
        onClose={handleSuccessFormClose}
        showButton={true}
        buttonText="Close"
      />
    </div>
  );
};

export default OverallFeedback;
