import React from "react";
import PropTypes from "prop-types";
import BorderedText from "../BorderedText";
import Button from "../Button";
import LeftArrow from "images/left-arrow.svg";
import RightArrow from "images/right-arrow.svg";
import OverlappingTextInput from "../FormInputs/OverlappingTextInput";
import SimpleSelect from "../FormInputs/SimpleSelect";
import OverlappingSelect from "../FormInputs/OverlappingSelect";
import SectionLoader from "../Loaders/SectionLoader";

const VidhanSabhaForm = ({
  stepForm,
  isFinalStep,
  currentStep,
  handleUpdate,
  totalSteps,
  fetching,
  handleACId,
  isSubmitting,
  handleNext,
  disablePrev,
  handlePrev,
}) => {
  const { form, name } = stepForm;

  const handleFormUpdate = (id, newFormItem) => {
    const newForm = form.map((formItem) => {
      if (formItem.id !== id) return formItem;
      return newFormItem;
    });
    handleUpdate(newForm);
  };

  const formItems = form.map((item) => {
    const {
      field_placeholder,
      field_type,
      id,
      name,
      assembly_list,
      field_items,
      required,
      value,
    } = item;

    const handleSelectChange = (e) => {
      let {
        target: { value },
      } = e;
      let newItem = { ...item };
      if (value) {
        newItem["value"] = field_items.filter(
          (field) => field.id === parseInt(value)
        )[0];
      } else {
        delete newItem.value;
      }
      if (assembly_list && handleACId) {
        handleACId(value);
      }
      handleFormUpdate(id, newItem);
    };

    const handleInputChange = (e) => {
      let {
        target: { value },
      } = e;
      let newItem = {
        ...item,
        value,
      };
      handleFormUpdate(id, newItem);
    };

    const placeholder = `${field_placeholder}${
      required || assembly_list ? "*" : ""
    }`;
    switch (field_type) {
      case "select":
        return (
          <OverlappingSelect
            placeholder={placeholder}
            value={value ? value.id : ""}
            key={`form-${id}`}
            required={assembly_list || !!required || false}
            onChange={handleSelectChange}
          >
            {field_placeholder && <option value="">{placeholder}</option>}
            {field_items.map((field) => {
              return (
                <option
                  value={field.id}
                  key={`field-${field_placeholder}-${field.id}`}
                >
                  {field.name}
                </option>
              );
            })}
          </OverlappingSelect>
        );
      case "input":
        return (
          <OverlappingTextInput
            key={`form-${field_placeholder}-${id}`}
            value={value || ""}
            required={!!required || false}
            onChange={handleInputChange}
            placeholder={placeholder}
          />
        );
      default:
        return null;
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    handleNext();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="box-border min-h-[292px] py-4 px-7 bg-white rounded-lg shadow mt-28">
          {fetching ? (
            <SectionLoader />
          ) : (
            <>
              <BorderedText text={name} />
              <div className="mt-5 grid grid-cols-2 gap-y-4 gap-x-11">
                {formItems}
              </div>
            </>
          )}
        </div>
        <div className="mt-8 flex items-center justify-between">
          <Button
            className="flex rounded-full py-[7px] px-6 bg-[#F1F5FF] items-center box-border max-h-[40px]"
            onClick={disablePrev ? null : handlePrev}
            type="button"
            disabled={disablePrev || isSubmitting}
          >
            <img className="mr-3.5" src={LeftArrow} alt="previous" />
            <span className="font-medium text-#163560]">Previous</span>
          </Button>
          <p className="text-[#FF9559] font-bold">
            Step {currentStep + 1} / {totalSteps}
          </p>
          {isFinalStep ? (
            <Button.Rounded disabled={isSubmitting} type="submit">
              Save &amp; Next
            </Button.Rounded>
          ) : (
            <Button
              type="submit"
              disabled={isSubmitting}
              className="flex rounded-full py-[7px] px-6 bg-[#F1F5FF] items-center box-border max-h-[40px]"
            >
              <span className="font-medium text-#163560]">Next</span>
              <img className="ml-3.5" src={RightArrow} alt="previous" />
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

VidhanSabhaForm.propTypes = {
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  disablePrev: PropTypes.bool,
  isFinalStep: PropTypes.bool,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  stepForm: PropTypes.object,
  handleACId: PropTypes.func,
  fetching: PropTypes.bool,
};

export default VidhanSabhaForm;
