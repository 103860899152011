import React from "react";
import t from "prop-types";

const Questions = ({ items }) => {
  if (!items.length) return null;
  
  const feedbackItems = items.map((item) => {
    const { name, field_options, comment, id } = item;
    return (<div key={`overall-${id}`} className="print:break-inside-avoid bg-[#F3F7FF] rounded-lg mt-6 py-4 px-9 box-border">
      <p className="poppins font-semibold text-xl text-[#163560]">{name}</p>
      {
        field_options?.value?.name
        && <p style={{backgroundColor: field_options && field_options.value && field_options.value.color ? field_options.value.color: ''}} 
          className="mt-3 rounded-lg text-white text-lg bg-[#f59842] font-bold inline-block min-w-[170px] text-center py-1 px-10 box-border">{field_options.value.name}</p>
      }
      {
        comment
        && <div className="mt-4 flex items-start">
          <div className="pr-2.5 w-40 border-r border-gray-400">
            <p className="poppins text-base font-semibold text-[#282622]">{comment.report_placeholder || 'Your obervation & feedback'}</p>
        </div>
          <p className="text-base pl-5 whitespace-pre-line font-medium">{comment?.value || ''}</p>
        </div>
      }
    </div>)
  });

  return <div className="">
    {feedbackItems}
  </div>
}

Questions.propTypes = {
  items: t.array
}

Questions.defaultProps = {
  items: []
}

export default Questions;