import classnames from "classnames";
import React from "react";

const SimpleSelect = ({ children, className, ...props }) => {
  const selectClassName = classnames("block pl-3 pr-10 py-2 font-bold text-base border-gray-300 focus:outline-none rounded-md",
    className);
    return (<select className={selectClassName} {...props} >
      {children}
    </select>)
}

export default SimpleSelect;