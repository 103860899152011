import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./components/Layout/Layout";
import SingleFolder from "./components/Media/SingleFolder";
import NoMatch from "./components/NoMatch";
import RequireAuth from "./components/RequireAuth";
import ClusterReport from "./pages/ClusterReport";
import ClusterReportPDF from "./pages/ClusterReportPDF";
import Login from "./pages/Login";
import ParliamentConstituencyVisitReportType from "./pages/ParliamentConstituencyVisitReportType";
import SSO from "./pages/SSO";
import AuthProvider from "./utils/AuthProvider";
import { HOME_LINK } from "./utils/internalLinks";
import useAuth from "./utils/useAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const Cluster = React.lazy(() => import("./pages/Clusters"));
const Phases = React.lazy(() => import("./pages/Phases"));
const LokSabha = React.lazy(() => import("./pages/LokSabha"));
const LokSabhaVisit = React.lazy(() => import("./pages/LokSabhaVisit"));
const National = React.lazy(() => import("./pages/NationalDashboard"));
const State = React.lazy(() => import("./pages/StateDashboard"));
const OverallFeedbackQuestions = React.lazy(() =>
  import("./pages/ReportType/OverallFeedback/OverallFeedbackForm")
);
const Vistarak = React.lazy(() => import("./pages/Vistarak"));
const VistarakReport = React.lazy(() => import("./pages/Vistarak/Report"));

const queryClient = new QueryClient();

const AuthencatedRoute = ({ children }) => {
  return (
    <React.Suspense>
      <RequireAuth>
        <section className="min-h-screen print:py-4 py-28 mx-auto max-w-8xl px-4 sm:px-6 lg:px-28 bg-neutral-50">
          {children}
        </section>
      </RequireAuth>
    </React.Suspense>
  );
};

const StartingRoute = () => {
  let auth = useAuth();
  let location = useLocation();
  const { homeLink } = auth;
  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return <Navigate replace to={homeLink || HOME_LINK} />;
  }
};

const App = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        signOutUrl={props.sign_out_url}
        signInUrl={props.sign_in_url}
        user={props.user || null}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                path="dashboard/vistarak/report/pc/:pcId/:taskType"
                index
                element={
                  <AuthencatedRoute>
                    <VistarakReport />
                  </AuthencatedRoute>
                }
              />
              <Route
                path="dashboard/vistarak"
                index
                element={
                  <AuthencatedRoute>
                    <Vistarak />
                  </AuthencatedRoute>
                }
              />
              <Route
                path="dashboard/national"
                index
                element={
                  <AuthencatedRoute>
                    <National />
                  </AuthencatedRoute>
                }
              />
              <Route
                path="dashboard/state"
                index
                element={
                  <AuthencatedRoute>
                    <State />
                  </AuthencatedRoute>
                }
              />
              <Route index element={<StartingRoute />} />
              <Route
                path="phases/:phaseId/clusters"
                element={
                  <AuthencatedRoute>
                    <Cluster />
                  </AuthencatedRoute>
                }
              />
              <Route
                path="phases"
                element={
                  <AuthencatedRoute>
                    <Phases />
                  </AuthencatedRoute>
                }
              />
              <Route
                path="phases/:phaseId/clusters/:clusterId/parliament-constituencies/:pcId"
                element={
                  <AuthencatedRoute>
                    <LokSabha />
                  </AuthencatedRoute>
                }
              />
              <Route
                path="phases/:phaseId/clusters/:clusterId/parliament-constituencies/:pcId/report"
                element={
                  <AuthencatedRoute>
                    <ClusterReport />
                  </AuthencatedRoute>
                }
              />
              <Route
                path="phases/:phaseId/clusters/:clusterId/parliament-constituencies/:pcId/report/pdf"
                element={
                  <AuthencatedRoute>
                    <ClusterReportPDF />
                  </AuthencatedRoute>
                }
              />
              <Route
                path="phases/:phaseId/clusters/:clusterId/parliament-constituencies/:pcId/visit/:visitId"
                element={
                  <AuthencatedRoute>
                    <LokSabhaVisit />
                  </AuthencatedRoute>
                }
              />
              <Route
                exact
                path="phases/:phaseId/clusters/:clusterId/parliament-constituencies/:pcId/visit/:visitId/overall-feedback/question"
                element={
                  <AuthencatedRoute>
                    <OverallFeedbackQuestions />
                  </AuthencatedRoute>
                }
              />
              <Route
                exact
                path="phases/:phaseId/clusters/:clusterId/parliament-constituencies/:pcId/visit/:visitId/media/:folderId"
                element={
                  <AuthencatedRoute>
                    <SingleFolder />
                  </AuthencatedRoute>
                }
              />
              <Route
                path="phases/:phaseId/clusters/:clusterId/parliament-constituencies/:pcId/visit/:visitId/:reportType"
                element={
                  <AuthencatedRoute>
                    <ParliamentConstituencyVisitReportType />
                  </AuthencatedRoute>
                }
              />
              <Route path="sso_client/sign_in" element={<SSO />} />
              <Route path="login" element={<Login />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
