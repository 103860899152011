import { Dialog } from "@headlessui/react";
import React from "react";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal/Modal";
import { XIcon } from "@heroicons/react/outline";
import BorderedText from "../../../../components/BorderedText";
import { useVistarakReportTaskMedia } from "../../../../libs/vistarak/hooks";
import useAuth from "../../../../utils/useAuth";
import FullPageLoader from "../../../../components/Loaders/FullPageLoader";

function TaskMedia({ isOpen, meetingId, onClose }) {
  const auth = useAuth();
  const { currentPermission } = auth;
  const { data, isLoading } = useVistarakReportTaskMedia({
    currentPermission,
    meetingId,
    isOpen,
  });

  const items = data?.items.map((image) => {
    if (!image?.mime_type?.includes("image")) return;
    return (
      <a href={image.url} target="_blank" rel="noopener norfereer">
        <img
          src={image.url}
          className="w-80 h-56 object-cover rounded-lg overflow-hidden"
          alt={image.filename}
        />
      </a>
    );
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 lg:max-w-screen-lg sm:max-w-sm sm:w-full sm:p-6">
        <div className="absolute top-0 right-0 z-10 hidden pt-4 pr-4 sm:block">
          <Button
            type="button"
            aria-hidden="true"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </Button>
        </div>
        <BorderedText text="Media" />
        <div className="mt-10">
          {isLoading ? (
            <FullPageLoader />
          ) : (
            <div className="flex flex-wrap gap-12">
              {items?.length ? items : <p>No Media File</p>}
            </div>
          )}
        </div>
      </Dialog.Panel>
    </Modal>
  );
}

export default TaskMedia;
