import { ClientApi } from "./client";
import { EXTERNAL_LINKS } from "./links";

export const fetchNationalDashboard = ({
  currentPermission,
  params,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(EXTERNAL_LINKS.DASHBOARD.NATIONAL, { params })
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const fetchStateDashboard = ({ currentPermission, params, phase }) => {
  return ClientApi(currentPermission.id, phase)
    .get(EXTERNAL_LINKS.DASHBOARD.STATE, { params })
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const fetchStateList = ({ currentPermission, phase }) => {
  return ClientApi(currentPermission.id, phase)
    .get(EXTERNAL_LINKS.STATE.LIST)
    .then((response) => response.data.data)
    .catch((error) => error);
};
