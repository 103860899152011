import React, { useEffect, useState } from "react";
import t from "prop-types";
import BorderedText from "../BorderedText";
import TableComponets from "./TableComponents";
import DownloadWhite from "images/download-white.svg";
import Button from "../Button";
import { dateFormat } from "../../utils/helper";
import classNames from "classnames";
import { XCircleIcon } from "@heroicons/react/outline";
import TextInput from "../FormInputs/TextInput";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import ReactTooltip from "react-tooltip";

const date = dateFormat(new Date(), "dd-MM-yyyy");

const handlePrint = () => window.print();

const HIDE_COLUMN_VS_NAME_MAPPER = "action";

const LSClustersReportsTable = ({
  title,
  data,
  isSearchable,
  cancelSearch,
  handleSearch,
  searchTerm,
  headers,
  phase,
}) => {
  const [showCollapsible, setShowCollapsible] = useState(false);

  const toggleCollapsible = () => setShowCollapsible(!showCollapsible);

  const headerItems = headers.map((header, index) => {
    const { description } = header;
    const isCollapsable = !!header.collapsible;
    if (!showCollapsible && isCollapsable) return null;
    const hideLastColumn =
      index === headers.length - 1 &&
      header.name &&
      header.name.toLowerCase() === HIDE_COLUMN_VS_NAME_MAPPER;
    const headerClassname = classNames(
      "border-r border-separate max-w-[200px] relative last:border-r-0 border-[#D6D6D6] mulish py-4 relative px-2 first:rounded-tl-lg last:rounded-tr-lg font-bold text-base text-center text-black",
      hideLastColumn ? "print:hidden" : ""
    );
    return (
      <th key={`th-${index}`} className={headerClassname}>
        {header.showCollapsibleButton && (
          <button
            data-tip={
              showCollapsible
                ? "Click to close the Pravas Mantri list"
                : "Click to view the Pravas Mantri list"
            }
            className="w-8 print:hidden h-8 cursor-pointer bg-white rounded-xl -top-4 -right-4 shadow-lg absolute top-0 right-0"
            onClick={toggleCollapsible}
          >
            {showCollapsible ? (
              <ChevronLeftIcon className="pointer-events-none" />
            ) : (
              <ChevronRightIcon className="pointer-events-none" />
            )}
          </button>
        )}
        {header.name}
        {description && (
          <p className="font-normal text-[10px]">{description}</p>
        )}
      </th>
    );
  });

  const rowItems = data.map((row, index) => {
    const singleRowItems = row.map((cellData, index) => {
      const { value, type, collapsible } = cellData;
      if (!showCollapsible && collapsible) return null;
      const hideLastColumn =
        index === row.length - 1 &&
        headers[index].name &&
        headers[index].name.toLowerCase() === HIDE_COLUMN_VS_NAME_MAPPER;
      const rowDatalassname = classNames(
        "p-0 border border-y-8 max-w-[200px] border-y-white first:border-l-0 last:border-r-0 border-[#D6D6D6]",
        hideLastColumn ? "print:hidden" : ""
      );
      return (
        <td className={rowDatalassname} key={`tr-td-${index}`}>
          <TableComponets phase={phase} type={type} value={value} />
        </td>
      );
    });
    return (
      <tr
        className="odd:bg-[#FBFCFF] even:bg-[#F4F7FE] break-inside-avoid"
        key={`tr-${index}`}
      >
        {singleRowItems}
      </tr>
    );
  });

  return (
    <div>
      <div className="flex items-center justify-between">
        <BorderedText text={title} />
        <div className="flex items-center">
          {isSearchable && (
            <div className="mr-8 relative rounded-md shadow-sm">
              <TextInput
                onChange={handleSearch}
                type="text"
                name="search"
                defaultValue={searchTerm}
                className="focus:ring-indigo-500 print:hidden w-56 lg:w-96 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Search"
              />
              {!!searchTerm && !!cancelSearch && (
                <Button
                  onClick={cancelSearch}
                  className="cursor-pointer print:hidden absolute appearance-none inset-y-0 right-0 pr-3 flex items-center"
                >
                  <XCircleIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Button>
              )}
            </div>
          )}
          <p className="print:block hidden text-2xl font-bold">
            Downloaded on: {date}
          </p>
          <Button
            onClick={handlePrint}
            className="h-10 print:hidden flex items-center bg-[#32BA7C] rounded-lg overflow-hidden"
          >
            <div className="w-10 flex h-full items-center bg-[#4F926A] justify-center">
              <img
                className="w-[14px] h-auto block"
                src={DownloadWhite}
                alt="download table"
              />
            </div>
            <span className="px-6 text-white font-medium">Download</span>
          </Button>
        </div>
      </div>
      <div className="mt-10 relative print:mt-0">
        <table className="min-w-full rounded-bl-lg rounded-br-lg relative overflow-auto">
          <thead className="sticky z-20	print:relative bg-[#E3ECFF] top-[80px]">
            <tr className="break-inside-avoid">{headerItems}</tr>
          </thead>
          <tbody>{rowItems}</tbody>
        </table>
      </div>
      <ReactTooltip />
    </div>
  );
};

LSClustersReportsTable.propTypes = {
  headers: t.array,
  data: t.array,
  isSearchable: t.bool,
  handleSearch: t.func,
  searchTerm: t.string,
  cancelSearch: t.func,
};

LSClustersReportsTable.defaultProps = {
  headers: [],
  data: [],
  isSearchable: false,
  handleSearch: () => {},
  searchTerm: "",
  cancelSearch: () => {},
};

export default React.memo(LSClustersReportsTable);
