import React, { useContext, useEffect, useState } from "react";
import { reportItems } from "../services";

const MeetingStatusAndPriortiesContext = React.createContext({
  meetingStatus: [],
  meetingAgendaStatus: [],
  meetingPriorities: []
});

export const MeetingStatusAndPriortieProvider = ({ children }) => {
    const [meetingStatus, setMeetingStatus] = useState([]);
    const [meetingAgendaStatus, setMeetingAgendaStatus] = useState([]);
    const [meetingPriorities, setMeetingPriorities] = useState([]);
    useEffect(() => {
        const getAllStatusAndPriorities = async () => {
          if (!meetingStatus.length) {
            const meetingStatusData = await reportItems.fetchMeetingStatus();
            setMeetingStatus(meetingStatusData?.data?.data || []);
          }
          if (!meetingAgendaStatus.length) {
            const meetingAgendaStatusData = await reportItems.fetchMeetingAgendaStatus();
            setMeetingAgendaStatus(meetingAgendaStatusData?.data?.data || []);
          }
          if (!meetingPriorities.length) {
            const meetingPrioritiesData = await reportItems.fetchMeetingPriorities();
            setMeetingPriorities(meetingPrioritiesData?.data?.data);
          }
        }
        getAllStatusAndPriorities();
    }, []);
    
    const value = { meetingStatus, meetingAgendaStatus, meetingPriorities };

    return <MeetingStatusAndPriortiesContext.Provider value={value}>{children}</MeetingStatusAndPriortiesContext.Provider>
}

const useMeetingReportStatus = () => useContext(MeetingStatusAndPriortiesContext);

export default useMeetingReportStatus;