import React from "react";

const MeetingPDFLoader = ({ isOpen }) => {
  return (
    <div className="fixed pdf-loader fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-30 inset-0 overflow-y-auto">
      <div className="flex items-end sm:items-center justify-center min-h-full p-4 sm:p-0">
        <div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 lg:max-w-screen-lg sm:max-w-sm sm:w-full sm:p-6">
          PDF Loading...
        </div>
      </div>
    </div>
  );
};

export default MeetingPDFLoader;
