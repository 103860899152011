import classNames from "classnames";
import React from "react";

function TaskToggle({
  items,
  hidden,
  taskStatus,
  fetching,
  onTaskStatusChange,
}) {
  if (!items.length || hidden) return null;

  const disabled = fetching;

  const toggleItems = items.map((item) => {
    const onClick = () => {
      if (disabled) return;
      onTaskStatusChange(item.key);
    };
    const isActive = item.key === taskStatus;

    const containerClassName = classNames(
      "flex-1 h-20 cursor-pointer shadow-[1px_2px_2px_rgba(0,0,0,0.25)] inline-flex items-center justify-center rounded-xl",
      isActive ? "bg-gradient-to-b from-[#699BF7] to-[#29538E]" : "bg-white"
    );
    const textClassName = classNames(
      "text-xl poppins font-medium",
      isActive ? "text-white" : ""
    );

    const countClassName = classNames(
      "rounded-full w-12 h-12 inline-flex items-center justify-center text-xl ml-8 bg-white",
      !isActive
        ? "bg-gradient-to-b from-[#699BF7] to-[#29538E] text-white"
        : "bg-white text-[#282622]"
    );
    return (
      <div className={containerClassName} onClick={onClick} key={item.key}>
        <span className={textClassName}>{item.title}</span>
        <span className={countClassName}>{item.count}</span>
      </div>
    );
  });
  return <div className="flex gap-10 mt-10 print:hidden">{toggleItems}</div>;
}

export default TaskToggle;
