import React, { useEffect, useState } from "react";
import useAuth from "../../utils/useAuth";
import { dashboardItems } from "../../services";
import { toast } from "react-toastify";
import NotAuthorized from "../../components/NotAuthorized";
import DashboardItems from "../../Dashboard/DashboardItems";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import MultipleSelectCheckmarks from "../../components/FormInputs/MultipleSelectCheckmarks";
import { useDebouncedCallback } from "use-debounce";
import { ERROR_STATUS_CODE } from "../../utils/constants";
import DashboardPhaseSelector from "../../Dashboard/DashboardPhaseSelector";
import Heading from "../../components/ReusableComponents/Heading";

const State = () => {
  const auth = useAuth();
  const [selectedPhase, setSelectedPhase] = useState(null);
  const { currentPermission } = auth;
  const [fetching, setFetching] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const cancelSearch = () => {
    setSearchTerm("");
  };

  const debounced = useDebouncedCallback((value) => {
    setSearchTerm(value);
  }, 1000);

  const handleSearch = (e) => debounced(e.target.value);

  const getStateList = async () => {
    if (!selectedPhase) return;
    const response = await dashboardItems.fetchStateList({
      currentPermission,
      phase: selectedPhase.id,
    });
    setStateList(response);
  };

  const handleSelectedStateInput = (value) => {
    setSelectedStates([]);
    setSelectedStates(value);
  };

  const handleSelectLabel = (item) => ({
    label: item.name,
    id: item.id,
  });

  useEffect(() => {
    const getStateDashboard = () => {
      if (fetching || !currentPermission || !selectedPhase) return;
      setFetching(true);
      setIsUnauthorized(false);
      const params = {
        ...(selectedStates &&
          selectedStates.length && {
            state_id: selectedStates.join(","),
          }),
        ...(searchTerm && {
          q: searchTerm.trim(),
        }),
      };
      dashboardItems
        .fetchStateDashboard({
          phase: selectedPhase.id,
          currentPermission,
          params,
        })
        .then((response) => {
          setFetching(false);
          if (response.isAxiosError) {
            if (
              response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED
            ) {
              setIsUnauthorized(true);
              return;
            }
            toast.error("Something went wrong...");
            return;
          }
          setDashboardData(response);
          getStateList();
        });
    };
    getStateDashboard();
  }, [currentPermission, selectedStates, selectedPhase, searchTerm]);

  if (isUnauthorized) return <NotAuthorized />;

  const handlePhaseSelect = (selectedPhase) => setSelectedPhase(selectedPhase);

  return (
    <div className="relative">
      <Heading item={{ title: "State Dashboard" }} />
      <div className="print:hidden absolute top-0 right-0">
        <MultipleSelectCheckmarks
          value={selectedStates}
          handleChange={handleSelectedStateInput}
          handleItem={handleSelectLabel}
          placeholder="Select State"
          items={stateList}
        />
      </div>
      <div className="my-10 print:hidden">
        <DashboardPhaseSelector
          disabled={fetching}
          onSelect={handlePhaseSelect}
        />
      </div>
      {!fetching && selectedPhase && dashboardData && (
        <DashboardItems
          phase={selectedPhase.id}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          cancelSearch={cancelSearch}
          dashboardItems={dashboardData}
        />
      )}
    </div>
  );
};

export default State;
