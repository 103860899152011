import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";
import { REPORT_TYPE_BACKEND_MAPPER } from "./constants";
import PageBreak from "./PageBreak";
import BorderedText from "../BorderedText";

const VidhanSabhaPDF = ({ items }) => {
  const tableItems = items.map((tableData, tableIndex) => {
    const { type } = tableData;
    const tableKey = `vs-table-${uuidv4()}`;
    if (type === REPORT_TYPE_BACKEND_MAPPER.PAGEBREAK) return <PageBreak key={tableKey} />
    const { title, headers, data } = tableData;
    const headerItems = headers.map((header, index) => {
        const headerKey = `vs-thead-th-${uuidv4()}`;
        return <th className={"first:w-56	font-bold box-border p-3"} key={headerKey}>{header.name}</th>
    });

    const rows = data.map((row) => {
        const rowKey = `vs-tr-${uuidv4()}`;
        const rowItem = row.map((rowData, rowIndex) => {
            const rowDataKey = `vs-tr-td-${uuidv4()}`;
            const rowClassName = classnames("first:font-semibold first:text-left text-center box-border py-2.5 px-3 first:left-0 relative",
              rowIndex === 0 ? "first:bg-[#E3ECFF]" : "odd:bg-[#F3F7FF] even:bg-[#F8FBFF]")
            return (<td className={rowClassName} key={rowDataKey}>{rowData.name}</td>)
        })
        return (<tr className="divide-x-2 last:border-0 border-b first:text-left border-[#9DAED2] divide-white" key={rowKey}>{rowItem}</tr>)
    })

    return (<div key={tableKey} className="bg-white rounded-lg min-w-full relative bg-white rounded-lg px-5 print:pb-6 last:pb-6 print:shadow last:shadow print:mt-10 first:mt-10">
      <table className="break-inside-avoid">
        <thead>
            {title && <tr className="text-left">
              <th className="pt-4" colSpan={headers ? headers.length + 1 : 0}><BorderedText className="pl-4 pb-7 whitespace-pre" text={title} /></th>
            </tr>}
            <tr className="divide-x-2 bg-[#E3ECFF] first:text-left border-b border-[#9DAED2] divide-white">{headerItems}</tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>)
  });

  return (<div className="mt-10 box-border">
    { tableItems }
  </div>)
}

export default VidhanSabhaPDF;