import { Field, Form, Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import BorderedText from "../../../components/BorderedText";
import Button from "../../../components/Button";
import DateInput from "../../../components/FormInputs/DateInput";
import OverlappingSelect from "../../../components/FormInputs/OverlappingSelect";
import SimpleSelect from "../../../components/FormInputs/SimpleSelect";
import { useVistarakFilters } from "../../../libs/vistarak/hooks";
import * as Yup from "yup";
import useAuth from "../../../utils/useAuth";
import { APP_PERMISSION_BACKEND_MAPPER } from "../../../utils/internalLinks";
import classNames from "classnames";

const StateField = ({isVidhanSabha}) => {
  const auth = useAuth();
  const { currentPermission } = auth;
  const {
    values: { zonal_incharge_id },
    setFieldValue,
  } = useFormikContext();

  const { data: state } = useVistarakFilters({
    currentPermission,
    filterType: "STATE",
    options: {
      enabled: !!zonal_incharge_id,
    },
    params: { zonal_incharge_id: zonal_incharge_id },
  });

  const stateOptions = state?.map((v) => {
    return (
      <option key={v.id} value={v.id}>
        {v.display_name}
      </option>
    );
  });

  const handleChange = (e) => {
    setFieldValue("state_id", e.target.value);
    setFieldValue("pc_id", "");
    if (isVidhanSabha) {
      setFieldValue("ac_id", "");
    }
  };

  return (
    <Field
      onChange={handleChange}
      name="state_id"
      disabled={!zonal_incharge_id}
      as={SimpleSelect}
    >
      <option value="">Select State</option>
      {stateOptions}
    </Field>
  );
};

const PCField = ({isVidhanSabha}) => {
  const auth = useAuth();
  const { currentPermission } = auth;
  const {
    values: { zonal_incharge_id, state_id },
    setFieldValue,
  } = useFormikContext();

  const { data: pc } = useVistarakFilters({
    currentPermission,
    filterType: "PC",
    options: {
      enabled: !!zonal_incharge_id && !!state_id.trim(),
    },
    params: { zonal_incharge_id, state_id },
  });

  const pcOptions = pc?.map((v) => {
    return (
      <option key={v.id} value={v.id}>
        {v.display_name}
      </option>
    );
  });

  const handleChange = (e) => {
    setFieldValue("pc_id", e.target.value);
    if (isVidhanSabha) {
      setFieldValue("ac_id", "");
    }
  };



  return (
    <Field
      name="pc_id"
      onChange={handleChange}
      disabled={!zonal_incharge_id || !state_id}
      as={SimpleSelect}
    >
      <option value="">Select Lok Sabha</option>
      {pcOptions}
    </Field>
  );
};

const ACField = () => {
  const auth = useAuth();
  const { currentPermission } = auth;
  const {
    values: { zonal_incharge_id, state_id, pc_id },
  } = useFormikContext();

  const { data: ac } = useVistarakFilters({
    currentPermission,
    filterType: "AC",
    options: {
      enabled: !!zonal_incharge_id && !!state_id.trim() && !!pc_id.trim(),
    },
    params: { zonal_incharge_id, state_id, pc_id },
  });

  const acOptions = ac?.map((v) => {
    return (
      <option key={v.id} value={v.id}>
        {v.display_name}
      </option>
    );
  });


  return (
    <Field
      name="ac_id"
      disabled={!zonal_incharge_id || !state_id || !pc_id}
      as={SimpleSelect}
    >
      <option value="">Select Vidhan Sabha</option>
      {acOptions}
    </Field>
  );
};

const formSchema = Yup.object().shape({
  start_date: Yup.date(),
  end_date: Yup.date().min(
    Yup.ref("start_date"),
    "End date should be greater than or equal to start date"
  ),
});

function Filters({ onSubmit, filters }) {
  const auth = useAuth();
  const { currentPermission } = auth;
  const { data: zonalIncharge } = useVistarakFilters({
    currentPermission,
    filterType: "ZONAL_INCHARGE",
  });

  const zonalInchargeOptions = zonalIncharge?.map((v) => {
    return (
      <option key={v.id} value={v.id}>
        {v.display_name}
      </option>
    );
  });

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const  isVidhanSabha = currentPermission?.name === APP_PERMISSION_BACKEND_MAPPER.VS_INCHARGE_DASHBOARD
  
  return (
    <div className="py-6 px-9 bg-white rounded-xl">
      <BorderedText text="Filters" />
      <Formik
        onSubmit={handleSubmit}
        validationSchema={formSchema}
        enableReinitialize={true}
        initialValues={{
          zonal_incharge_id: filters?.zonal_incharge_id || "",
          state_id: filters?.state_id || "",
          pc_id: filters?.pc_id || "",
          start_date: filters?.start_date || "",
          end_date: filters?.end_date || "",
          ...(isVidhanSabha && {
            ac_id: filters?.ac_id || "",
          })
        }}
      >
        {({ values, setFieldValue, resetForm }) => {
          const handleReset = () => {
            resetForm({
              values: {
                zonal_incharge_id: "",
                state_id: "",
                pc_id: "",
                start_date: "",
                end_date: "",
                ...(isVidhanSabha && {
                  ac_id: ""
                })
              },
            });
          };

          const handleZonalChange = (e) => {
            setFieldValue("zonal_incharge_id", e.target.value);
            setFieldValue("state_id", "");
            setFieldValue("pc_id", "");
            if (isVidhanSabha) {
              setFieldValue("ac_id", "");
            }
          };

          const cn = classNames("grid grid-cols-4 gap-x-20 gap-y-6 mt-6", isVidhanSabha ? "grid-cols-4" : "grid-cols-3");

          return (
            <Form>
              <div className={cn}>
                <Field
                  onChange={handleZonalChange}
                  name="zonal_incharge_id"
                  as={SimpleSelect}
                >
                  <option value="">Zonal Incharge</option>
                  {zonalInchargeOptions}
                </Field>
                <StateField isVidhanSabha={isVidhanSabha} />
                <PCField isVidhanSabha={isVidhanSabha} />
                {
                  isVidhanSabha && <ACField />
                }
                <DateInput label="Date From" name="start_date" />
                <DateInput label="Date To" name="end_date" />
                <div className="flex items-center gap-12">
                  <Button
                    className="bg-[#FF9955] border rounded-xl border-white text-white text-lg h-10 px-9"
                    type="submit"
                  >
                    Apply
                  </Button>
                  <Button
                    className="border border-grey-400 rounded-xl text-lg h-10 px-9"
                    onClick={handleReset}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Filters;
