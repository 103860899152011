import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as client from "./client";

export function useVistarakDashboard({ currentPermission, params }) {
  return useQuery(
    ["vistarak", params, currentPermission],
    () => client.fetchVistarakOverview({ currentPermission, params }),
    {
      enabled: !!currentPermission,
    }
  );
}

export function useVistarakFilters({
  currentPermission,
  filterType,
  options,
  params = {},
}) {
  return useQuery(
    ["vistarak", "filters", filterType, params, currentPermission],
    () =>
      client.fetchVistarakFilters(
        { currentPermission, filterType, params },
        {
          ...options,
        }
      ),
      {
        enabled: options?.enabled,
      }
  );
}

export function useVistarakReport({ currentPermission, params, options = {} }) {
  return useQuery(
    ["vistarak", "report", params, currentPermission],
    () => client.fetchVistarakReport({ currentPermission, params }),
    {
      ...options,
    }
  );
}

export function useVistarakReportExcel({
  currentPermission,
  params,
  options = {},
}) {
  return useQuery(
    ["vistarak", "report", currentPermission, params, "excel"],
    () => client.fetchVistarakReportExcel({ currentPermission, params }),
    {
      ...options,
    }
  );
}

export function useVistarakReportTaskOverview({
  currentPermission,
  params,
  taskType,
  options,
  pcId,
}) {
  return useQuery(
    ["vistarak", "report", "task", taskType, params, currentPermission],
    () =>
      client.fetchVistarakReportTaskOverview({
        currentPermission,
        params,
        taskType,
        pcId,
      }),
    {
      ...options,
    }
  );
}

export function useVistarakReportTaskReport({
  currentPermission,
  params,
  taskType,
  options,
  pcId,
}) {
  return useQuery(
    ["vistarak", "report", "task", taskType, params, currentPermission],
    () =>
      client.fetchVistarakReportTaskReport({
        currentPermission,
        params,
        taskType,
        pcId,
      }),
    {
      ...options,
    }
  );
}

export function useVistarakReportTaskReview({ currentPermission }) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ meetingId, data }) =>
      client.updateVistarakReportTaskReview({
        currentPermission,
        meetingId,
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vistarak", "report", "task"]);
      },
    }
  );
}

export function useVistarakReportTaskMedia({
  currentPermission,
  meetingId,
  isOpen,
}) {
  return useQuery(
    ["vistarak", "report", "task", "media", meetingId, currentPermission],
    () =>
      client.fetchVistarakReportTaskMeetingMedia({
        currentPermission,
        meetingId,
      }),
    {
      enabled: !!currentPermission && !!meetingId && isOpen,
    }
  );
}

export function useVistarakReportTaskParticipants({
  currentPermission,
  meetingId,
  isOpen,
}) {
  return useQuery(
    ["vistarak", "report", "task", "participants", meetingId],
    () =>
      client.fetchVistarakReportTaskMeetingParticipants({
        currentPermission,
        meetingId,
      }),
    {
      enabled: !!currentPermission && !!meetingId && isOpen,
    }
  );
}
