import React, { useState } from "react";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import MetaBar from "../../components/MetaBar";
import Title from "../../components/Title";
import {
  useVistarakDashboard,
  useVistarakReport,
  useVistarakReportExcel,
} from "../../libs/vistarak/hooks";
import useAuth from "../../utils/useAuth";
import DashboardTable from "./components/dashboard-table";
import Filters from "./components/filters";
import { ERROR_STATUS_CODE } from "../../utils/constants";
import NotAuthorized from "../../components/NotAuthorized";
import TaskTitle from "./Report/components/task-title";
import { APP_PERMISSION_BACKEND_MAPPER } from "../../utils/internalLinks";

function Vistarak() {
  const auth = useAuth();
  const { currentPermission } = auth;
  const  isVidhanSabha = currentPermission?.name === APP_PERMISSION_BACKEND_MAPPER.VS_INCHARGE_DASHBOARD

  const [filter, setFilter] = useState({
    zonal_incharge_id: "",
    state_id: "",
    pc_id: "",
    start_date: "",
    end_date: "",
    ...(isVidhanSabha && {
      ac_id: ""
    })
  });
  const {
    data: overview,
    isLoading,
    error,
  } = useVistarakDashboard({
    currentPermission,
    params: {
      filter,
    },
  });

  const {
    data: report,
    isLoading: loading,
    isError,
  } = useVistarakReport({
    currentPermission,
    params: {
      filter: filter,
    },
  });

  const { refetch: excelFetch, isFetching: fetchingExcel } =
    useVistarakReportExcel({
      currentPermission,
      params: {
        filter: filter,
      },
      options: {
        enabled: false,
      },
    });

  const handleExcel = () => {
    excelFetch();
  };

  if (
    error?.isAxiosError &&
    error?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED
  ) {
    return <NotAuthorized />;
  }

  if (isLoading) return <FullPageLoader />;
  if (!overview) return null;

  const handleFilters = (filter = null) => setFilter(filter);
  return (
    <div>
      <TaskTitle
        imageSrc={overview.icon}
        taskTitle={"All Vistarak"}
        title={overview.title}
      />
      <MetaBar meta={overview.meta} />
      <div className="mt-12 print:hidden">
        <Filters filters={filter} onSubmit={handleFilters} />
      </div>
      <div className="mt-8 bg-white p-5 rounded-xl">
        {loading && !isError && <FullPageLoader />}
        {isError && (
          <p className="text-red-500 text-lg font-bold">
            Something went wrong!
          </p>
        )}
        <DashboardTable
          fetchingExcel={fetchingExcel || false}
          handleExcel={handleExcel}
          filters={filter}
          data={report}
        />
      </div>
    </div>
  );
}

export default Vistarak;
