import classNames from "classnames";
import React from "react";
import ReactShowMoreText from "react-show-more-text";

const ShowMoreText = ({
  moreText,
  linkClassName,
  textClassName,
  lessText,
  lines,
  children,
}) => {
  const textClassNames = classNames(
    "whitespace-pre-line break-words font-medium text-gray-800",
    textClassName ? textClassName : "text-xs"
  );
  const linkClassNames = classNames(
    "text-[#5384FC]",
    linkClassName ? linkClassName : "text-xs"
  );
  return (
    <ReactShowMoreText
      more={moreText || "Read more"}
      truncatedEndingComponent="..."
      className={textClassNames}
      anchorClass={linkClassNames}
      lines={lines || 4}
      less={lessText || "Read less"}
    >
      {children}
    </ReactShowMoreText>
  );
};

export default ShowMoreText;
