import Logo from "images/BJP-logo.svg";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import BackgroundImage from "images/bg-bjp-new.png";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { HOME_LINK } from "../../utils/internalLinks";
import useAuth from "../../utils/useAuth";
import BJPLogo from "images/BJP-logo.png";
import classnames from "classnames";
import Help from "./Help";
import classNames from "classnames";

const Header = () => {
  const auth = useAuth();
  const [isVistarak, setIsVistarak] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { permissions, homeLink, currentPermission, handlePermissionChange } =
    auth;

  const handleSignOut = () => {
    auth.signout();
  };

  const handlePermissionChangeAndRedirect = (permissionId) => {
    const newHomeLink = handlePermissionChange(permissionId);
    navigate(newHomeLink);
  };

  useEffect(() => {
    if (location) {
      setIsVistarak(location.pathname.includes("vistarak"));
    }
  }, [location]);

  const headerClassName = classNames(
    "z-20 border shadow-lg fixed print:relative bg-white w-full top-0 left-0",
    isVistarak && "print:hidden"
  );

  const imageClassName = classNames(
    "w-auto h-auto max-h-full hidden bg-neutral-50",
    isVistarak && "print:block"
  );
  return (
    <>
      <header className={headerClassName}>
        <div
          className="print:max-w-full	max-w-8xl mx-auto sm:px-6 lg:px-16"
          aria-label="Top"
        >
          <div className="w-full py-[12px] print:justify-center flex items-center justify-between border-none">
            <div className="hidden print:flex items-center justify-center">
              <img
                src={BJPLogo}
                className="w-10 h-10 object-contain block"
                alt="Bhartiya Janta Party"
              />
              <span className="ml-[16px] poppins text-[22px] text-[#5A5A61] font-semibold">
                भारतीय जनता पार्टी
              </span>
            </div>
            <Link
              className="flex print:hidden items-center"
              to={homeLink || HOME_LINK}
            >
              <img
                src={Logo}
                className="w-10 h-10"
                alt="Bhartiya Janta Party"
              />
              <span className="ml-[16px] poppins text-[22px] text-[#5A5A61] font-semibold">
                भारतीय जनता पार्टी
              </span>
            </Link>
            <div className="flex w-[428px] print:hidden items-center justify-between">
              <Help />
              {auth.user ? (
                <Menu
                  as="div"
                  className="print:hidden relative inline-block text-left"
                >
                  <div className="w-56 print:w-full">
                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                      <div className="w-48">
                        <p className="whitespace-nowrap w-40 overflow-hidden text-ellipsis">
                          {auth.user.name}
                        </p>
                        {currentPermission && (
                          <p className="whitespace-nowrap overflow-hidden text-ellipsis text-xs">
                            {currentPermission.name}
                          </p>
                        )}
                      </div>
                      <ChevronDownIcon
                        className="-mr-1 ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                      {permissions && !!(permissions.length > 1) && (
                        <div className="py-1">
                          {permissions.map((permission) => {
                            const handleChange = () =>
                              handlePermissionChangeAndRedirect(permission.id);
                            return (
                              <Menu.Item key={permission.id}>
                                {({ active }) => (
                                  <p
                                    onClick={handleChange}
                                    className={classnames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm",
                                      currentPermission &&
                                        currentPermission.id === permission.id
                                        ? "bg-indigo-50 font-bold"
                                        : ""
                                    )}
                                  >
                                    {permission.name}
                                  </p>
                                )}
                              </Menu.Item>
                            );
                          })}
                        </div>
                      )}
                      <div className="py-1 w-56">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={handleSignOut}
                              href={auth.signOutUrl}
                              className={classnames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Sign Out
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <div className="w-56"></div>
              )}
            </div>
          </div>
        </div>
      </header>
      <img src={BackgroundImage} className={imageClassName} alt="LS Pravas" />
    </>
  );
};

export default Header;
