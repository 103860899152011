import { ClientApi } from "./client";
import { EXTERNAL_LINKS } from "./links";

export const fetchOverAllFeedBackForm = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(
      EXTERNAL_LINKS.OVERALL_FEEDBACK.QUESTIONS({
        clusterId,
        pcId,
        visitId,
        reportType,
      })
    )
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const fetchOverAllFeedback = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(EXTERNAL_LINKS.CLUSTERS.FORM({ clusterId, pcId, visitId, reportType }))
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const updateOverAllFeedBackForm = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  data,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .post(
      EXTERNAL_LINKS.CLUSTERS.FORM({ clusterId, pcId, visitId, reportType }),
      data
    )
    .then((response) => response.data.data)
    .catch((error) => error);
};
