import React, { memo } from "react";
import BorderedText from "../BorderedText";
import t from "prop-types";
import EditBtn from "./EditBtn";
import { useParams } from "react-router-dom";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../utils/internalLinks";
import usePhase from "../../utils/usePhase";
import classNames from "classnames";

const String = ({ name }) => (
  <div className="h-full flex justify-center items-center whitespace-pre p-4 box-border break-words text-center">
    {name}
  </div>
);

const Empty = ({ empty }) => {
  const { bg_color, icon } = empty;
  const styles = { backgroundColor: bg_color };
  return (
    <div className="h-full flex justify-center items-center" style={styles}>
      <img src={icon} />
    </div>
  );
};

const VidhanSabha = ({ tableData, type, visit, isPDF }) => {
  if (!tableData || (tableData && !tableData.length)) return null;
  const { clusterId, pcId } = useParams();
  const phase = usePhase();
  const editLink = PARLIAMENT_CONSTITUENCY_LINKS.VISIT_TYPE(
    clusterId,
    pcId,
    visit.id,
    type,
    phase
  );
  const tableItems = tableData.map((table, i) => {
    const { key, rows, title, headers } = table;
    const tableHeaderItems = headers.map((header, i) => {
      const { name, colspan } = header;
      const className = classNames(
        "p-4 last:rounded-tr-lg align-top text-center first:rounded-tl-lg border-r-4 border-white bg-[#4471B0] last:border-r-0 ",
        typeof colspan === "number" ? "w-1/4" : ""
      );
      return (
        <th className={className} key={i} colSpan={colspan || 1}>
          {name}
        </th>
      );
    });
    const rowsItems = rows.map((rowData, i) => {
      const rowItem = rowData.map((row, j) => {
        const { rowspan, type, phase: rowPhase, is_bold } = row;
        const Cell = type === "empty" ? Empty : String;
        const className = classNames(
          "border-4 h-full border-white",
          rowPhase === parseInt(phase) ? "bg-[#E5ECFD]" : "bg-[#F8FAFF]",
          is_bold && "font-semibold"
        );
        return (
          <td key={j} className={className} rowSpan={rowspan || 1}>
            {Cell && <Cell {...row} />}
          </td>
        );
      });
      return <tr key={i}>{rowItem}</tr>;
    });
    return (
      <div key={i} className="print:break-inside-avoid mt-10 first:mt-0">
        <BorderedText text={title} />
        <table className="h-min print:break-inside-avoid  w-full mt-5">
          <thead>
            <tr className="text-base font-medium text-white">
              {tableHeaderItems}
            </tr>
          </thead>
          <tbody>{rowsItems}</tbody>
        </table>
      </div>
    );
  });
  return (
    <div className="py-5 mt-8 px-3 box-border bg-white rounded-lg shadow border border-gray-200">
      {tableItems}
      {!isPDF && (
        <div className="mt-9 flex items-center justify-end">
          <EditBtn link={editLink} />
        </div>
      )}
    </div>
  );
};

VidhanSabha.propTypes = {
  tableData: t.array,
  type: t.string,
  isPDF: t.bool,
  visit: t.object,
};

VidhanSabha.defaultProps = {
  tableData: [],
  isPDF: false,
  type: "",
  visit: null,
};

export default memo(VidhanSabha);
