import React, { memo } from "react";
import t from "prop-types";
import { useParams } from "react-router-dom";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../../utils/internalLinks";
import usePhase from "../../../utils/usePhase";
import EditBtn from "../EditBtn";
import QuestionContainer from "../../../pages/ReportType/PravasDays/components/QuestionContainer";

const PravasDays = ({ type, data, visit, isReadOnly, isPDF }) => {
  const { clusterId, pcId } = useParams();
  const phase = usePhase();

  const editLink = PARLIAMENT_CONSTITUENCY_LINKS.VISIT_TYPE(
    clusterId,
    pcId,
    visit?.id || "",
    type,
    phase
  );

  const questionsList = data?.questions?.length ? (
    data.questions.map((question, index) => {
      const link = editLink + `?q=${question.id}`;
      return (
        <div
          key={question.id}
          className="shadow border pb-10 border-gray-200 bg-white rounded-lg mt-8"
        >
          <QuestionContainer
            isPDF={isPDF}
            isReadOnly={isReadOnly}
            index={index + 1}
            question={question}
          />
          {!isPDF && (
            <div className="flex px-7 justify-end">
              <EditBtn link={link} />
            </div>
          )}
        </div>
      );
    })
  ) : (
    <div className="bg-white rounded-lg mt-7 p-4 box-border">
      <p className="break-words text-lg">No Virtual Pravas done as of now.</p>
      {!isPDF && (
        <div className="flex px-7 justify-end">
          <EditBtn link={editLink} />
        </div>
      )}
    </div>
  );

  return <div>{questionsList}</div>;
};

PravasDays.propTypes = {
  type: t.string,
  isPDF: t.bool,
  visit: t.object,
};

PravasDays.defaultProps = {
  type: "",
  isPDF: false,
  visit: null,
};

export default memo(PravasDays);
