import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Button from "../../../components/Button";
import { toast } from "react-toastify";
import Title from "../../../components/Title";
import { fetchOverAllFeedback } from "../../../services/overallFeedback";
import useAuth from "../../../utils/useAuth";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../../utils/internalLinks";
import FullPageLoader from "../../../components/Loaders/FullPageLoader";
import NotAuthorized from "../../../components/NotAuthorized";
import { ERROR_STATUS_CODE } from "../../../utils/constants";
import usePhase from "../../../utils/usePhase";
import OverallFeedbackTable from "./components/OverallFeedbackTable";

const OverallFeedback = () => {
  const auth = useAuth();
  const { currentPermission } = auth;
  const { clusterId, pcId, visitId, reportType } = useParams();
  const currentPhase = usePhase();
  const [fetching, setFetching] = useState(false);
  const [overallFeedbackData, setOverallFeedbackData] = useState(null);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  useEffect(() => {
    const getOverallFeedbackForm = async () => {
      if (!currentPermission || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      const response = await fetchOverAllFeedback({
        phase: currentPhase,
        currentPermission,
        clusterId,
        pcId,
        visitId,
        reportType,
      });
      setFetching(false);
      if (response.isAxiosError) {
        if (response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED) {
          setIsUnauthorized(true);
          return;
        }
        toast.error("Something went wrong!");
        return;
      }
      if (response) {
        setOverallFeedbackData(response);
      }
    };
    getOverallFeedbackForm();
  }, [currentPermission, currentPhase]);

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  if (!overallFeedbackData) return null;

  const link = PARLIAMENT_CONSTITUENCY_LINKS.VISIT(
    clusterId,
    pcId,
    visitId,
    currentPhase
  );

  return (
    <div>
      <Title
        imageSrc={overallFeedbackData.image}
        title={overallFeedbackData.name}
      />
      <div className="mt-8">
        <OverallFeedbackTable overallFeedbackData={overallFeedbackData} />
        <div className="flex items-center justify-center mt-8">
          <Button.Rounded to={link} as={Link}>
            <span className="font-medium text-#163560]">Done</span>
          </Button.Rounded>
        </div>
      </div>
    </div>
  );
};

export default OverallFeedback;
