import React from "react";
import Rounded from "./Rounded";
import Download from "./Download";

const Button = ({ as, ...props }) => {
  const Component = as || "button";
  return (
    <Component
      {...props}
      className={`rounded-lg ${props.className || ""} disabled:opacity-50`}
    >
      {props.children}
    </Component>
  );
};

Button.Rounded = Rounded;
Button.Download = Download;

export default Button;
