import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { phasesService } from "../services";
import { ERROR_STATUS_CODE } from "../utils/constants";
import useAuth from "../utils/useAuth";

function DashboardPhaseSelector({ onSelect, disabled }) {
  const auth = useAuth();
  const { currentPermission } = auth;
  const [fetching, setFetching] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [phases, setPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(null);

  const handlePhaseChange = (selectedPhase) => {
    if (disabled) return;
    setSelectedPhase(selectedPhase);
    onSelect?.(selectedPhase);
  };

  useEffect(() => {
    const getPhases = () => {
      if (!currentPermission || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      phasesService.fetchPhases({ currentPermission }).then((response) => {
        setFetching(false);
        if (
          response?.isAxiosError &&
          response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED
        ) {
          setIsUnauthorized(true);
          return;
        }
        if (response && response.length && !response.isAxiosError) {
          setPhases([...response].reverse());
          setSelectedPhase(response[response.length - 1]);
          onSelect?.(response[response.length - 1]);
        }
      });
    };
    getPhases();
  }, [currentPermission]);

  if (!phases.length || isUnauthorized) return null;

  const phasesItem = phases.map((phase) => {
    const isActive = phase.id === selectedPhase?.id;
    const onClick = () => handlePhaseChange(phase);
    const containerClassName = classNames(
      "flex-1 h-20 cursor-pointer shadow-[1px_2px_2px_rgba(0,0,0,0.25)] inline-flex items-center justify-center rounded-xl	",
      isActive ? "bg-gradient-to-b from-[#FFBA79] to-[#ED765E]" : "bg-white",
      disabled ? "opacity-75" : ""
    );
    const textClassName = classNames(
      "text-xl poppins font-medium",
      isActive ? "text-white" : ""
    );
    return (
      <div className={containerClassName} onClick={onClick} key={phase.id}>
        <img
          className="w-[35px] h-[35px] mr-4"
          src={phase.icon}
          alt={phase.name}
        />
        <span className={textClassName}>{phase.name}</span>
      </div>
    );
  });
  return <div className="flex gap-2.5">{phasesItem}</div>;
}

export default DashboardPhaseSelector;
