import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import CrossIcon from "images/cross.svg";
import OverlappingTextInput from "../FormInputs/OverlappingTextInput";
import OverlappingSelect from "../FormInputs/OverlappingSelect";

const MediaFormItem = ({ formItems, 
    value, 
    handleUpdate,
    canRemove,
    handleRemove }) => {

    if (!formItems.length) return null;

    const formItemsArr = formItems.map((item, index) => {
        let component;
        const { placeholder } = item;

        const handleInputChange = e => {
            const { target: { value: formValue, name } } = e;
            let newValue = {
                [name]: formValue
            }
            handleUpdate(value.customId, newValue)
        }

        const handleSelectChange = e => {
            const { target: { value: formValue, name } } = e;
            const fieldValue = formValue
                ? item.field_items.filter((item) => item.id === parseInt(formValue))[0]
                : null
            let newValue = {
                [name]: fieldValue
            }
            handleUpdate(value.customId, newValue);
        }

        switch (item.type) {
            case "input":
                component = <div className="w-[478px] mr-3">
                    <OverlappingTextInput onChange={handleInputChange} 
                        name={item.field_name}
                        className="h-full"
                        value={value[item.field_name] || ''} 
                        placeholder={placeholder} />
                    </div>;
                break;
            case "select":
                const { field_items } = item;
                const selectItems = field_items.map((select) => <option key={select.id} 
                    value={select.id}>
                        {select.name}
                    </option>)
                component = <OverlappingSelect name={item.field_name}
                    onChange={handleSelectChange}
                    value={value[item.field_name]?.id || ''}>
                    <option>Select Platform</option>
                    {selectItems}
                </OverlappingSelect>
                break;
            default:
                return null;
        }

        if (!component) return component;
        
        return <React.Fragment key={index}>{component}</React.Fragment>
    });

    return <div className="flex items-center justify-between mt-8 first:mt-11">
        <div className="flex">
            {formItemsArr}
        </div>
        <Button type="button" 
            className="flex items-center"
            onClick={handleRemove}>
            <img src={CrossIcon} className="mr-2.5" alt="remove" />
            <span className="poppins font-medium text-xl text-[#ED5F61]">Remove</span>
        </Button>
    </div>
}

MediaFormItem.propTypes = {
    formItems: PropTypes.array,
    handleRemove: PropTypes.func,
    canRemove: PropTypes.any,
    handleUpdate: PropTypes.func
}

MediaFormItem.defaultProps = {
    formItems: [],
    canRemove: false,
    handleRemove: () => {},
    handleUpdate: () => {}
}

export default MediaFormItem;