import React from "react";
import PropTypes from "prop-types";
import MediaFolders from "./MediaFolders";
import MediaForm from "./MediaForm";
import { v4 as uuidv4 } from "uuid";

const MEDIA_TYPES_BACKEND_MAPPER = {
    FOLDER: 'folder',
    MEDIA_FORM: 'media_form'
}

const TYPES = {
    [MEDIA_TYPES_BACKEND_MAPPER.FOLDER]: MediaFolders,
    [MEDIA_TYPES_BACKEND_MAPPER.MEDIA_FORM]: MediaForm
}
const MediaItems = ({ items, isPDF }) => {
    if (!items?.length) return null;
    const mediaItems = items.map((item) => {
        const { type } = item;
        const customId = uuidv4();
        const Component = TYPES[type] || null;
        if (!Component) return null;
        return <Component key={customId} isPDF={isPDF} {...item} />
    })
    return <>
        {mediaItems}
    </>
}

MediaItems.propTypes = {
    items: PropTypes.array,
    isPDF: PropTypes.bool
}

MediaItems.defaultProps = {
    items: [],
    isPDF: false
}

export default MediaItems;