import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MediaFormItem from "./MediaFormItem";
import BorderedText from "../BorderedText";
import Button from "../Button";
import { v4 as uuidv4 } from "uuid";

const createBlankFormValues = (formItems) => {
    let value = {
        customId: uuidv4()
    };
    formItems.forEach((formItem) => {
        value[formItem.field_name] = ''
    })
    return value;
}

const FORM_KEY = 'form';

const MediaForm = ({ formData: form, handleUpdate }) => {

    const [updateValueWithIds, setUpdateValueWithIds] = useState(false);

    const updateForm = (newFormData) => {
        handleUpdate(FORM_KEY, newFormData);
    }

    const appendNewFormUpdate = (itemId, value) => {
        let newForm = form.map((formItem) => {
            if (formItem.id !== itemId) return formItem;
            return {
                ...formItem,
                values: formItem.values
                    && formItem.values.length
                    ? formItem.values.concat(value)
                    : [value]
            }
        });
        updateForm(newForm);
    }

    useEffect(() => {
        const handleFormDefaultValuesWhereNotAvailable = () => {
            if (!form) return;
            let newForm = form.map((formItem) => {
                return {
                    ...formItem,
                    values: formItem.values 
                        && formItem.values.length 
                        ? formItem.values.map((value) => {
                            const customId = uuidv4()
                            return {
                                ...value,
                                customId
                            }
                        })
                        : [createBlankFormValues(formItem.form_items)]
                }
            });
            setUpdateValueWithIds(true);
            updateForm(newForm);
        }
        handleFormDefaultValuesWhereNotAvailable();
    }, []);

    if (!updateValueWithIds) return null;

    const handleFormValueRemove = (itemId, removeId) => {
        const newForm = form.map((formItem) => {
            if (formItem.id !== itemId) return formItem;
            return {
                ...formItem,
                values: formItem.values.filter((_) => _.customId !== removeId)
            }
        });
        updateForm(newForm);
    }

    const handleFormValueChange = (itemId, updateId, newValue) => {
        const newForm = form.map((formItem) => {
            if (formItem.id !== itemId) return formItem;
            return {
                ...formItem,
                values: formItem.values.map((_) => {
                    if (_.customId !== updateId) return _;
                    return {
                        ..._,
                        ...newValue
                    }
                })
            }
        });
        updateForm(newForm);
    }

    const formItems = form.map((item) => {
        const addFormValues = () => {
            let value = createBlankFormValues(item.form_items);
            appendNewFormUpdate(item.id, value)
        }

        const mediaFormItems = item.values.map((value) => {
            const handleRemove = () => handleFormValueRemove(item.id, value.customId);
            const handleUpdate = (valueId, newValue) => handleFormValueChange(item.id, valueId, newValue);
            return <MediaFormItem
                handleRemove={handleRemove} 
                handleUpdate={handleUpdate}
                key={value.customId} 
                value={value} 
                formItems={item.form_items} />
        })

        return <div key={item.id} className="first:mt-0 mt-14">
            <div className="flex items-center justify-between">
                <BorderedText text={item.name} />
                <Button className="h-10 py px-4 border border-[#163560] rounded-full text-lg text-[#163560]" 
                    type="button" 
                    onClick={addFormValues}>+ Add Link</Button>
            </div>
            {mediaFormItems}
        </div>
    });
    return <div className="bg-white p-8 box-border">{formItems}</div>;
}

MediaForm.propTypes = {
    form: PropTypes.array,
    handleUpdate: PropTypes.func
}

MediaForm.defaultProps = {
    form: [],
    handleUpdate: () => {}
}

export default MediaForm;