import React from "react";
import NotFoundImage from "images/404.svg";
import { Link } from "react-router-dom";

const NoMatch = () => {
  return (<div className="min-h-screen py-28 mx-auto max-w-8xl px-4 sm:px-6 lg:px-28 bg-neutral-50">
    <Link className="w-full block" to="/">
      <img src={NotFoundImage} className="mx-auto" alt="not found" />
    </Link>
  </div>)
}

export default React.memo(NoMatch);