import { ClientApi } from "./client";
import { EXTERNAL_LINKS } from "./links";

export const fetchPravasDaysForm = ({ currentPermission, clusterId, pcId, visitId, reportType, phase }) => {
  return ClientApi(currentPermission.id, phase).get(EXTERNAL_LINKS.CLUSTERS.FORM({clusterId, pcId, visitId, reportType}))
    .then(response => response.data.data)
    .catch(error => error);
}

export const addPravasDaysForm = ({ currentPermission, clusterId, pcId, visitId, reportType, phase, questionId, data }) => {
  return ClientApi(currentPermission.id, phase).post((EXTERNAL_LINKS.PRAVAS_DAYS.QUESTION({clusterId, pcId, visitId, questionId, reportType})), data)
    .then(response => response.data.data)
    .catch(error => error);
}

export const updatePravasDaysForm = ({ currentPermission, clusterId, pcId, visitId, reportType, phase, questionId, valueId, data }) => {
  return ClientApi(currentPermission.id, phase).patch((EXTERNAL_LINKS.PRAVAS_DAYS.QUESTION_VALUE({clusterId, pcId, visitId, questionId, reportType, valueId})), data)
    .then(response => response.data.data)
    .catch(error => error);
}


export const deletePravasDaysForm = ({ currentPermission, clusterId, pcId, visitId, reportType, phase, questionId, valueId }) => {
  return ClientApi(currentPermission.id, phase).delete((EXTERNAL_LINKS.PRAVAS_DAYS.QUESTION_VALUE({clusterId, pcId, visitId, questionId, reportType, valueId})))
    .then(response => response.data.data)   
    .catch(error => error);
}