import React, { memo } from "react";
import t from "prop-types";
import CheckIcon from "images/check.svg";
import { v4 as uuidv4 } from "uuid";

const Table = ({ tableData }) => {
  if (!tableData) return;
  const { headers, data } = tableData;
  
  const headerItems = headers
   && headers.length 
   ? headers.map((header, index) => {
    const headerKey = `of-header-${uuidv4()}`;
    const { color } = header;
    const styles = {
      ...(color && {
        background: color
      })
    }
    return <th key={headerKey} 
      colSpan={index ? 3 : 2} 
      style={styles}
      className="first:font-medium first:text-xl first:pl-10 bg-[#E3ECFF] box-border py-2.5 px-3 font-bold text-base first:text-left text-center">{header.name}</th>
  })
  : null;

  const rows = data 
    && data.length
    ? data.map((row) => {
      const rowKey = `of-tr-${uuidv4()}`;
      const rowItems = row
      && row.length 
        ? row.map((rowData, index) => {
            const { value } = rowData;
            const tdKey = `of-tr-td-${uuidv4()}`;
            const component = typeof value === "string"
              ? <p>{value}</p>
              : typeof value === "boolean"
                ? value 
                  ? <img src={CheckIcon} className="mx-auto" alt="checked" />
                  : null
                : null;
              const { color } = rowData;
              const styles = {
                ...(color && {
                  background: color
                })
              }
            return <td key={tdKey} 
              colSpan={index ? 3 : 2} 
              style={styles}
              className="first:font-medium poppins first:text-xl first:pl-10 first:text-left bg-[#F8FBFF] first:bg-[#E3ECFF] text-center box-border  py-2.5 px-3 relative">{component}</td>
        })
        : null;
      if (!rowItems) return rowItems;
      return (<tr key={rowKey} className="divide-x-2 last:border-0 border-b first:text-left border-[#9DAED2] divide-white">{rowItems}</tr>)
    })
    : null;
  return (<div>
    <table className="break-inside-avoid min-w-full relative">
        {
          headerItems
          && <thead>
              <tr className="divide-x-2 first:text-left border-b border-[#9DAED2] divide-white">{headerItems}</tr>
          </thead>
        }
        {
          rows
          && <tbody>{rows}</tbody>
        }
    </table>
  </div>)
}

Table.propTypes = {
  tableData: t.object
}

Table.defaultProps = {
  tableData: null
}

export default memo(Table);