import { ClientApi } from "../../services/client";
import { EXTERNAL_LINKS } from "../../services/links";
import contentDisposition from "content-disposition";
import qs from "qs";

const fetchVistarakOverview = async ({ currentPermission, params }) => {
  const response = await ClientApi(currentPermission.id, "", true).get(
    EXTERNAL_LINKS.VISTARAK.DASHBOARD,
    {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }
  );
  return response?.data?.data;
};

const fetchVistarakFilters = async ({
  currentPermission,
  filterType,
  params,
}) => {
  const response = await ClientApi(currentPermission.id, "", true).get(
    EXTERNAL_LINKS.VISTARAK.FILTERS[filterType],
    { params }
  );
  return response?.data?.data;
};

const fetchVistarakReport = async ({ currentPermission, params }) => {
  const response = await ClientApi(currentPermission.id, "", true).get(
    EXTERNAL_LINKS.VISTARAK.REPORT,
    {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }
  );
  return response?.data?.data;
};

const fetchVistarakReportExcel = async ({ currentPermission, params }) => {
  const response = await ClientApi(currentPermission.id, "", true).get(
    EXTERNAL_LINKS.VISTARAK.REPORT_EXCEL,
    {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
      responseType: "blob",
    }
  );
  const contentDispositionHeader = response.headers["content-disposition"]; // Extract the file name from the response
  const parsedContentDisposition = contentDisposition.parse(
    contentDispositionHeader
  );
  const fileName = parsedContentDisposition.parameters.filename;
  const blob = new Blob([response.data], { type: "application/octet-stream" });

  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = fileName;
  downloadLink.click();
  return true;
};

const fetchVistarakReportTaskOverview = async ({
  currentPermission,
  params,
  taskType,
  pcId,
}) => {
  const response = await ClientApi(currentPermission.id, "", true).get(
    EXTERNAL_LINKS.VISTARAK.TASK.OVERVIEW({ pcId, taskType }),
    {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }
  );
  return response?.data?.data;
};

const fetchVistarakReportTaskReport = async ({
  currentPermission,
  params,
  taskType,
  pcId,
}) => {
  const response = await ClientApi(currentPermission.id, "", true).get(
    EXTERNAL_LINKS.VISTARAK.TASK.REPORT({ pcId, taskType }),
    {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }
  );
  return response?.data?.data;
};

const updateVistarakReportTaskReview = async ({
  currentPermission,
  meetingId,
  data,
}) => {
  const response = await ClientApi(currentPermission.id, "", true).post(
    EXTERNAL_LINKS.VISTARAK.TASK.MEETING.REVIEW({ meetingId }),
    data
  );
  return response?.data?.data;
};

const fetchVistarakReportTaskMeetingMedia = async ({
  currentPermission,
  meetingId,
}) => {
  const response = await ClientApi(currentPermission.id, "", true).get(
    EXTERNAL_LINKS.VISTARAK.TASK.MEETING.MEDIA({ meetingId })
  );
  return response?.data?.data;
};

const fetchVistarakReportTaskMeetingParticipants = async ({
  currentPermission,
  meetingId,
}) => {
  const response = await ClientApi(currentPermission.id, "", true).get(
    EXTERNAL_LINKS.VISTARAK.TASK.MEETING.PARTICIPANTS({ meetingId })
  );
  return response?.data?.data;
};

export {
  fetchVistarakOverview,
  fetchVistarakFilters,
  fetchVistarakReport,
  fetchVistarakReportTaskOverview,
  fetchVistarakReportTaskReport,
  fetchVistarakReportTaskMeetingMedia,
  fetchVistarakReportTaskMeetingParticipants,
  updateVistarakReportTaskReview,
  fetchVistarakReportExcel,
};
