import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import { Link, useParams } from "react-router-dom";
import { MEDIA_LINK } from "../../utils/internalLinks";
import { REPORT_TYPE_BACKEND_MAPPER } from "../ClusterReportItems/constants";
import DustbinIcon from "images/dustbin.svg";
import usePhase from "../../utils/usePhase";

const MediaFolders = ({ folders, handleEdit, handleDelete }) => {
  if (!folders.length) return null;
  const { clusterId, pcId, visitId, reportType } = useParams();
  const currentPhase = usePhase();

  const folderItems = folders.map((folder) => {
    const { id, name, file_count, cover_image } = folder;

    const onEdit = (e) => {
      e.preventDefault();
      handleEdit(folder);
    };

    const onDelete = (e) => {
      e.preventDefault();
      handleDelete(folder);
    };

    const fileLinks = MEDIA_LINK.SINGLE_FOLDER({
      reportType: reportType || REPORT_TYPE_BACKEND_MAPPER.MEDIA,
      phase: currentPhase,
      folderId: id,
      clusterId,
      pcId,
      visitId,
    });

    const imageUrl = cover_image?.image_path || cover_image?.url;
    return (
      <Link
        to={fileLinks}
        key={id}
        className="rounded-lg relative p-4 box-border border border-dashed border-gray-300"
      >
        <Button
          className="absolute top-2 right-2"
          onClick={onDelete}
          type="button"
        >
          <img src={DustbinIcon} alt="delete" />
        </Button>
        <p className="font-bold whitespace-nowrap text-lg text-ellipsis overflow-hidden mb-4">
          {name}
        </p>
        <div className="w-[312px] h-[216px] rounded-lg overflow-hidden mx-auto mb-2.5">
          {imageUrl && (
            <img
              className="w-[312px] object-top object-cover overflow-hidden rounded-lg h-[216px] bg-white"
              src={imageUrl}
            />
          )}
        </div>
        <div className="flex items-center justify-between">
          <Button
            type="button"
            className="py-1 px-10 cursor-pointer bg-[#61BE7A] font-bold text-lg text-white rounded-lg"
            onClick={onEdit}
          >
            Edit
          </Button>
          <Button
            type="button"
            className="py-1 px-5 cursor-pointer bg-[#F8E8DD] text-[#FF9559] font-semibold"
          >
            {file_count} File{file_count >= 1 ? "s" : ""}
          </Button>
        </div>
      </Link>
    );
  });

  return folderItems;
};

MediaFolders.propTypes = {
  folders: PropTypes.array,
  handleEdit: PropTypes.func,
};

MediaFolders.defaultProps = {
  folders: [],
  handleEdit: () => {},
};
export default MediaFolders;
