import { Form, Formik } from 'formik';
import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import BorderedText from '../../../components/BorderedText';
import DateInput from '../../../components/FormInputs/DateInput';
import { parliamentConstituencies } from '../../../services';
import useAuth from '../../../utils/useAuth';
import usePhase from '../../../utils/usePhase';
import Button from '../../Button';

const INITIAL_VALUES = {
  start_date: '',
  end_date: ''
}

const FormSchema = Yup.object().shape({
  start_date: Yup.date()
    .required('Required'),
  end_date: Yup.date()
    .required('Required')
    .min(Yup.ref('start_date'), 'End date should be greater than or equal to start date'),
});

const LokSabhaVisitCreateOrUpdateForm = ({ afterSubmit, values }) => {
  const auth = useAuth();
  const currentPhase = usePhase();
  const { currentPermission } = auth;
  const initialValues = values || INITIAL_VALUES;
  const { clusterId, pcId, visitId } = useParams();

  const handleSubmit = async (values, { setSubmitting }) => {
    const func = visitId 
      ? parliamentConstituencies.updateParliamentryConstituenciesVisit
      : parliamentConstituencies.createParliamentryConstituenciesVisit;
    const response = await func({currentPermission, clusterId, pcId, visitId, data: values, phase: currentPhase});
    if (response) {
      afterSubmit(response);
    } else {
      toast.error('Something went wrong');
    }
    setSubmitting(false);
  }

  return (<Formik onSubmit={handleSubmit} 
    initialValues={initialValues}
    validationSchema={FormSchema}>
    {({ isSubmitting }) => (
      <Form className="flex items-center justify-between flex-1">
        <BorderedText text={`${visitId ? "Edit Dates-" : "Create New Visit-"}`} />
        <div className="flex items-center">
          <DateInput name="start_date" />
          <DateInput name="end_date" />
        </div>
        <Button.Rounded type="submit" className="min-w-[163px] h-[40px]" disabled={isSubmitting}>
          { visitId ? "Save" : "Add Visit" }
        </Button.Rounded>
      </Form>
  )}
  </Formik>)
}

export default LokSabhaVisitCreateOrUpdateForm;