import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Question from "./Question";
import scrollIntoView from "scroll-into-view";
import { useSearchParams } from "react-router-dom";

const QuestionContainer = ({
  question,
  onUpdate,
  isReadOnly,
  isPDF,
  onDelete,
  index,
}) => {
  const { questions, id, name } = question;
  const [open, setOpen] = useState(isPDF || isReadOnly || false);
  const [searchParams] = useSearchParams();
  const [hasScrolled, setHasScrolled] = useState(false);
  const currentQuestionId = searchParams.get("q");
  const isPDForReadOnly = isPDF || isReadOnly;

  useEffect(() => {
    const scrollToItem = () => {};
    if (hasScrolled) return;
    setHasScrolled(true);
    scrollToItem();
    let ele = document.querySelector(".scroll-item");
    if (ele) {
      scrollIntoView(ele, { time: 0 });
      setOpen(currentQuestionId && parseInt(currentQuestionId) === id);
    }
  }, [hasScrolled]);

  const questionsList = questions.map((nestedQuestion, childIndex) => {
    return (
      <Question
        index={childIndex + 1}
        parentIndex={index}
        isPDF={isPDF}
        isReadOnly={isReadOnly}
        onUpdate={onUpdate}
        onDelete={onDelete}
        key={nestedQuestion.id}
        question={nestedQuestion}
      />
    );
  });

  const toggleQuestions = () => setOpen(!open);

  const questionClassName = classNames(
    "flex justify-between",
    isPDForReadOnly ? "" : "cursor-pointer"
  );

  const containerClassName = classNames(
    "py-7  px-9 ",
    isPDForReadOnly
      ? ""
      : "shadow border border-gray-200 bg-white rounded-lg mt-6 first:mt-0",
    currentQuestionId && parseInt(currentQuestionId) === id
      ? "bg-yellow-50 scroll-item"
      : ""
  );

  return (
    <div className={containerClassName}>
      <div className={questionClassName} onClick={toggleQuestions}>
        <p className="text-xl poppins font-medium">
          {index}. {name}
        </p>
        {!isPDForReadOnly &&
          (open ? (
            <ChevronUpIcon
              className="w-8 h-auto text-gray-500"
              aria-hidden="true"
            />
          ) : (
            <ChevronDownIcon
              className="w-8 h-auto text-gray-500"
              aria-hidden="true"
            />
          ))}
      </div>
      {open && questionsList}
    </div>
  );
};

export default QuestionContainer;
