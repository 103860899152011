import React from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import MetaBar from "../../../../components/MetaBar";
import TaskToggle from "./tasks-toggle";
import TaskTitle from "./task-title";

const Items = {
  heading: TaskTitle,
  block_meta: MetaBar,
  task_toggle: TaskToggle,
  breadcrumbs: Breadcrumbs,
};

function Overview({ data, ...props }) {
  if (!data?.length) return null;
  const items = data?.map((item, i) => {
    const Comp = Items[item.type] || null;
    if (!Comp) return null;
    return (
      <Comp
        imageSrc={item.icon}
        key={i}
        meta={item.items}
        {...item}
        {...props}
      />
    );
  });
  return items;
}

export default Overview;
