import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FullPageLoader from "../../../components/Loaders/FullPageLoader";
import NotAuthorized from "../../../components/NotAuthorized";
import Title from "../../../components/Title";
import { fetchPravasDaysForm } from "../../../services/pravasDays";
import useAuth from "../../../utils/useAuth";
import usePhase from "../../../utils/usePhase";
import QuestionContainer from "./components/QuestionContainer";

const PravasDays = () => {
  const auth = useAuth();
  const { currentPermission } = auth;
  const [fetching, setFetching] = useState(false);
  const { clusterId, pcId, visitId, reportType } = useParams();
  const currentPhase = usePhase();
  const [pravasDaysData, setPravasDaysData] = useState(null);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  useEffect(() => {
    const getPravasDaysForm = async () => {
      if (!currentPermission || !currentPhase || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      const formData = await fetchPravasDaysForm({
        currentPermission,
        clusterId,
        pcId,
        visitId,
        reportType,
        phase: currentPhase,
      });
      setFetching(false);
      if (formData) {
        setPravasDaysData(formData);
      }
    };
    getPravasDaysForm();
  }, [currentPermission, currentPhase]);

  const handleUpdate = (parentQuestionId, questionId, newValues) => {
    const newQuestionsData = pravasDaysData.questions.map((question) => {
      if (question.id !== parentQuestionId) return question;
      return {
        ...question,
        questions: question.questions.map((childQuestion) => {
          if (childQuestion.id !== questionId) return childQuestion;
          return {
            ...childQuestion,
            ...newValues,
          };
        }),
      };
    });
    setPravasDaysData({
      ...pravasDaysData,
      questions: newQuestionsData,
    });
  };

  const handleDelete = (parentQuestionId, questionId, valueId) => {
    const newQuestionsData = pravasDaysData.questions.map((question) => {
      if (question.id !== parentQuestionId) return question;
      return {
        ...question,
        questions: question.questions.map((childQuestion) => {
          if (childQuestion.id !== questionId) return childQuestion;
          return {
            ...childQuestion,
            values: childQuestion.values.filter(
              (value) => value.id !== valueId
            ),
          };
        }),
      };
    });
    setPravasDaysData({
      ...pravasDaysData,
      questions: newQuestionsData,
    });
  };

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  if (!pravasDaysData) return null;

  const questionsList = pravasDaysData?.questions
    ? pravasDaysData.questions.map((question, index) => {
        return (
          <QuestionContainer
            key={question.id}
            index={index + 1}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            question={question}
          />
        );
      })
    : null;

  return (
    <div>
      <Title
        title={pravasDaysData.name}
        imageSrc={pravasDaysData.image}
        description={pravasDaysData.description}
      />
      {questionsList}
    </div>
  );
};

export default PravasDays;
