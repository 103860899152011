import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getMediaFolder } from "../../services/media";
import useAuth from "../../utils/useAuth";
import { useParams, Link } from "react-router-dom";
import {
  ERROR_STATUS_CODE,
  REPORT_TYPE_BACKEND_MAPPER,
} from "../../utils/constants";
import { toast } from "react-toastify";
import FullPageLoader from "../Loaders/FullPageLoader";
import NotAuthorized from "../NotAuthorized";
import NoMatch from "../NoMatch";
import Title from "../Title";
import FolderForm from "./FolderForm";
import { PencilIcon } from "@heroicons/react/outline";
import BorderedText from "../BorderedText";
import Button from "../Button";
import CreateOrEditFolder from "../Modals/Media/Folder/CreateOrEditFolder";
import Lightbox from "react-image-lightbox";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../utils/internalLinks";
import PDFIcon from "images/pdf.svg";
import usePhase from "../../utils/usePhase";

const SingleFolder = ({ folder }) => {
  const { clusterId, pcId, visitId, reportType, folderId } = useParams();
  const currentPhase = usePhase();
  if (!folder && !folderId) return null;
  const [folderData, setFolderData] = useState(null);
  const [imagesForGallery, setImagesForGallery] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const auth = useAuth();
  const { currentPermission } = auth;
  const [showFolderForm, setShowFolderForm] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const toggleFolderCreateOrEdit = () => setShowFolderForm(!showFolderForm);

  const folderCreateOrEditFormSubmitSuccess = (response) => {
    setFolderData({
      ...folderData,
      folder: {
        ...folderData.folder,
        ...response,
      },
    });
    toggleFolderCreateOrEdit();
  };

  const updateImageWithPdfPreview = async (data) => {
    const filesArray = data?.form?.length ? data.form : [];
    const transformedData = filesArray.map((file) => {
      if (file.type !== "file") return file;
      const { value } = file;
      if (!value || (value && !value.length)) return file;
      return {
        ...file,
        value: value.map((currentFile) => {
          const { mime_type } = currentFile;
          if (!mime_type.includes("pdf")) return currentFile;
          return {
            ...currentFile,
            pdfPreview: PDFIcon,
          };
        }),
      };
    });
    return {
      ...data,
      form: transformedData,
    };
  };

  const updateFolderData = async (data) => {
    const transformedData = await updateImageWithPdfPreview(data);
    setFolderData(transformedData);
  };

  useEffect(() => {
    const getFolderData = async () => {
      if (!currentPermission || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      const response = await getMediaFolder({
        phase: currentPhase,
        currentPermission,
        clusterId,
        pcId,
        visitId,
        reportType: reportType ? reportType : REPORT_TYPE_BACKEND_MAPPER.MEDIA,
        folderId: folderId || folder.id,
      });
      setFetching(false);
      if (response.isAxiosError) {
        if (response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED) {
          setIsUnauthorized(true);
          return;
        }
        toast.error("Something went wrong...");
        return;
      }
      const newData = await updateImageWithPdfPreview(response);
      setFolderData(newData);
    };
    getFolderData();
  }, [currentPermission, currentPhase]);

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  if (!folderData) return null;

  if (!folderData.folder) return <NoMatch />;

  const handleGalleryClose = () => {
    setIsLightboxOpen(false);
    setActiveIndex(null);
  };

  const handleGalleryMoveNext = () => {
    const newIndex = (activeIndex + 1) % folderData.form[0].value.length;
    setActiveIndex(newIndex);
  };

  const handleGalleryMovePrev = () => {
    const newIndex =
      (activeIndex + folderData.form[0].value.length - 1) %
      folderData.form[0].value.length;
    setActiveIndex(newIndex);
  };

  const handleGallery = (index) => {
    setActiveIndex(index);
    setIsLightboxOpen(true);
  };

  const folderLink = PARLIAMENT_CONSTITUENCY_LINKS.VISIT_TYPE(
    clusterId,
    pcId,
    visitId,
    reportType ? reportType : REPORT_TYPE_BACKEND_MAPPER.MEDIA,
    currentPhase
  );

  const mainSrc =
    folderData.form[0] && folderData.form[0].value
      ? folderData.form[0].value[activeIndex]
      : null;
  const nextSrc =
    folderData.form[0] && folderData.form[0].value
      ? folderData.form[0].value[
          (activeIndex + 1) % folderData.form[0].value.length
        ]
      : null;
  const prevSrc =
    folderData.form[0] && folderData.form[0].value
      ? folderData.form[0].value[
          (activeIndex + folderData.form[0].value.length - 1) %
            folderData.form[0].value.length
        ]
      : null;

  return (
    <div>
      <Title
        title={folderData.name || ""}
        description={folderData.description || ""}
        imageSrc={folderData.icon || ""}
      />
      <div className="mt-11 mb-5 flex items-center">
        <div className="flex items-start">
          <Link to={folderLink}>
            <BorderedText text={"Folders"} />
          </Link>
          <p className="font-bold ml-2">
            {" >"} {folderData.folder.name || ""}
          </p>
        </div>
        <Button type="button" onClick={toggleFolderCreateOrEdit}>
          <PencilIcon className="ml-4 w-4 h-4" />
        </Button>
      </div>
      <FolderForm
        folderId={folderData.folder.id}
        onSubmit={updateFolderData}
        handleGallery={handleGallery}
        form={folderData.form}
      />
      <CreateOrEditFolder
        isOpen={showFolderForm}
        handleSuccess={folderCreateOrEditFormSubmitSuccess}
        folder={folderData.folder}
        onClose={toggleFolderCreateOrEdit}
      />
      {isLightboxOpen && mainSrc && (
        <Lightbox
          mainSrc={mainSrc.image_path || mainSrc.url || mainSrc.preview}
          nextSrc={nextSrc.image_path || nextSrc.url || nextSrc.preview}
          prevSrc={prevSrc.image_path || prevSrc.url || prevSrc.preview}
          onCloseRequest={handleGalleryClose}
          onMovePrevRequest={handleGalleryMovePrev}
          onMoveNextRequest={handleGalleryMoveNext}
        />
      )}
    </div>
  );
};

SingleFolder.propTypes = {
  folder: PropTypes.object,
};

export default SingleFolder;
