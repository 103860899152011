import React, { memo } from "react";
import t from "prop-types";
import { REPORT_TYPE_BACKEND_MAPPER } from "./constants";
import MetaBar from "../MetaBar";
import Title from "../Title";
import Visit from "./Visit";
import OverallFeedback from "./OverallFeedback";
import Media from "./Media";
import VidhanSabha from "./VidhanSabha";
import PageBreak from "./PageBreak";
import MeetingPDF from "./MeetingPDF";
import MeetingPDFNoVisit from "./MeetingPDF/MeetingPDFNoVisit";
import PravasDays from "./PravasDays";
import Subtitle from "./Subtitle";

const ClusterReportsPDFItems = ({ report }) => {
  if (!report.length) return null;
  const reportItems = report.map((item, index) => {
    const key = `report-${index}`;
    switch (item.type) {
      case REPORT_TYPE_BACKEND_MAPPER.META:
        return <MetaBar key={key} meta={item.items} />;
      case REPORT_TYPE_BACKEND_MAPPER.VISITS:
        return <Visit isPDF={true} key={key} data={item} />;
      case REPORT_TYPE_BACKEND_MAPPER.TITLE:
        return (
          <div
            key={key}
            className="print:mt-1 break-after-avoid break-before-avoid mt-9"
          >
            <Title title={item.title} imageSrc={item?.icon || ""} />
          </div>
        );
      case REPORT_TYPE_BACKEND_MAPPER.MEETINGS:
        if (!item?.visit) {
          return <MeetingPDFNoVisit />;
        }
        return (
          <MeetingPDF
            items={item.items}
            isPDF={true}
            visit={item?.visit}
            key={key}
            item={item}
            type={item?.type}
            meeting={item.meeting || null}
          />
        );
      case REPORT_TYPE_BACKEND_MAPPER.VIDHAN_SABHA:
        return (
          <VidhanSabha
            key={key}
            isPDF={true}
            visit={item?.visit}
            type={item?.type}
            tableData={item.items}
            items={item?.items}
          />
        );
      case REPORT_TYPE_BACKEND_MAPPER.OVERALL_FEEDBACK:
        return (
          <OverallFeedback
            key={key}
            type={item?.type}
            isPDF={true}
            data={item}
            visit={item?.visit}
            items={item?.items || []}
          />
        );
      case REPORT_TYPE_BACKEND_MAPPER.MEDIA:
        return (
          <Media
            key={key}
            type={item?.type}
            isPDF={true}
            items={item?.items || []}
          />
        );
      case REPORT_TYPE_BACKEND_MAPPER.PRAVAS_DAYS:
        return (
          <PravasDays
            type={item?.type}
            visit={item?.visit}
            key={key}
            isPDF={true}
            data={item}
          />
        );
      // case REPORT_TYPE_BACKEND_MAPPER.SUBTITLE:
      //   return <Subtitle key={key} title={item.title || ""} />;
      case REPORT_TYPE_BACKEND_MAPPER.PAGEBREAK:
        return <PageBreak key={key} />;
      default:
        return null;
    }
  });
  return reportItems;
};

ClusterReportsPDFItems.propTypes = {
  report: t.array,
};

ClusterReportsPDFItems.defaultProps = {
  report: [],
};

export default memo(ClusterReportsPDFItems);
