import React, { memo } from "react";
import t from "prop-types";

const Comment = ({ comment }) => {
  if (!comment) return null;
  return  <div className="mt-9 py-7 min-h-[128px] px-9 bg-[#F3F7FF] rounded-lg flex items-start">
    <div className="pr-2.5 w-40 border-r border-gray-400">
      <p className="poppins text-base font-semibold text-[#282622]">{comment.report_placeholder || 'Description'}</p>
    </div>
    <p className="text-base pl-5 font-medium">{comment?.value || ''}</p>
  </div>
}

Comment.propTypes = {
  comment: t.object
}

export default memo(Comment);