import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { VISTARAK_LINKS } from "../../../utils/internalLinks";
import qs from "qs";
import TextInput from "../../../components/FormInputs/TextInput";
import Button from "../../../components/Button";

const VistarakLink = ({ itemKey, value, filters, pc_id }) => {
  const linkTo = VISTARAK_LINKS.REPORT_TYPE(pc_id, itemKey);
  const params = qs.stringify(filters, { arrayFormat: "brackets" });
  return (
    <>
      <Link
        className="text-[#5384FC] underline print:hidden"
        to={linkTo + "?" + params}
      >
        {value}
      </Link>
      <p className="text-[#5384FC] underline hidden print:block">{value}</p>
    </>
  );
};

const String = ({ value }) => {
  return <p>{value}</p>;
};

const List = ({ value }) => {
  const listItems = value?.map((v, i) => {
    const { type } = v;
    const Comp = ItemsType[type] || null;
    if (!Comp) return null;
    return <Comp key={i} {...v} />;
  });
  return listItems;
};

const ItemsType = {
  string: String,
  list: List,
  link: VistarakLink,
};

const DashboardTable = ({ data, filters, handleExcel, fetchingExcel }) => {
  const [search, setSearch] = useState("");

  const handlePrint = () => window.print();

  const rowItems = useMemo(() => {
    const rowItemsWithSearch = !!search.trim()
      ? data?.items?.data?.filter((rowData, i) => {
          if (i === 0) return false;
          const filtered = rowData.filter((_) =>
            JSON.stringify(_.value)
              .toLowerCase()
              .includes(search.trim().toLowerCase())
          );
          return !!filtered.length;
        })
      : data?.items?.data;
    return rowItemsWithSearch?.map((rowData, i) => {
      const rowItem = rowData?.map((row, j) => {
        const Comp = ItemsType[row.type] || null;
        if (!Comp) return null;
        const cn = classNames(
          "border-r-2 py-4 text-base border-white",
          j === 0 && "font-bold"
        );
        return (
          <td className={cn} key={`rowItem-${j}`}>
            <Comp filters={filters} {...row} itemKey={row.key} />
          </td>
        );
      });
      const cn = classNames(
        "bg-[#F3F7FF] text-center",
        i === 0 &&
          !search &&
          "font-bold sticky print:static print:top-0 top-[135px]",
        i < data.items.data.length - 1 && "border-b border-b-[#9DAED2]"
      );
      return (
        <tr className={cn} key={`row-${i}`}>
          {rowItem}
        </tr>
      );
    });
  }, [data, search]);

  const headerItems = data?.items?.headers?.map((v, i) => {
    return (
      <th
        className="p-4 sticky whitespace-nowrap print:relative max-h-20 print:top-0 top-[80px] align-top text-center text-base mulish text-white border-r-2 border-white bg-[#4471B0]"
        key={`head-${i}`}
      >
        {v.name}
      </th>
    );
  });

  if (!data) return null;

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <div className="flex justify-between mb-6 print:hidden">
        <div>
          {data.is_searchable && (
            <TextInput
              className="w-96 p-2.5"
              placeholder="Search by Name, Number, count, etc"
              onChange={handleChange}
            />
          )}
        </div>
        <div className="flex items-center gap-2">
          <Button.Download text="PDF" onClick={handlePrint} />
          <Button.Download
            disabled={fetchingExcel}
            text="Excel"
            onClick={handleExcel}
          />
        </div>
      </div>
      <table className="w-full">
        <thead>
          <tr>{headerItems}</tr>
        </thead>
        <tbody>{rowItems}</tbody>
      </table>
    </div>
  );
};

export default DashboardTable;
