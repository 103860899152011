import React, { useEffect, useState } from "react";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import NotAuthorized from "../../components/NotAuthorized";
import PCPercentageModal from "../../components/PCPercentageModal";
import { fetchClusters } from "../../services";
import { ERROR_STATUS_CODE } from "../../utils/constants";
import useAuth from "../../utils/useAuth";
import usePhase from "../../utils/usePhase";
import ClusterItem from "./ClusterItem";

const Cluster = () => {
  const auth = useAuth();
  const phase = usePhase();
  const { currentPermission } = auth;
  const [clusters, setClusters] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const [pcPercentageDetails, setPcPercentageDetails] = useState(null);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  useEffect(() => {
    const getClusters = () => {
      if (!currentPermission || !phase || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      fetchClusters({ currentPermission, phase }).then((response) => {
        setFetching(false);
        if (
          response &&
          response.isAxiosError &&
          response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED
        ) {
          setFetching(false);
          setIsUnauthorized(true);
          return;
        }
        if (response && !response.isAxiosError) {
          setClusters(response || []);
        }
      });
    };
    getClusters();
  }, [currentPermission, phase]);

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  const handlePercentageClick = (selectedCluster = null, selectedPC = null) => {
    setSelectedCluster(selectedCluster);
    setPcPercentageDetails(selectedPC);
  };

  const handleClose = () => {
    setSelectedCluster(null);
    setPcPercentageDetails(null);
  };

  const clusterItems = clusters.map((cluster) => {
    return (
      <ClusterItem
        onPercentageClick={handlePercentageClick}
        key={cluster.id}
        cluster={cluster}
      />
    );
  });
  return (
    <>
      {clusterItems}
      {!!pcPercentageDetails && (
        <PCPercentageModal
          isOpen={!!pcPercentageDetails}
          onClose={handleClose}
          clusterId={selectedCluster}
          pcId={pcPercentageDetails}
        />
      )}
    </>
  );
};

export default Cluster;
