import React from "react";
import { RadioGroup as RadioGroupReact } from "@headlessui/react";
import classNames from "classnames";

const RadioGroup = ({ items, value, name, onChange, className, required }) => {
  const groupClass = classNames("flex items-center space-x-32", className)

  const handleChange = (value) => onChange(value);

  return (<RadioGroupReact value={value} onChange={handleChange} name={name}>
    <div className={groupClass}>
      {items.map((item) => (
        <RadioGroupReact.Option
          key={item.name}
          value={item.id}>
          {({ checked }) => {
            const className = classNames("border align-middle relative border-[#163560] inline-block w-6 h-6 bg-[#EEF3FF] rounded-full",
              checked ? "after:content-[' '] after:absolute after:w-4 after:h-4 after:bg-[#5384FC] after:rounded-lg after:top-1/2 after:left-1/2 after:-translate-y-1/2 after:-translate-x-1/2" : "")
            return (<div key={item.name}>
                <span className={className}></span>
                <RadioGroupReact.Label as="span" className="text-base font-medium ml-3.5 align-middle">
                  {item.name}
                </RadioGroupReact.Label>
              </div>)
          }
          }
        </RadioGroupReact.Option>
      ))}
    </div>
  </RadioGroupReact>)
}

export default RadioGroup;