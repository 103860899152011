import React, { useEffect, useState } from "react";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import NotAuthorized from "../../components/NotAuthorized";
import Title from "../../components/Title";
import { phasesService } from "../../services";
import { ERROR_STATUS_CODE } from "../../utils/constants";
import useAuth from "../../utils/useAuth";
import PhaseItem from "./PhaseItem";

const Phases = () => {
  const auth = useAuth();
  const { currentPermission } = auth;
  const [phases, setPhases] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  useEffect(() => {
    const getPhases = () => {
      if (!currentPermission || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      phasesService.fetchPhases({ currentPermission }).then((response) => {
        setFetching(false);
        if (
          response &&
          response.isAxiosError &&
          response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED
        ) {
          setFetching(false);
          setIsUnauthorized(true);
          return;
        }
        if (response && !response.isAxiosError) {
          setPhases(response || []);
        }
      });
    };
    getPhases();
  }, [currentPermission]);

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  const phasesItems = phases.map((phase) => {
    return <PhaseItem key={phase.id} phase={phase} />;
  });

  const title = !!phases.length
    ? `Lok Sabha - ${phases.map((phase) => phase.name).join(", ")}`
    : "Lok Sabha";
  return (
    <div>
      <Title title={title} description="Select a Phase to proceed" />
      <div className="flex mt-20 flex-wrap gap-6">{phasesItems}</div>
    </div>
  );
};

export default Phases;
