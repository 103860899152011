import React, { memo } from "react";
import PropTypes from "prop-types";
import {
    buildStyles, CircularProgressbarWithChildren
} from "react-circular-progressbar";

const CircleProgress = ({ percentage }) => {
  const percentageColor = !percentage
  ? "#ED5F61"
  : percentage <= 33
    ? "#ED5F61"
    : percentage < 70
      ? "#FF9900"
      : "#32BA7C";
  const styles = buildStyles({
    strokeLinecap: "butt",
    trailColor: "#F8F8F8",
    pathColor: percentageColor
  });

  const titleStyle = {
    color: percentageColor
  }
  return (<CircularProgressbarWithChildren styles={styles} value={percentage}>
      <p className="text-xl font-bold" style={titleStyle}>{percentage} %</p>
      <p className="text-xs">Completed</p>
  </CircularProgressbarWithChildren>)
}

CircleProgress.propTypes = {
  percentage: PropTypes.number
}

CircleProgress.defaultProps = {
  percentage: 0
}

export default memo(CircleProgress);