import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../../components/Button";
import RemoveConfirmation from "../../../../components/RemoveConfirmation/RemoveConfirmation";
import { deletePravasDaysForm } from "../../../../services/pravasDays";
import useAuth from "../../../../utils/useAuth";
import usePhase from "../../../../utils/usePhase";
import QuestionValuesForm from "./QuestionValuesForm";
import QuestionValuesTable from "./QuestionValuesTable";
import PlusCircleIcon from "images/plus.svg";
import { toast } from "react-toastify";
import { NumberToAlphabet } from "number-to-alphabet";
import classNames from "classnames";

const DELETE_MODAL_STATE = {
  open: false,
  valueId: null,
  deleting: false,
};

const FORM_STATE = {
  open: false,
  isEdit: false,
  edit: null,
};

const defaultAlphabet = new NumberToAlphabet();

const Question = ({
  question,
  onUpdate,
  isPDF,
  isReadOnly,
  index,
  parentIndex,
  onDelete,
}) => {
  const auth = useAuth();
  const { currentPermission } = auth;
  const { clusterId, pcId, visitId, reportType } = useParams();
  const currentPhase = usePhase();
  const [open, setOpen] = useState(isPDF || isReadOnly || false);
  const [formState, setFormState] = useState(FORM_STATE);
  const [deleteModal, setDeleteModal] = useState(DELETE_MODAL_STATE);
  const { values, name, parent_id } = question;
  const isPDForReadOnly = isPDF || isReadOnly;

  const toggleOpen = () => {
    if (!question.values || (question.values && !question.values.length)) {
      setFormState({
        ...FORM_STATE,
        open: true,
      });
    }
    setOpen(!open);
  };

  const openForm = () =>
    setFormState({
      open: true,
      isEdit: false,
      edit: null,
    });

  const onEdit = (value) => {
    setFormState({
      open: true,
      isEdit: true,
      edit: value,
    });
  };

  const handleCloseForm = () => setFormState(FORM_STATE);

  const handleDelete = async () => {
    if (deleteModal.deleting) return;
    setDeleteModal({
      ...deleteModal,
      deleting: true,
    });
    const response = await deletePravasDaysForm({
      clusterId,
      pcId,
      currentPermission,
      visitId,
      reportType,
      phase: currentPhase,
      questionId: question.id,
      valueId: deleteModal.valueId,
    });
    if (response) {
      onDelete?.(parent_id, question.id, deleteModal.valueId);
      toast.success("Meeting deleted.");
      toggleDeleteModal();
      return;
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleSaveForm = (newValues) => {
    onUpdate?.(parent_id, question.id, newValues);
    handleCloseForm();
  };

  const toggleDeleteModal = (valueId) => {
    if (valueId) {
      setDeleteModal({
        open: true,
        valueId,
      });
      return;
    }
    setDeleteModal(DELETE_MODAL_STATE);
  };

  const className = classNames(
    "flex justify-between",
    isPDForReadOnly ? "" : "cursor-pointer"
  );

  return (
    <div className="print:break-inside-avoid mt-6 py-5 border-t border-gray-200">
      <div className={className} onClick={toggleOpen}>
        <p className="poppins text-base font-medium">
          {parentIndex}
          {defaultAlphabet.numberToString(index).toLocaleUpperCase()}. {name}
        </p>
        <div className="shrink-0 ml-2 flex items-center">
          {!isPDForReadOnly ? (
            !!values.length ? (
              <p className="py-1.5 poppins text-medium rounded-3xl text-white px-5 bg-[#61BE7A]">
                {values.length} Meeting{values.length > 1 ? "s" : ""}
              </p>
            ) : (
              <p className="py-1.5 poppins text-medium rounded-3xl text-white px-5 bg-[#F06060]">
                Pending
              </p>
            )
          ) : null}
          {!isPDForReadOnly &&
            (open ? (
              <ChevronUpIcon className="ml-6 w-6 h-auto" />
            ) : (
              <ChevronDownIcon className="ml-6 w-6 h-auto" />
            ))}
        </div>
      </div>
      {open ? (
        <div>
          {(formState.open || !values.length) && !isPDForReadOnly && (
            <QuestionValuesForm
              onCancel={handleCloseForm}
              onSave={handleSaveForm}
              isEdit={formState.isEdit || false}
              editValue={formState.edit}
              question={question}
            />
          )}
          {!isPDForReadOnly && !formState.open && !!values.length && (
            <Button.Rounded
              className="to-white inline-flex items-center mt-4 border poppins border text-[#262626] border-[#262626] text-lg from-white shadow-white"
              onClick={openForm}
            >
              <img src={PlusCircleIcon} className="mr-2" alt="add response" />
              Add Response
            </Button.Rounded>
          )}
          <QuestionValuesTable
            isPDF={isPDForReadOnly}
            onEdit={onEdit}
            onDelete={toggleDeleteModal}
            question={question}
          />
        </div>
      ) : null}
      <RemoveConfirmation
        onCancel={toggleDeleteModal}
        onDelete={handleDelete}
        isOpen={deleteModal.open}
        description={"Are you sure you want to remove this remark?"}
        title="Remove Feedback/remark?"
      />
    </div>
  );
};

export default Question;
