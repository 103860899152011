import React from "react";
import { useParams } from "react-router-dom";
import { dateFormat } from "../../utils/helper";
import { CLUSTER_LINKS } from "../../utils/internalLinks";
import usePhase from "../../utils/usePhase";
import BorderedText from "../BorderedText";
import EditBtn from "./EditBtn";

const Visit = ({ data, isPDF }) => {
  const { clusterId, pcId } = useParams();
  const phase = usePhase();
  const { title, items } = data;
  const dateItems =
    items && items.length
      ? items.map((date, index) => {
          const visitCountText = `Visit ${index + 1}`;
          const { start_date, end_date } = date;
          const startDate = start_date
            ? dateFormat(new Date(start_date), "dd-MM-yyyy")
            : "";
          const endDate = end_date
            ? dateFormat(new Date(end_date), "dd-MM-yyyy")
            : "";
          return (
            <div
              key={visitCountText}
              className="pl-4 [&:nth-child(4n)]:border-0 border-r border-gray-400"
            >
              <p className="text-xs text-[#FF9955] font-semibold">
                {visitCountText}
              </p>
              <p className="mt-1.6 poppins font-semibold text-lg">
                {startDate}
              </p>
              <p className="poppins mt-1.6 text-xs text-[#828282] font-semibold">
                TO
              </p>
              <p className="mt-1.6 poppins font-semibold text-lg">{endDate}</p>
            </div>
          );
        })
      : null;

  const editLink = CLUSTER_LINKS.SINGLE_LOK_SABHA(clusterId, pcId, phase);

  return (
    <div className="bg-white rounded-lg mt-7 p-4 box-border">
      {title && <BorderedText text={title} />}
      {dateItems && (
        <div className="mt-6 grid gap-2 grid-cols-4">{dateItems}</div>
      )}
      {!isPDF && !!items.length && (
        <div className="mt-9 flex items-center justify-end">
          <EditBtn link={editLink} />
        </div>
      )}
    </div>
  );
};

export default Visit;
