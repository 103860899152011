import React from "react";

const MeetingPDFNoVisit = () => {
  return (
    <div className="box-border shadow print:mt-2 print:p-0 mt-8 bg-white rounded-lg py-4 px-6">
      <p>Meeting PDF not generated as there's no visit</p>
    </div>
  );
};

export default MeetingPDFNoVisit;
