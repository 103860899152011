import React, { useState, useRef, useEffect } from "react";
import t from "prop-types";
import Button from "../Button";
import BlackPencilIcon from "images/pencil_black.svg";
import classNames from "classnames";
import ShowMoreText from "../ShowMoreText";
import DustbinIcon from "images/dustbin.svg";
import html2canvas from "html2canvas";
import { v4 as uuid } from "uuid";
import MeetingPDFLoader from "../ClusterReportItems/MeetingPDF/MeetingPDFLoader";

const generateImage = async (element, tableId) => {
  const canvas = await html2canvas(element, {
    onclone: (clonedDoc) => {
      clonedDoc.getElementById(tableId).style.display = "table";
    },
  });
  const image = await canvas.toDataURL("image/png", 1.0);
  return image;
};

const TextCell = ({ row, index, isPDF }) => {
  const { name, show_more, rowSpan, is_subdued } = row;
  const style = {
    ...(rowSpan &&
      index === 0 && {
        position: "sticky",
        left: 0,
        zIndex: 10,
      }),
    ...(rowSpan &&
      index === 1 && {
        position: "sticky",
        left: `120px`,
        zIndex: 10,
      }),
  };
  const className = classNames(
    name?.length < 4 ? "w-16" : "w-72",
    "align-top border-4 border-white",
    is_subdued ? "bg-[#EFF3FB]" : "bg-[#E3ECFF]",
    isPDF ? "px-2" : "p-2"
  );
  const textClassName = classNames(
    name?.length < 4 ? "w-16 text-center" : "w-72",
    "whitespace-pre-line align-top",
    isPDF ? "inline-block mt mb-3" : ""
  );
  return (
    <td className={className} rowSpan={rowSpan || 1}>
      <p className={textClassName}>{name}</p>
    </td>
  );
};

const MeetingStatusCell = ({ row, isPDF }) => {
  const { meeting_status, rowSpan, is_subdued } = row;
  const className = classNames(
    is_subdued ? "bg-[#EFF3FB]" : "bg-[#E3ECFF]",
    "align-top w-36 text-center border-4 border-white",
    isPDF ? "px-2" : "p-2"
  );
  const textClassName = classNames(
    "w-36 box-border",
    isPDF && "inline-block mb-2 mt-"
  );
  return (
    <td rowSpan={rowSpan || 1} className={className}>
      <div className={textClassName}>
        {meeting_status && <p>{meeting_status.name}</p>}
      </div>
    </td>
  );
};

const RemarkCell = ({ row, index, isPDF }) => {
  const { remark, show_more, is_subdued, rowSpan } = row;
  const className = classNames(
    index === 0 ? "w-24" : "w-72 text-justify",
    "align-top border-4 border-white",
    is_subdued ? "bg-[#EFF3FB]" : "bg-[#E3ECFF]",
    isPDF ? "px-2" : "p-2"
  );
  const textClassName = classNames(
    index === 0 ? "w-24" : "w-72 text-justify",
    "whitespace-pre-line text-sm",
    isPDF ? "box-border inline-block mt mb-2" : ""
  );
  return (
    <td className={className} rowSpan={rowSpan || 1}>
      {show_more ? (
        <ShowMoreText textClassName={textClassName}>
          <p className={textClassName}>{remark}</p>
        </ShowMoreText>
      ) : (
        <p className={textClassName}>{remark}</p>
      )}
    </td>
  );
};

const AgendaStatusCell = ({ row, isPDF }) => {
  const { is_subdued } = row;
  const className = classNames(
    is_subdued ? "bg-[#EFF3FB]" : "bg-[#E3ECFF]",
    "align-top w-36 text-center border-4 border-white",
    isPDF ? "px-2" : "p-2"
  );
  const { agenda_status, rowSpan } = row;
  const buttonStyle = {
    ...(agenda_status?.color && {
      borderColor: agenda_status.color,
      color: agenda_status.color,
      backgroundColor: `${agenda_status.color}26`,
    }),
  };
  const buttonClassName = classNames(
    "shrink-0 inline-flex items-center px-2  border text-sm font-semibold rounded focus:outline-none",
    isPDF ? "h-8" : "py-1"
  );
  const buttonSpan = classNames(isPDF ? "mb-3 inline-block" : "");
  const buttonParentClass = classNames(
    "w-36 box-border",
    isPDF ? "mt-1.5 inline-block" : ""
  );
  return (
    <td rowSpan={rowSpan || 1} className={className}>
      <div className={buttonParentClass}>
        {agenda_status && (
          <Button style={buttonStyle} className={buttonClassName}>
            <span className={buttonSpan}>{agenda_status.name}</span>
          </Button>
        )}
      </div>
    </td>
  );
};

const ActionCell = ({ row, onEdit, onDelete }) => {
  const { rowSpan, meeting_id, can_delete, can_edit, is_subdued } = row;
  const className = classNames(
    is_subdued ? "bg-[#EFF3FB]" : "bg-[#E3ECFF]",
    "p-2 border-4 border-white"
  );
  const handleDelete = () => onDelete(meeting_id);
  const handleEdit = () => onEdit(meeting_id);
  return (
    <td className={className} rowSpan={rowSpan || 1}>
      <div className="flex items-center w-36 flex-col gap-4">
        {can_delete && (
          <Button onClick={handleDelete}>
            <img className="w-8	h-auto" src={DustbinIcon} alt="delete" />
          </Button>
        )}
        {can_edit && (
          <Button onClick={handleEdit}>
            <img className="w-8	h-auto" src={BlackPencilIcon} alt="edit" />
          </Button>
        )}
      </div>
    </td>
  );
};

const InformationMeetingTable = ({ meetingData, onDelete, onEdit, isPDF }) => {
  const tableRef = useRef(null);
  const [image, setImage] = useState(null);
  const [generating, setGenerating] = useState(!!isPDF);
  const [dimensions, setDimensions] = useState(null);
  const tableId = uuid();

  const generatePDF = async (element, tableId) => {
    const tableImage = await generateImage(element, tableId);
    const newImage = new Image();
    newImage.src = tableImage;
    newImage.onload = () => {
      setGenerating(false);
    };
    setImage(tableImage);
  };

  useEffect(() => {
    const handlePDF = async () => {
      if (!tableRef.current || !isPDF) return;
      await generatePDF(tableRef.current, tableId);
    };
    handlePDF();
  }, [tableRef.current, isPDF]);
  if (!meetingData) return null;
  const { headers, rows } = meetingData;
  const headerRows = headers.map((headerRow, i) => {
    const headerCells = headerRow.map((header, j) => {
      const { name, scope, colspan, is_subdued } = header;
      const style = {
        position: "sticky",
        top: `${75 + i * 48}px`,
        zIndex: 10,
        ...(j === 0 && {
          left: `0`,
          zIndex: 11,
        }),
        ...(j === 1 && {
          left: `120px`,
          zIndex: 11,
        }),
      };
      const className = classNames(
        "p-3",
        i === 0 && j === 0 && "rounded-tl-lg",
        i === 0 && j === headerRow.length - 1 && "rounded-tr-lg",
        "text-white text-base font-bold",
        "border-r-4 border-white last:border-r-0",
        typeof colspan === "number" ? "border-b-4" : "",
        is_subdued ? "bg-[#95A9CD]" : "bg-[#4471B0]"
      );
      return (
        <th className={className} key={j} scope={scope} colSpan={colspan}>
          {name}
        </th>
      );
    });
    return <tr key={i}>{headerCells}</tr>;
  });

  const rowItems = rows.map((rowData, i) => {
    const rowItem = rowData.map((row, j) => {
      const { type } = row;
      const Cell =
        type === "string" ? (
          TextCell
        ) : type === "agenda_status" ? (
          AgendaStatusCell
        ) : type === "action" ? (
          ActionCell
        ) : type === "meeting_status" ? (
          MeetingStatusCell
        ) : type === "remark" ? (
          RemarkCell
        ) : (
          <td></td>
        );
      return (
        <Cell
          index={i === 0 ? j : 1}
          key={j}
          {...(type === "action" && {
            onEdit,
            onDelete,
          })}
          isPDF={isPDF || false}
          row={row}
        />
      );
    });
    return <tr key={i}>{rowItem}</tr>;
  });

  const className = classNames("relative", isPDF ? "  " : "overflow-x-auto");

  const tableClassName = classNames(
    "w-full relative whitespace-nowrap",
    isPDF ? "hidden" : ""
  );

  const handleImageLoad = (e) => {
    const { target } = e;
    setDimensions({
      width: target.clientWidth,
      height: target.clientHeight,
    });
  };

  const imageStyle = dimensions
    ? {
        width: dimensions.width,
        height: dimensions.height,
        background: `url(${image})`,
        backgroundSize: "contain",
        margin: `0 auto`,
      }
    : {};

  const imageClassName = classNames(dimensions && "hidden");

  return (
    <>
      {isPDF && image ? (
        <div style={imageStyle}>
          <img
            className={imageClassName}
            src={image}
            onLoad={handleImageLoad}
            alt="image"
          />
        </div>
      ) : (
        <div id={`container-${tableId}`} className={className}>
          <table ref={tableRef} id={tableId} className={tableClassName}>
            <thead>{headerRows}</thead>
            <tbody>{rowItems}</tbody>
          </table>
        </div>
      )}
      {generating && <MeetingPDFLoader isOpen={generating} />}
    </>
  );
};

InformationMeetingTable.propTypes = {
  isPDF: t.bool,
  isReadOnly: t.bool,
  meetingData: t.object,
  handleDelete: t.func,
  handleEdit: t.func,
};

export default InformationMeetingTable;
