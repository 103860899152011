import React, { useState } from "react";
import TaskMedia from "./task-media";
import TaskParticipants from "./task-participants";
import classNames from "classnames";

const LINK_TYPES = {
  MEDIA: "media",
  PARTICIPANTS: "participants",
};

function TaskLink(props) {
  const { link_type, value, count, meeting_id, media_list } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const textCn = classNames(typeof count !== "undefined" && "print:hidden");
  const cn = classNames(link_type === LINK_TYPES.MEDIA ? "w-32" : "");
  const content =
    link_type === LINK_TYPES.MEDIA && media_list?.length ? (
      <div className="flex flex-wrap gap-2 items-center justify-center">
        {media_list.map((item) => (
          <img
            className="w-12 h-12 overflow-hidden shrink-0"
            id={item.id}
            src={item.url}
          />
        ))}
      </div>
    ) : (
      <span className={textCn}>
        {link_type === LINK_TYPES.MEDIA ? count : value}
      </span>
    );
  return (
    <div className={cn}>
      <p
        className="cursor-pointer underline text-blue-500"
        onClick={toggleOpen}
      >
        {content}
      </p>
      {link_type === LINK_TYPES.MEDIA && (
        <TaskMedia
          onClose={toggleOpen}
          isOpen={isOpen}
          meetingId={meeting_id}
        />
      )}
      {link_type === LINK_TYPES.PARTICIPANTS && (
        <TaskParticipants
          onClose={toggleOpen}
          isOpen={isOpen}
          meetingId={meeting_id}
        />
      )}
    </div>
  );
}

export default TaskLink;
