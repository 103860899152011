import { ClientApi } from "./client";
import { EXTERNAL_LINKS } from "./links";

export const fetchVidhanSabhaSectionFormSections = ({ currentPermission, clusterId, pcId, visitId, reportType, phase }) => {
  return ClientApi(currentPermission.id, phase).get(EXTERNAL_LINKS.CLUSTERS.FORM({clusterId, pcId, visitId, reportType}))
    .then(response => response.data.data)
    .catch(error => error);
}

export const fetchVidhanSabhaSectionFormSection = ({ currentPermission, clusterId, pcId, visitId, reportType, sectionId, phase, params }) => {
  return ClientApi(currentPermission.id, phase).get(EXTERNAL_LINKS.CLUSTERS.VIDHAN_SABHA.SECTION({clusterId, pcId, visitId, reportType, sectionId}), {params})
    .then(response => response.data.data)
    .catch(error => error);
}
  
export const updateVidhanSabhaSectionFormSection = ({ currentPermission, clusterId, pcId, visitId, reportType, sectionId, phase, data }) => {
  return ClientApi(currentPermission.id, phase).post(EXTERNAL_LINKS.CLUSTERS.VIDHAN_SABHA.SECTION({clusterId, pcId, visitId, reportType, sectionId}), data)
    .then(response => response.data.data)
    .catch(error => error);
}