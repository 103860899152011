import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BorderedText from "../../components/BorderedText";
import Button from "../../components/Button";
import Success from "../../components/Modals/Success/Success";
import Title from "../../components/Title";
import {
  deleteMediaFolder,
  fetchMediaForm,
  updateMediaForm,
} from "../../services/media";
import {
  MEDIA_LINK,
  PARLIAMENT_CONSTITUENCY_LINKS,
} from "../../utils/internalLinks";
import useAuth from "../../utils/useAuth";
import NotAuthorized from "../../components/NotAuthorized";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import {
  ERROR_STATUS_CODE,
  REPORT_TYPE_BACKEND_MAPPER,
} from "../../utils/constants";
import MediaForm from "../../components/Media/MediaForm";
import MediaFolders from "../../components/Media/MediaFolders";
import CreateFolderIcon from "images/create-folder.svg";
import FolderIcon from "images/folder.svg";
import CreateOrEditFolder from "../../components/Modals/Media/Folder/CreateOrEditFolder";
import RemoveConfirmation from "../../components/RemoveConfirmation/RemoveConfirmation";
import usePhase from "../../utils/usePhase";

const Media = () => {
  const [submitting, setSubmitting] = useState(false);
  const auth = useAuth();
  const { currentPermission } = auth;
  const currentPhase = usePhase();
  const navigate = useNavigate();
  const { clusterId, pcId, visitId, reportType } = useParams();
  const [mediaData, setMediaData] = useState(null);
  const [successFormShow, setSuccessFormShow] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [showFolderForm, setShowFolderForm] = useState(false);
  const [folderEditData, setFolderEditData] = useState(null);
  const [deleteFolder, setDeleteFolder] = useState(null);

  useEffect(() => {
    const getMediaForm = async () => {
      if (!currentPermission || !currentPhase || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      const response = await fetchMediaForm({
        phase: currentPhase,
        currentPermission,
        clusterId,
        pcId,
        visitId,
        reportType,
      });
      setFetching(false);
      if (response.isAxiosError) {
        if (response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED) {
          setIsUnauthorized(true);
          return;
        }
        toast.error("Something went wrong!");
        return;
      }
      if (response && !response.isAxiosError) {
        setMediaData(response);
      }
    };
    getMediaForm();
  }, [currentPermission, currentPhase]);

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  if (!mediaData) return null;

  const handleSuccessFormClose = () => {
    setSuccessFormShow(false);
    navigate(
      PARLIAMENT_CONSTITUENCY_LINKS.VISIT(
        clusterId,
        pcId,
        visitId,
        currentPhase
      )
    );
  };

  const updateForm = (key, value) => {
    setMediaData({
      ...mediaData,
      [key]: value,
    });
  };

  const transformFormDataForBackend = () => {
    return mediaData.form.map((formItem) => {
      delete formItem.form_items;
      return {
        ...formItem,
        ...(formItem.values &&
          formItem.values.length && {
            values: formItem.values.map((value) => {
              delete value["customId"];
              return value;
            }),
          }),
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    const data = {
      form: transformFormDataForBackend(),
    };
    updateMediaForm({
      phase: currentPhase,
      currentPermission,
      clusterId,
      pcId,
      visitId,
      reportType,
      data,
    })
      .then((response) => {
        if (response.isAxiosError) {
          return;
        }
        toast.success("Form submitted successfully");
        setSuccessFormShow(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const toggleFolderCreateOrEdit = (editData = null) => {
    setShowFolderForm(!showFolderForm);
    setFolderEditData(editData);
  };

  const folderCreateOrEditFormSubmitSuccess = (response) => {
    toggleFolderCreateOrEdit();
    if (folderEditData) {
      // updating folder details
      setMediaData({
        ...mediaData,
        folders: mediaData.folders.map((folder) => {
          if (folder.id !== response.id) return folder;
          return {
            ...folder,
            ...response,
          };
        }),
      });
      return;
    }
    // opening new folder details page
    const folderLink = MEDIA_LINK.SINGLE_FOLDER({
      phase: currentPhase,
      folderId: response.id,
      reportType: reportType || REPORT_TYPE_BACKEND_MAPPER.MEDIA,
      clusterId,
      pcId,
      visitId,
    });
    navigate(folderLink);
  };

  const openDeleteFolderConfirmation = (folder = null) =>
    setDeleteFolder(folder);

  const closeFolderToBeRemoved = () => setDeleteFolder(null);

  const handleFolderDelete = async () => {
    if (!deleteFolder || deleting) return;
    setDeleting(true);
    const data = {
      folderId: deleteFolder.id,
      reportType: reportType || REPORT_TYPE_BACKEND_MAPPER.MEDIA,
      phase: currentPhase,
      currentPermission,
      clusterId,
      pcId,
      visitId,
    };
    const response = await deleteMediaFolder(data);
    setDeleting(false);
    if (response.isAxiosError) {
      toast.error("Something went wrong");
      return;
    }
    const newMediaDataFolders = mediaData.folders.filter(
      (folder) => folder.id !== deleteFolder.id
    );
    setMediaData({
      ...mediaData,
      folders: newMediaDataFolders,
    });
    closeFolderToBeRemoved();
  };

  return (
    <div>
      <Title title={mediaData.name} description={mediaData.description} />
      <div className="mt-11 mb-5 flex items-center">
        <BorderedText text="Folders" />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid md:grid-cols-2 mt-6 lg:grid-cols-3 gap-5 mb-28">
          <MediaFolders
            folders={mediaData.folders}
            handleDelete={openDeleteFolderConfirmation}
            handleEdit={toggleFolderCreateOrEdit}
          />
          <div
            className="rounded-lg p-4 box-boder border border-dashed cursor-pointer border-gray-300"
            onClick={() => toggleFolderCreateOrEdit(null)}
          >
            <img
              className="my-12 mx-auto"
              src={FolderIcon}
              alt="create folder"
            />
            <img
              src={CreateFolderIcon}
              className="mx-auto"
              alt="create folder"
            />
          </div>
        </div>
        <MediaForm handleUpdate={updateForm} formData={mediaData.form || []} />
        <div className="mt-11">
          <div className="mt-32 text-center">
            <Button.Rounded type="submit" disabled={submitting}>
              {submitting ? "Saving..." : "Save & Review"}
            </Button.Rounded>
          </div>
        </div>
      </form>
      <Success
        isOpen={successFormShow}
        title="Form submitted successfully"
        onClose={handleSuccessFormClose}
        showButton={true}
        buttonText="Close"
      />
      <CreateOrEditFolder
        isOpen={showFolderForm}
        handleSuccess={folderCreateOrEditFormSubmitSuccess}
        folder={folderEditData}
        onClose={toggleFolderCreateOrEdit}
      />
      <RemoveConfirmation
        onCancel={closeFolderToBeRemoved}
        onDelete={handleFolderDelete}
        title="Remove Folder"
        deleteBtnText={deleting ? "Deleting..." : "Delete"}
        deleting={deleting}
        description="Are you sure you want to remove the folder?"
        isOpen={!!deleteFolder}
      />
    </div>
  );
};

export default Media;
