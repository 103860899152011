import React from "react";
import { DownloadIcon } from "@heroicons/react/outline";
import classNames from "classnames";

const Download = ({ as, className, text, disabled, ...props }) => {
  const Component = as || "button";
  const cn = classNames(
    "h-11 rounded-xl appearance-none flex",
    className || "",
    disabled && "opacity-50"
  );
  return (
    <Component disabled={disabled} className={cn} {...props}>
      <span className="bg-[#4e926a] rounded-tl-xl	rounded-bl-xl h-full flex items-center justify-center w-10">
        <DownloadIcon className="w-4 h-4 text-white" />
      </span>
      <span className="h-full flex text-white font-bold text-base rounded-tr-xl px-5	rounded-br-xl items-center justify-center bg-[#32BA7C]">
        {text || "Download"}
      </span>
    </Component>
  );
};

export default Download;
