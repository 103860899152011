import classNames from 'classnames';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isWebUri } from 'valid-url';


const MediaForm = (props) => {
    const { media_form } = props;
    const { title, data, headers } = media_form;

    const headerItems = headers
        && headers.length 
        ? headers.map((header, index) => {
        const headerKey = `of-header-${uuidv4()}`;
        const thClass = classNames("font-medium text-xl bg-[#E3ECFF] box-border text-[#828282] py-2.5 px-3 poppins text-center",
            index === 0 ? 'w-56' : '');
        return <th key={headerKey}
            className={thClass}>{header.name}</th>
   })
   : null;
   
   const rows = data 
    && data.length
    ? data.map((row) => {
      const rowKey = `of-tr-${uuidv4()}`;
      const rowItems = row
      && row.length 
        ? row.map((rowData) => {
            const { value } = rowData;
            const tdKey = `of-tr-td-${uuidv4()}`;
            const isValidUrl = isWebUri(value);
            return <td key={tdKey} 
              className="font-medium text-xl last:mulish bg-[#EDF3FD] text-center box-border py-2.5 px-3 break-words relative">
                {
                    isValidUrl
                    ? <a href={value} target="_blank" className="no-underline" rel="noopener noreferrer">{value}</a>
                    : <span>{value}</span>
                }
                
            </td>
        })
        : null;
      if (!rowItems) return rowItems;
      return (<tr key={rowKey} className="divide-x-2 last:border-0 border-b first:text-left border-white divide-white">{rowItems}</tr>)
    })
    : null;

    return <div className="mt-5 print:break-inside-avoid">
        <p className="mb-5 poppins text-xl font-medium">{title}</p>
        <table className="break-inside-avoid table-fixed w-full relative">
            {
                headerItems
                && <thead>
                    <tr className="divide-x-2 first:text-left border-b border-white divide-white">{headerItems}</tr>
                </thead>
            }
            { rows && <tbody>{rows}</tbody> }
        </table>
    </div>
}

export default MediaForm;