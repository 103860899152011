import React from "react";
import PencilIcon from "images/pencil.svg";
import ShowMoreText from "../../../../components/ShowMoreText";
import classNames from "classnames";
import { Link, useParams } from "react-router-dom";
import { OVERALL_FEEDBACK_LINK } from "../../../../utils/internalLinks";
import usePhase from "../../../../utils/usePhase";

const DefaultCell = ({ isFirst, row, index }) => {
  const { name } = row;
  const className = classNames(
    "border-4 px-4 border-white bg-[#E3ECFF]",
    isFirst ? "text-right" : ""
  );
  return (
    <td className={className}>
      <div className="font-medium">{name}</div>
    </td>
  );
};

const AnswerCell = ({ row, isReadOnly, isPDF }) => {
  const { name, comment, answer_type, is_subdued } = row;
  const className = classNames(
    "align-top border-4 border-white",
    is_subdued ? "bg-[#EFF3FB]" : "bg-[#E3ECFF]"
  );
  const answerClassName =
    answer_type === "both"
      ? "py-5 h-16 box-border text-center font-medium border-b-4 border-white"
      : "py-5 h-16 box-border text-center font-medium";
  return (
    <td className={className}>
      <div>
        {(answer_type === "both" || answer_type === "radio") && (
          <div className={answerClassName}>
            <p>{name || ""}</p>
          </div>
        )}
        {(answer_type === "both" || answer_type === "comment") && (
          <div className="p-5 box-border">
            <p className="font-semibold mb-3">Observation & Feedback-</p>
            {isPDF || isReadOnly ? (
              <p>{comment}</p>
            ) : (
              <ShowMoreText
                textClassName="text-sm text-justify font-medium"
                linkClassName="text-sm font-medium"
              >
                <p className="text-sm text-justify font-medium">{comment}</p>
              </ShowMoreText>
            )}
          </div>
        )}
      </div>
    </td>
  );
};

const EditCell = ({ row }) => {
  const { question_id } = row;
  const { clusterId, pcId, visitId, reportType } = useParams();
  const phase = usePhase();
  const link = `${OVERALL_FEEDBACK_LINK.QUESTIONS({
    clusterId,
    pcId,
    visitId,
    reportType,
    phase,
  })}?qId=${question_id}`;
  return (
    <td className="border-4 border-white bg-[#E3ECFF]">
      <Link to={link} className="flex items-center justify-center">
        <img src={PencilIcon} />
      </Link>
    </td>
  );
};

const OverallFeedbackTable = ({ overallFeedbackData, isPDF, isReadOnly }) => {
  if (!overallFeedbackData) return null;
  const { headers, row: data } = overallFeedbackData;

  const headerItems = headers.map((header, i) => {
    const className = classNames(
      "p-4 last:rounded-tr-lg first:rounded-tl-lg border-r-4 border-white last:border-r-0",
      header.is_subdued ? "bg-[#95A9CD]" : "bg-[#4471B0]",
      i === 0
        ? "w-[4%]"
        : i === 1
        ? "w-2/12"
        : i === headers.length - 1 && header.name.includes("action")
        ? "w-2/12"
        : "w-3/12"
    );
    return (
      <th className={className} key={i}>
        <p>{header.name}</p>
      </th>
    );
  });

  const tableBody = data.map((rowData, i) => {
    const rowItems = rowData.map((row, i) => {
      const { row_type, edit } = row;
      const Cell =
        row_type === "answer" ? AnswerCell : edit ? EditCell : DefaultCell;
      return (
        <Cell
          isPDF={isPDF}
          isReadOnly={isReadOnly}
          isFirst={i === 0}
          isLast={i === headers.length - 1}
          row={row}
          key={i}
        />
      );
    });
    return <tr key={i}>{rowItems}</tr>;
  });

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="text-base font-medium text-white">{headerItems}</tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </table>
    </div>
  );
};

export default OverallFeedbackTable;
