import React from 'react';
import t from "prop-types";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      marginTop: 10
    },
  },
};

const MultipleSelectCheckmarks = ({ items, 
  handleChange, 
  placeholder,
  value,
  handleItem }) => {

  const onChange = (e) => {
    const {
      target: { value },
    } = e;
    handleChange(value);
  };

  const menuItems = items.map((item) => {
    const { label, id } = handleItem(item);
    return <MenuItem sx={{p: 0, fontSize: 14}} key={id} value={id}>
      <Checkbox checked={value.indexOf(id) > -1} />
      <ListItemText className='!p-0 !text-sm' primary={label} />
    </MenuItem>
  });

  const handleSelected = (selected) => {
    if (!selected || (selected && !selected.length)) return '';
    const selectedItems = items
      .filter((item) => selected.includes(item.id))
      .map((item) => {
        const { label } = handleItem(item);
        return label;
      });
    return selectedItems.join(', ')
  }

  return (
    <div className="bg-white">
      <FormControl size="small" sx={{ m: 0, width: 200 }}>
        <InputLabel id="demo-multiple-checkbox-label">{placeholder}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={value}
          onChange={onChange}
          input={<OutlinedInput label={placeholder} />}
          renderValue={handleSelected}
          MenuProps={MenuProps}
        >
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
}

MultipleSelectCheckmarks.propTypes = {
  items: t.array,
  value: t.array,
  handleItem: t.func,
  handleChange: t.func,
  placeholder: t.string,
}

MultipleSelectCheckmarks.defaultProps = {
  items: [],
  value: '',
  handleChange: () => {},
  handleItem: () => {},
  placeholder: 'Select'
}

export default MultipleSelectCheckmarks;