import { Field, Form, Formik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import Button from "../../../../components/Button";
import DateInput from "../../../../components/FormInputs/DateInput";
import {
  updatePravasDaysForm,
  addPravasDaysForm,
} from "../../../../services/pravasDays";
import useAuth from "../../../../utils/useAuth";
import usePhase from "../../../../utils/usePhase";
import { dateFormat } from "../../../../utils/helper";
import { toast } from "react-toastify";
import * as Yup from "yup";

const FormSchema = Yup.object().shape({
  start_date: Yup.date().required("Meeting date is required"),
  value: Yup.string().required("Required"),
});

const QuestionValuesForm = ({
  question,
  isEdit,
  editValue,
  onSave,
  onCancel,
}) => {
  const auth = useAuth();
  const { clusterId, pcId, visitId, reportType } = useParams();
  const currentPhase = usePhase();
  const { currentPermission } = auth;

  const handleSubmit = async (values, { setSubmitting }) => {
    const func = isEdit ? updatePravasDaysForm : addPravasDaysForm;
    const response = await func({
      currentPermission,
      clusterId,
      pcId,
      visitId,
      phase: currentPhase,
      questionId: question.id,
      reportType,
      valueId: isEdit ? editValue.id : null,
      data: values,
    });
    setSubmitting(false);
    if (response) {
      onSave(response);
      toast.success(
        isEdit
          ? "Meeting updated successfully"
          : "Meeting created successfully."
      );
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
      enableReinitialize={true}
      initialValues={{
        start_date: editValue
          ? dateFormat(new Date(editValue.start_date), "yyyy-MM-dd")
          : "",
        value: editValue ? editValue.value : "",
      }}
    >
      {({ isSubmitting, errors, touched, validateField }) => {
        return (
          <Form className="mt-6">
            <div className="inline-flex items-center">
              <label className="mr-2" htmlFor="start_date">
                Meeting Date
              </label>
              <DateInput required name="start_date" />
            </div>
            <Field
              component="textarea"
              maxLength={2000}
              placeholder="Feedback/remarks"
              className="w-full resize-none rounded-lg mt-8 h-28"
              required={true}
              name="value"
            />
            {errors.value && touched.value && (
              <p className="text-right text-red-500 text-xs">{errors.value}</p>
            )}
            <div className="flex mt-8 items-center justify-end">
              {(isEdit || !!question?.values.length) && (
                <Button className="mr-4" type="button" onClick={onCancel}>
                  Cancel
                </Button>
              )}
              <Button.Rounded disabled={isSubmitting} type="submit">
                Save
              </Button.Rounded>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default QuestionValuesForm;
