import classNames from "classnames";
import React, { useState } from "react";
import SimpleSelect from "../SimpleSelect";

const OverlappingSelect = ({ placeholder, value, ...props }) => {
  const labelClassname = classNames("absolute ease-in duration-100 -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900",
    value ? 'opacity-100' : 'opacity-0');
  return (<div className="relative border border-black rounded-md shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
  <label
    htmlFor="name"
    className={labelClassname}
  >
    { placeholder }
  </label>
  <SimpleSelect className="block p-0 w-full focus:ring-0 border-0" 
    value={value} 
    {...props}>
    {props.children}
  </SimpleSelect>
</div>)
}

export default OverlappingSelect;