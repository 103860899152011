import React from "react";
import Cards from "./Cards";

const CardsGroup = ({ items }) => {
  if (!items || (items && !items.length)) return null;
  const itemsData = items.map((item, index) => {
    const { title, value } = item;
    return <Cards key={`block-${index}`} title={title} value={value} />
  })
  return <div className="flex flex-wrap gap-4">{itemsData}</div>
}

export default CardsGroup;