import { ClientApi } from "./client";
import { EXTERNAL_LINKS } from "./links";

export const fetchClusters = ({ currentPermission, phase }) => {
  return ClientApi(currentPermission.id, phase)
    .get(EXTERNAL_LINKS.CLUSTERS.SINGLE)
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const fetchClusterReport = ({
  currentPermission,
  clusterId,
  pcId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(EXTERNAL_LINKS.CLUSTERS.REPORT({ clusterId, pcId }))
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const fetchClusterReportPDF = ({
  currentPermission,
  clusterId,
  pcId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(EXTERNAL_LINKS.CLUSTERS.REPORT_PDF({ clusterId, pcId }))
    .then((response) => response.data.data)
    .catch((error) => error);
};
