import { ClientApi } from "./client";
import { EXTERNAL_LINKS } from "./links";

const fetchParliamentryConstituenciesDetails = ({
  currentPermission,
  clusterId,
  pcId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(EXTERNAL_LINKS.CLUSTERS.PC(clusterId, pcId))
    .then((response) => response.data.data)
    .catch((error) => error);
};

const fetchParliamentryConstituenciesPercentageDetails = ({
  currentPermission,
  clusterId,
  pcId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(EXTERNAL_LINKS.CLUSTERS.PC_PERCENTAGE_DETAILS({ clusterId, pcId }))
    .then((response) => response.data.data)
    .catch((error) => error);
};

const fetchParliamentryConstituenciesVists = ({
  currentPermission,
  clusterId,
  pcId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase).get(
    EXTERNAL_LINKS.CLUSTERS.PC_VISIT(clusterId, pcId)
  );
};

const createParliamentryConstituenciesVisit = ({
  currentPermission,
  clusterId,
  pcId,
  data,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .post(EXTERNAL_LINKS.CLUSTERS.PC_VISIT(clusterId, pcId), data)
    .then((response) => response.data.data)
    .catch((_) => false);
};

const fetchParliamentryConstituenciesVisit = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .patch(
      EXTERNAL_LINKS.CLUSTERS.PC_VISIT_ID({
        currentPermission,
        clusterId,
        pcId,
        visitId,
      })
    )
    .then((response) => response.data.data)
    .catch((_) => false);
};

const updateParliamentryConstituenciesVisit = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  data,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .patch(
      EXTERNAL_LINKS.CLUSTERS.PC_VISIT_ID({
        currentPermission,
        clusterId,
        pcId,
        visitId,
      }),
      data
    )
    .then((response) => response.data.data)
    .catch((_) => false);
};

const deleteParliamentryConstituenciesVisit = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .delete(
      EXTERNAL_LINKS.CLUSTERS.PC_VISIT_ID({
        currentPermission,
        clusterId,
        pcId,
        visitId,
      })
    )
    .then((response) => response.data.data)
    .catch((_) => false);
};

const getParliamentryConstituencyVisitReport = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  reportId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(
      EXTERNAL_LINKS.CLUSTERS.PC_SINGLE_VISIT({
        clusterId,
        pcId,
        visitId,
        reportType,
        reportId,
      })
    )
    .then((response) => response.data.data)
    .catch((_) => false);
};

const updateOrCreateParliamentryConstituencyVisitReport = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  reportId,
  data,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase).post(
    EXTERNAL_LINKS.CLUSTERS.PC_SINGLE_VISIT({
      clusterId,
      pcId,
      visitId,
      reportType,
      reportId,
    }),
    data
  );
};

const getParliamentryConstituencyInsights = ({
  currentPermission,
  clusterId,
  pcId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(EXTERNAL_LINKS.CLUSTERS.PC_INSIGHTS({ clusterId, pcId }))
    .then((response) => response.data.data)
    .catch((_) => false);
};

export {
  fetchParliamentryConstituenciesDetails,
  fetchParliamentryConstituenciesVists,
  createParliamentryConstituenciesVisit,
  getParliamentryConstituencyVisitReport,
  fetchParliamentryConstituenciesVisit,
  updateOrCreateParliamentryConstituencyVisitReport,
  updateParliamentryConstituenciesVisit,
  getParliamentryConstituencyInsights,
  deleteParliamentryConstituenciesVisit,
  fetchParliamentryConstituenciesPercentageDetails,
};
