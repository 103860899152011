import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import ClusterReportItems from "../../components/ClusterReportItems";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import Title from "../../components/Title";
import { fetchClusterReport } from "../../services";
import { CLUSTER_LINKS } from "../../utils/internalLinks";
import useAuth from "../../utils/useAuth";
import DownloadIcon from "images/download.svg";
import NotAuthorized from "../../components/NotAuthorized";
import { ERROR_STATUS_CODE } from "../../utils/constants";
import usePhase from "../../utils/usePhase";

const ClusterReport = () => {
  const auth = useAuth();
  const { clusterId, pcId } = useParams();
  const { currentPermission } = auth;
  const phase = usePhase();
  const [fetching, setFetching] = useState(false);
  const [report, setReport] = useState(null);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  useEffect(() => {
    const getClusters = async () => {
      if (!currentPermission || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      let response = await fetchClusterReport({
        currentPermission,
        clusterId,
        pcId,
        phase,
      });
      setFetching(false);
      if (response.isAxiosError) {
        if (response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED) {
          setIsUnauthorized(true);
          return;
        }
        toast.error("Something went wrong...");
        return;
      }
      setReport(response);
    };
    getClusters();
  }, [currentPermission]);

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  if (!report) return null;

  const PDFLink = CLUSTER_LINKS.REPORT_PDF(clusterId, pcId, phase);

  return (
    <div>
      <Title imageSrc={report.icon || ""} title={report.title || ""} />
      <ClusterReportItems report={report.report || []} />
      <div className="flex mt-16 items-center justify-center">
        <Button
          as={Link}
          className="px-12 py-3 border-2 border-[#163560] rounded-full inline-flex items-center justify-center"
          to={PDFLink}
        >
          <span className="text-xl font-bold text-[#163560]">
            Download as PDF
          </span>
          <img className="ml-2.5" src={DownloadIcon} alt="download" />
        </Button>
      </div>
    </div>
  );
};

export default ClusterReport;
