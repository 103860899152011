import React from "react";
import NotAuthorizedIcon from "images/401.svg";
import { Link } from "react-router-dom";

const NotAuthorized = ({ handleClick }) => {
  const onClick = () => {
    handleClick?.();
  };

  const Component =
    handleClick && typeof handleClick === "function" ? "div" : Link;
  return (
    <div className="min-h-screen py-14 mx-auto max-w-8xl px-4 sm:px-6 lg:px-28 bg-neutral-50">
      <Component
        onClick={onClick}
        to="/"
        className="cursor-pointer w-full block"
      >
        <img src={NotAuthorizedIcon} className="mx-auto" alt="not found" />
      </Component>
    </div>
  );
};

export default React.memo(NotAuthorized);
