import Media from "./Media";
import Meetings from "./Meetings";
import OverallFeedback from "./OverallFeedback";
import VidhanSabha from "./VidhanSabha";
import MediaWithFolders from "./MediaWithFolders";
import PravasDays from "./PravasDays";

let ReportTypes = {};

ReportTypes["media"] = MediaWithFolders; 
ReportTypes["meetings"] = Meetings;
ReportTypes["overall-feedback"] = OverallFeedback;
ReportTypes["vidhan-sabha"] = VidhanSabha;
ReportTypes["pravas_days"] = PravasDays;

export default ReportTypes;