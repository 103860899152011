import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { parliamentConstituencies } from "../../../services";
import useAuth from "../../../utils/useAuth";
import usePhase from "../../../utils/usePhase";
import Title from "../../Title";
import FileIcon from "images/file.svg";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import DownloadIcon from "images/download.svg";
import BorderedText from "../../BorderedText";

const LokSabhaInsights = () => {
    const [open, setOpen] = useState(false);
    const [insights, setInsights] = useState([]);
    const [fetching, setFetching] = useState(false);
    const { clusterId, pcId } = useParams();
    const currentPhase = usePhase();
    const auth = useAuth();
    const { currentPermission } = auth;

    useEffect(() => {
        const getInsights = async () => {
            if (!currentPermission || !currentPhase || fetching) return;
            setFetching(true);
            const pcInsights =  await parliamentConstituencies.getParliamentryConstituencyInsights({ currentPermission, clusterId, pcId, phase: currentPhase });
            setFetching(false);
            console.log('pcInsights', pcInsights);
            setInsights(pcInsights || []);
        }
        getInsights();
    }, [currentPermission, currentPhase])
    
    if (!insights.length) return null;

    const toggleOpen = () => setOpen(!open);
    
    const tableBodyItems = insights.map((insight, i) => {
        const sNo = `${i + 1}.`;
        return <tr className="border-white" key={insight.id}>
            <td className="pl-7 text-[#828282] text-sm border border-r-0 py-3 border-black rounded-tl-lg rounded-bl-lg">{sNo}</td>
            <td className="py-3 text-lg font-bold border-y border-black">{insight.name}</td>
            <td className="rounded-tr-lg text-center border border-l-0 border-black py-3 rounded-br-lg">
                <a download={true} className="bg-[#E6EBFE] inline-block border border-[#4A6D9D] p-1.5 rounded-md" target="_blank" rel="noopener noreferrer" href={insight.link}>
                    <img src={DownloadIcon} alt={insight.name} />
                </a>
            </td>
        </tr>
    })
    return <div className="bg-white mt-6 shadow-sm p-5 box-border rounded-lg">
        {
            open
            ? <div>
                <div className="flex cursor-pointer justify-between" onClick={toggleOpen}>
                    <BorderedText className="after:bg-[#FF9559] text-xl text-[#163560]" text={"List of Previous Reports"} />
                    <ChevronUpIcon className="w-12 h-auto text-gray-500" aria-hidden="true" />
                </div>
                <table className="border-separate border-spacing-x-0 border-spacing-y-4 w-full">
                    <thead>
                        <tr className="bg-[#5384FC] text-white text-lg">
                            <th className="rounded-tl-lg rounded-bl-lg pl-7 py-2 text-left">S No.</th>
                            <th className="py-2 w-3/4 text-left">Meeting Title</th>
                            <th className="rounded-tr-lg rounded-br-lg py-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableBodyItems}
                    </tbody>
                </table>
            </div>
            : <div onClick={toggleOpen} className="flex cursor-pointer flex-start justify-between">
                <Title imageSrc={FileIcon} 
                    imageClassName="w-14 h-14"
                    title={"Lok Sabha Analysis & Insights"} 
                    description="Click here to find Election results, Lok Sabha insights, Sangathan data." />
                <ChevronDownIcon className="w-12 h-auto text-gray-500" aria-hidden="true" />
            </div>
        }
    </div>
}

export default LokSabhaInsights;