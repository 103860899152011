import React from "react";
import LinearProgressBar from "./LinearProgressBar";
import List from "./List";
import TableText from "./TableText";
import ViewIcon from "images/eye.svg";
import { CLUSTER_LINKS } from "../../utils/internalLinks";
import { Link } from "react-router-dom";

const TABLE_ROW_BACKEND_MAPPER = {
  LIST: "list",
  STRING: "string",
  PROGRESSBAR: "progressbar",
  LINK: "link",
};

const ActionLink = ({ value, phase }) => {
  if (!value.is_generated) return null;
  const { pc_id, cluster_id } = value;
  const link = CLUSTER_LINKS.REPORT_PDF(cluster_id, pc_id, phase);
  return (
    <Link
      className="flex items-center px-3 shrink-0 justify-center h-full"
      to={link}
    >
      <img className="mr-1.5 w-4 h-auto block" src={ViewIcon} alt="view pdf" />
      <span className="underline whitespace-pre	text-xs poppins font-medium text-[#3B6CE4]">
        View Report
      </span>
    </Link>
  );
};

const TABLE_ROW_COMPONENT_VS_TABLE_ROW_BACKEND_MAPPER = {
  [TABLE_ROW_BACKEND_MAPPER.STRING]: TableText,
  [TABLE_ROW_BACKEND_MAPPER.LIST]: List,
  [TABLE_ROW_BACKEND_MAPPER.PROGRESSBAR]: LinearProgressBar,
  [TABLE_ROW_BACKEND_MAPPER.LINK]: ActionLink,
};

const TableComponets = ({ type, phase, value }) => {
  const Component = TABLE_ROW_COMPONENT_VS_TABLE_ROW_BACKEND_MAPPER[type];
  if (!Component) return null;
  return <Component phase={phase} value={value} />;
};

export default TableComponets;
