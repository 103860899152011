import React from "react";
import TableComponets from "./TableComponents";

const List = ({ value, phase }) => {
  const items = value.map((item, index) => {
    const { value, type } = item;
    return (
      <div
        key={`item-${index}`}
        className="h-16 last:border-0 border-b border-[#D6D6D6]"
      >
        <TableComponets phase={phase} value={value} type={type} />
      </div>
    );
  });
  return items;
};

export default List;
