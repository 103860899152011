import ParliamentImage from "images/parliament.svg";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../../components/Button";
import InformationMeeting from "../../../components/InformationMeetings/InformationMeetings";
import FullPageLoader from "../../../components/Loaders/FullPageLoader";
import MetaBar from "../../../components/MetaBar";
import NotAuthorized from "../../../components/NotAuthorized";
import ReportBlock from "../../../components/ReportBlock/ReportBlock";
import Title from "../../../components/Title";
import { parliamentConstituencies, reportItems } from "../../../services";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../../utils/internalLinks";
import useAuth from "../../../utils/useAuth";
import usePhase from "../../../utils/usePhase";

const MeetingTypes = ({ meetingTypes, handleMeetingChange }) => {
  const meetingTypesItems = meetingTypes.map((report, index) => {
    const blockData = {
      title: report.name,
      description: report.description,
      onClick: () => handleMeetingChange(report, index),
      image: report.image,
    };
    return <ReportBlock key={`report-${index}`} blockData={blockData} />;
  });
  return (
    <div className="mt-4 lg:mt-8 grid grid-cols-2 gap-3 lg:gap-8">
      {meetingTypesItems}
    </div>
  );
};

const Meetings = () => {
  const { clusterId, pcId, visitId, reportType } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const phase = usePhase();
  const { currentPermission } = auth;
  const [meta, setMeta] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [meetingTypes, setMeetingTypes] = useState([]);
  const [currentMeeting, setCurrentMeeting] = useState(null);
  const [meetingType, setMeetingType] = useState(null);
  const [isFetchingMeeting, setIsFetchingMeeting] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  useEffect(() => {
    const getParliamentConstituentiesAndMeetingsType = async () => {
      if (!currentPermission || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      const pcInformation =
        await parliamentConstituencies.fetchParliamentryConstituenciesDetails({
          phase,
          currentPermission,
          clusterId,
          pcId,
        });
      if (
        pcInformation &&
        pcInformation.isAxiosError &&
        pcInformation?.response?.status === 401
      ) {
        setFetching(false);
        setIsUnauthorized(true);
        return;
      }
      const meetingTypes = await reportItems.fetchReportItemsByReportType({
        phase,
        currentPermission,
        reportType,
      });
      setFetching(false);
      if (pcInformation) {
        setMeta(pcInformation.meta);
      }
      if (meetingTypes?.data?.data) {
        const meetings = meetingTypes.data.data;
        setMeetingTypes(meetings);
        const paramsMeetingId = searchParams.get("meeting_id");
        if (paramsMeetingId) {
          let isMeetingAvailable = meetings.filter(
            (meeting) => meeting.id === parseInt(paramsMeetingId)
          );
          if (isMeetingAvailable && isMeetingAvailable.length) {
            let currentIndex = meetings.findIndex(
              (meeting) => meeting.id === parseInt(paramsMeetingId)
            );
            setCurrentMeeting(currentIndex);
            setMeetingType(isMeetingAvailable[0]);
          }
        } else {
          setCurrentMeeting(0);
        }
      }
    };
    getParliamentConstituentiesAndMeetingsType();
  }, [currentPermission]);

  const updateParams = (meeting) => {
    if (!meeting) return;
    setSearchParams(`meeting_id=${meeting.id}`);
  };

  const handleMeetingType = (type, index) => {
    setCurrentMeeting(index);
    setMeetingType(type);
    updateParams(type);
  };

  const disabledPrev = currentMeeting === 0;
  const isLast = currentMeeting === meetingTypes.length - 1;

  const handlePrev = () => {
    if (currentMeeting === 0) return;
    setMeetingType(meetingTypes[currentMeeting - 1]);
    setCurrentMeeting(currentMeeting - 1);
    updateParams(meetingTypes[currentMeeting - 1]);
  };

  const handleNext = () => {
    if (currentMeeting === meetingTypes.length - 1) {
      navigate(
        PARLIAMENT_CONSTITUENCY_LINKS.VISIT(clusterId, pcId, visitId, phase)
      );
      return;
    }
    setMeetingType(meetingTypes[currentMeeting + 1]);
    setCurrentMeeting(currentMeeting + 1);
    updateParams(meetingTypes[currentMeeting + 1]);
  };

  if (isUnauthorized) return <NotAuthorized />;

  return (
    <div>
      {meetingType ? (
        <>
          <InformationMeeting
            meeting={meetingType}
            handleFetchingMeeting={setIsFetchingMeeting}
            handleMeetingChange={handleMeetingType}
            meetingTypes={meetingTypes}
          />
          <div className="flex items-center justify-center mt-8">
            <Button.Rounded
              onClick={disabledPrev ? null : handlePrev}
              type="button"
              disabled={disabledPrev || isFetchingMeeting}
            >
              <span className="font-medium text-#163560]">Previous</span>
            </Button.Rounded>
            <Button.Rounded
              type="submit"
              className="ml-4"
              disabled={isFetchingMeeting}
              onClick={handleNext}
            >
              <span className="font-medium text-#163560]">
                {isLast ? "Submit" : "Next"}
              </span>
            </Button.Rounded>
          </div>
        </>
      ) : (
        <>
          <Title
            title="Information of Meetings &amp; Events Conducted in Lok Sabha"
            imageSrc={ParliamentImage}
          />
          {fetching ? (
            <div className="mt-5">
              <FullPageLoader />
            </div>
          ) : (
            <>
              <MetaBar meta={meta} />
              <MeetingTypes
                handleMeetingChange={handleMeetingType}
                meetingTypes={meetingTypes}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Meetings;
