import axios from "axios";

export const setPhaseHeaders = (phase) => {
  return {
    "x-phase": phase,
  };
};

const PRAVAS_APP_URL = `pravas-app/permission`;

export const ClientApi = (permissionId, phase = "", isPravasApp) =>
  axios.create({
    baseURL: `/api${isPravasApp ? `/${PRAVAS_APP_URL}` : ""}/${permissionId}`,
    ...(phase && {
      headers: {
        ...setPhaseHeaders(phase),
      },
    }),
    withCredentials: true,
  });
