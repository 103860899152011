import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const LinearProgressBar = ({ value }) => {
  const { percentage, color } = value;
  
  const style = {
    height: 8,
    borderRadius: 5,
    backgroundColor: `#EEEEEE`,
    ...(color && {
      [`& .MuiLinearProgress-bar`]: {
         backgroundColor: color
       }
    })
  }

  return (
      <div className="w-36 px-3 relative h-full flex items-center justify-end mx-auto">
          <Box className="flex-1">
            <span className="block text-right font-bold mulish text-xs mb-0.5">{percentage}%</span>
            <LinearProgress sx={style} variant="determinate" value={percentage} />
          </Box>
      </div>
    );
}

export default LinearProgressBar;