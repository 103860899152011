import { ClientApi } from "./client";
import { EXTERNAL_LINKS } from "./links";

export const fetchMediaForm = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(EXTERNAL_LINKS.CLUSTERS.FORM({ clusterId, pcId, visitId, reportType }))
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const updateMediaForm = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  data,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .post(
      EXTERNAL_LINKS.CLUSTERS.FORM({ clusterId, pcId, visitId, reportType }),
      data
    )
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const deleteMedia = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  fileId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .delete(
      EXTERNAL_LINKS.MEDIA.SINGLE_FILE({
        clusterId,
        pcId,
        visitId,
        reportType,
        fileId,
      })
    )
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const createMediaFolder = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  data,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .post(
      EXTERNAL_LINKS.MEDIA.FOLDER({ clusterId, pcId, visitId, reportType }),
      data
    )
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const getMediaFolder = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  folderId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(
      EXTERNAL_LINKS.MEDIA.SINGLE_FOLDER({
        clusterId,
        pcId,
        visitId,
        reportType,
        folderId,
      })
    )
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const updateMediaFolder = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  folderId,
  data,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .patch(
      EXTERNAL_LINKS.MEDIA.SINGLE_FOLDER({
        clusterId,
        pcId,
        visitId,
        reportType,
        folderId,
      }),
      data
    )
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const deleteMediaFolder = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  folderId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .delete(
      EXTERNAL_LINKS.MEDIA.SINGLE_FOLDER({
        clusterId,
        pcId,
        visitId,
        reportType,
        folderId,
      })
    )
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const getFolderAttachments = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  folderId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .get(
      EXTERNAL_LINKS.MEDIA.ATTACHMENT({
        clusterId,
        pcId,
        visitId,
        reportType,
        folderId,
      })
    )
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const updateFolderAttachments = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  folderId,
  formData,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .post(
      EXTERNAL_LINKS.MEDIA.ATTACHMENT({
        clusterId,
        pcId,
        visitId,
        reportType,
        folderId,
      }),
      formData
    )
    .then((response) => response.data.data)
    .catch((error) => error);
};

export const deleteFolderAttachments = ({
  currentPermission,
  clusterId,
  pcId,
  visitId,
  reportType,
  folderId,
  attachmentId,
  phase,
}) => {
  return ClientApi(currentPermission.id, phase)
    .delete(
      EXTERNAL_LINKS.MEDIA.SINGLE_ATTACHMENT({
        clusterId,
        pcId,
        visitId,
        reportType,
        folderId,
        attachmentId,
      })
    )
    .then((response) => response.data.data)
    .catch((error) => error);
};
