import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BorderedText from "../../components/BorderedText";
import Button from "../../components/Button";
import FileUpload from "../../components/FormInputs/FileUpload";
import TextArea from "../../components/FormInputs/TextArea";
import Success from "../../components/Modals/Success/Success";
import RemoveConfirmation from "../../components/RemoveConfirmation/RemoveConfirmation";
import Title from "../../components/Title";
import {
  deleteMedia,
  fetchMediaForm,
  updateMediaForm,
} from "../../services/media";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../utils/internalLinks";
import useAuth from "../../utils/useAuth";
import Lightbox from "react-image-lightbox";
import NotAuthorized from "../../components/NotAuthorized";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import { ERROR_STATUS_CODE } from "../../utils/constants";
import usePhase from "../../utils/usePhase";

const FORM_TYPES = {
  FILE: "file",
  TEXTAREA: "textarea",
};

const Media = () => {
  const [submitting, setSubmitting] = useState(false);
  const auth = useAuth();
  const { currentPermission } = auth;
  const currentPhase = usePhase();
  const navigate = useNavigate();
  const { clusterId, pcId, visitId, reportType } = useParams();
  const [mediaForm, setMediaForm] = useState(null);
  const [successFormShow, setSuccessFormShow] = useState(false);
  const [fileToBeRemoved, setFileToBeRemoved] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  useEffect(() => {
    const getMediaForm = async () => {
      if (!currentPermission || currentPhase || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      const response = await fetchMediaForm({
        phase: currentPhase,
        currentPermission,
        clusterId,
        pcId,
        visitId,
        reportType,
      });
      setFetching(false);
      if (response.isAxiosError) {
        if (response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED) {
          setIsUnauthorized(true);
          return;
        }
        toast.error("Something went wrong!");
        return;
      }
      if (response && !response.isAxiosError) {
        setMediaForm(response);
      }
    };
    getMediaForm();
  }, [currentPermission, currentPhase]);

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  if (!mediaForm) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    let formData = new FormData(e.target);
    let newFormData = new FormData();
    for (const [key, value] of formData) {
      if (key.includes("file")) {
        if (value && value.size) {
          newFormData.append(key, value);
        }
      } else {
        newFormData.append(key, value);
      }
    }
    updateMediaForm({
      phase: currentPhase,
      currentPermission,
      clusterId,
      pcId,
      visitId,
      reportType,
      formData: newFormData,
    }).then((response) => {
      setSubmitting(false);
      if (response.isAxiosError) {
        return;
      }
      toast.success("Form submitted successfully");
      setSuccessFormShow(true);
    });
  };

  const handleSuccessFormClose = () => {
    setSuccessFormShow(false);
    navigate(
      PARLIAMENT_CONSTITUENCY_LINKS.VISIT(
        clusterId,
        pcId,
        visitId,
        currentPhase
      )
    );
  };

  const handleFileRemove = async () => {
    if (!fileToBeRemoved || deleting) return;
    setDeleting(true);
    let response = await deleteMedia({
      phase: currentPhase,
      currentPermission,
      clusterId,
      pcId,
      visitId,
      reportType,
      fileId: fileToBeRemoved,
    });
    setDeleting(false);
    if (response.isAxiosError) {
      toast.error("Something went wrong");
      return;
    }
    if (response) {
      let newForm = {
        ...mediaForm,
        form: mediaForm.form
          ? mediaForm.form.map((form) => {
              if (form.type !== FORM_TYPES.FILE) return form;
              return {
                ...form,
                value: form.value
                  ? form.value.filter(
                      (formFile) => formFile.id !== fileToBeRemoved
                    )
                  : [],
              };
            })
          : [],
      };
      setMediaForm(newForm);
      setFileToBeRemoved(null);
    }
  };

  const closeFileToBeRemoveModal = () => setFileToBeRemoved(!fileToBeRemoved);

  const openFileToBeRemoved = (fileId = "") => setFileToBeRemoved(fileId);

  const handleGallery = (index) => {
    setActiveIndex(index);
    setIsLightboxOpen(true);
  };

  const formItems =
    mediaForm && mediaForm.form
      ? mediaForm.form.map((form, index) => {
          const { value } = form;
          return (
            <div key={index}>
              {form.type === FORM_TYPES.FILE && (
                <div className="flex flex-wrap">
                  {Array(form.count)
                    .fill(true)
                    .map((_, index) => {
                      const handleRemove =
                        value && value[index] && value[index].id
                          ? () => openFileToBeRemoved(value[index].id)
                          : () => {};
                      const openGallery =
                        value && value[index] && value[index].id
                          ? (e) => {
                              e.preventDefault();
                              handleGallery(index);
                            }
                          : () => {};
                      return (
                        <FileUpload
                          value={value && value[index] ? value[index] : null}
                          {...(value &&
                            value[index] && {
                              handleRemove,
                              handleGallery: openGallery,
                            })}
                          key={`file-${index}`}
                          name={`files[]`}
                        />
                      );
                    })}
                </div>
              )}
              {form.type === FORM_TYPES.TEXTAREA && (
                <div className="mt-9">
                  <BorderedText text="Picture Description" />
                  <div className="px-3.5 box-border mt-4">
                    <TextArea
                      defaultValue={value}
                      name="comment"
                      placeholder={form.placeholder}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })
      : null;

  const handleGalleryClose = () => {
    setIsLightboxOpen(false);
    setActiveIndex(null);
  };

  const files =
    mediaForm.form.filter((form) => form.type === FORM_TYPES.FILE) &&
    mediaForm.form.filter((form) => form.type === FORM_TYPES.FILE).length
      ? mediaForm.form.filter((form) => form.type === FORM_TYPES.FILE)[0].value
      : [];

  const handleGalleryMoveNext = () => {
    const newIndex = (activeIndex + 1) % files.length;
    setActiveIndex(newIndex);
  };

  const handleGalleryMovePrev = () => {
    const newIndex = (activeIndex + files.length - 1) % files.length;
    setActiveIndex(newIndex);
  };

  return (
    <div>
      <Title title={mediaForm.name} description={mediaForm.description} />
      <form onSubmit={handleSubmit} className="mt-11">
        {formItems}
        <div className="mt-32 text-center">
          <Button.Rounded disabled={submitting}>
            {submitting ? "Saving..." : "Save & Review"}
          </Button.Rounded>
        </div>
      </form>
      <Success
        isOpen={successFormShow}
        title="Form submitted successfully"
        onClose={handleSuccessFormClose}
        showButton={true}
        buttonText="Close"
      />
      <RemoveConfirmation
        onCancel={closeFileToBeRemoveModal}
        onDelete={handleFileRemove}
        title="Remove File"
        deleteBtnText={deleting ? "Deleting..." : "Delete"}
        deleting={deleting}
        description="Are you sure you want to remove the file?"
        isOpen={!!fileToBeRemoved}
      />
      {isLightboxOpen && (
        <Lightbox
          mainSrc={files[activeIndex].url}
          nextSrc={files[(activeIndex + 1) % files.length].url}
          prevSrc={files[(activeIndex + files.length - 1) % files.length].url}
          onCloseRequest={handleGalleryClose}
          onMovePrevRequest={handleGalleryMoveNext}
          onMoveNextRequest={handleGalleryMovePrev}
        />
      )}
    </div>
  );
};

export default Media;
