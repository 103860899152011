import { ErrorMessage, Field } from "formik";
import CalendarOrange from "images/calendar_orange.svg";
import React from "react";

const DateInput = ({ name, label }) => {
  return (
    <div className="relative min-w-[150px] lg:min-w-[255px] mr-3 lg:mr-5 rounded-md">
      {!!label && (
        <label className="absolute z-1 px-3 bg-white text-xs ml-2.5 l-0 t-0 -mt-2.5">
          {label}
        </label>
      )}
      <Field
        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-10 lg:pl-7 lg:pr-12 sm:text-sm border-gray-500 rounded-md"
        type="date"
        name={name}
      />
      <ErrorMessage
        className="absolute -bottom-4 whitespace-pre text-xs text-red-700"
        name={name}
        component="p"
      />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <img src={CalendarOrange} alt="date input" />
      </div>
    </div>
  );
};

export default React.memo(DateInput);
