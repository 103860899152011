import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import CrossIcon from "images/charm_cross.svg";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { parliamentConstituencies } from "../../services";
import {
  MEETING_AGENDA_STATUS_BACKEND_MAPPING,
  MEETING_STATUS_BACKEND_MAPPING,
} from "../../utils/constants";
import useAuth from "../../utils/useAuth";
import usePhase from "../../utils/usePhase";
import BorderedText from "../BorderedText";
import Button from "../Button";
import SimpleSelect from "../FormInputs/SimpleSelect";
import TextInput from "../FormInputs/TextInput";
import Modal from "../Modal";

const AddMeeting = ({
  isOpen,
  meetingPriorities,
  meetingAgendaStatus,
  meetingMeta,
  onClose,
  editMeeting,
  onSubmit,
  meetingStatus,
}) => {
  const auth = useAuth();
  const { currentPermission } = auth;
  const phase = usePhase();
  const [meetingAgendas, setMeetingAgendas] = useState([
    { name: "", key: Math.random() },
  ]);
  const [meetingForm, setMeetingForm] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addAgenda = () => {
    setMeetingAgendas([...meetingAgendas, { key: Math.random(), name: "" }]);
  };

  const handleInputChange = (e) => {
    setMeetingForm({
      ...meetingForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setMeetingForm({});
    setMeetingAgendas([{ name: "", key: Math.random() }]);
  };

  useEffect(() => {
    if (!isOpen) {
      handleClose();
    }
  }, [isOpen]);

  useEffect(() => {
    if (editMeeting) {
      const prioityObj = meetingPriorities.filter(
        (priority) => priority.id === parseInt(editMeeting.meeting_priority_id)
      );
      const meetingNameWithoutPriority = prioityObj.length
        ? editMeeting.name.replace(`${prioityObj[0].name} - `, "")
        : editMeeting.name;
      setMeetingForm({
        name: editMeeting ? meetingNameWithoutPriority : "",
        priority: editMeeting ? editMeeting.meeting_priority_id : "",
      });
      setMeetingAgendas(
        editMeeting.meeting_agendas || [{ name: "", key: Math.random() }]
      );
    } else {
      setMeetingForm({
        name: "",
        priority: "",
      });
      setMeetingAgendas([{ name: "", key: Math.random() }]);
    }
  }, [editMeeting]);

  const removeAgenda = (agendaId) => {
    setMeetingAgendas(
      meetingAgendas.filter((agenda) =>
        agenda.key ? agenda.key !== agendaId : agenda.id !== agendaId
      )
    );
  };

  const updateAgenda = (agendaId, agendaText) => {
    setMeetingAgendas(
      meetingAgendas.map((agenda) => {
        if (agenda.key) {
          if (agenda.key !== agendaId) return agenda;
          return {
            ...agenda,
            name: agendaText,
          };
        } else {
          if (agenda.id !== agendaId) return agenda;
          return {
            ...agenda,
            name: agendaText,
          };
        }
      })
    );
  };

  const handleSubmit = async () => {
    if (!meetingForm.name.trim()) {
      toast.error("Meeting title missing");
      return;
    }
    if (!meetingForm.priority) {
      toast.error("Meeting priority missing");
      return;
    }
    const emptyMeetingAgenda = meetingAgendas.filter(
      (agenda) => typeof agenda.name !== "undefined" && !agenda.name.trim()
    );
    if (emptyMeetingAgenda.length) {
      toast.error("Please add meeting agenda text.");
      return;
    }
    if (isSubmitting) return;
    setIsSubmitting(true);
    const status = meetingStatus.filter(
      (status) => status.status === MEETING_STATUS_BACKEND_MAPPING.DONE
    )[0];
    const agendaStatus = meetingAgendaStatus.filter(
      (status) => status.status === MEETING_AGENDA_STATUS_BACKEND_MAPPING.YES
    )[0];
    const data = {
      name: meetingForm.name,
      priority: meetingForm.priority,
      meeting_status: status,
      ...(editMeeting && {
        id: editMeeting.id,
      }),
      meeting_agendas: meetingAgendas.map((agenda) => {
        return {
          name: agenda.name,
          agenda_status: agendaStatus,
          ...(agenda.id && {
            id: agenda.id,
          }),
        };
      }),
    };
    const payload = {
      ...meetingMeta,
      currentPermission,
      phase,
      data,
    };
    const createNewMeeting =
      await parliamentConstituencies.updateOrCreateParliamentryConstituencyVisitReport(
        payload
      );
    setIsSubmitting(false);
    if (createNewMeeting?.data?.data) {
      onSubmit(createNewMeeting?.data?.data);
    } else {
      toast("Something went wrong");
    }
  };

  const agendaList = meetingAgendas.map((agenda) => {
    const onDelete = () => removeAgenda(agenda.key || agenda.id);
    const onChange = (e) => {
      updateAgenda(agenda.key || agenda.id, e.target.value);
    };
    return (
      <div
        className="flex items-center jusitfy-between px-3 mt-3"
        key={agenda.key || agenda.id}
      >
        <TextInput
          type="text"
          onChange={onChange}
          value={agenda.name}
          placeholder="Meeting Agenda"
          maxLength={200}
          className="flex-1"
        />
        <Button className="ml-6" onClick={onDelete}>
          <img src={CrossIcon} alt="delete" />
        </Button>
      </div>
    );
  });

  const meetingPrioritiesItem = meetingPriorities.map((priority) => {
    const { id, name } = priority;
    return (
      <option key={id} value={id}>
        {name}
      </option>
    );
  });

  const textTitle = editMeeting ? "Edit Meeting-" : "Create New Meeting-";

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Dialog.Panel className="relative bg-white rounded-lg p-5 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6 lg:w-[960px] xl:w-[1080px] lg:max-w-5xl">
        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div>
          <div className="flex mt-8 pb-7 justify-between items-center">
            <BorderedText className="shrink-0" text={textTitle} />
            <TextInput
              type="text"
              onChange={handleInputChange}
              placeholder="Meeting Title"
              maxLength={500}
              name="name"
              className="flex-1 mx-5"
              value={meetingForm.name || ""}
            />
            <SimpleSelect
              onChange={handleInputChange}
              name="priority"
              value={meetingForm.priority || ""}
            >
              <option value="">Priority</option>
              {meetingPrioritiesItem}
            </SimpleSelect>
          </div>
          <div className="pt-7 border-t border-t-gray-200">
            <div className="flex items-center justify-between">
              <BorderedText text="Add Meeting Agenda" />
              <Button
                className="font-medium rounded-full py-1.5 px-6 text-white focus:outline-none text-lg bg-[#5AA3F3]"
                onClick={addAgenda}
              >
                Add Agenda +
              </Button>
            </div>
            <div className="mt-7">{agendaList}</div>
          </div>
          <div className="flex items-center justify-center mt-7">
            <Button
              className="font-medium py-1.5 px-6 rounded-full text-white focus:outline-none text-lg bg-[#61BE7A]"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              {isSubmitting ? "Saving..." : "Save Meeting"}
            </Button>
          </div>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};

export default AddMeeting;
