import React from "react";
import Button from "../../../../components/Button";
import { dateFormat } from "../../../../utils/helper";
import DustbinIcon from "images/dustbin.svg";
import BlackPencilIcon from "images/pencil_black.svg";
import InfoIcon from "images/info.svg";
import ShowMoreText from "../../../../components/ShowMoreText";
import ReactTooltip from "react-tooltip";

const QuestionValuesTable = ({ question, onDelete, isPDF, onEdit }) => {
  const { values } = question;
  if (!values || (values && !values.length)) return null;

  const valuesItems = values.map((valueItem, i) => {
    const { start_date, value, action, id } = valueItem;
    const handleDelete = () => onDelete(id);
    const handleEdit = () => onEdit?.(valueItem);
    return (
      <tr key={i} className="even:bg-[#F1F6FF]">
        <td className="p-4">{i + 1}</td>
        <td className="p-4 text-center">
          {dateFormat(new Date(start_date), "dd-MM-yyyy")}
        </td>
        <td className="p-4 text-center">
          {isPDF ? (
            <p className="text-sm">{value}</p>
          ) : (
            <ShowMoreText textClassName="text-base" linkClassName="text-base">
              <p>{value}</p>
            </ShowMoreText>
          )}
        </td>
        {!isPDF && (
          <td className="p-4 text-center">
            <div className="flex items-center justify-evenly">
              {action?.can_edit && (
                <Button onClick={handleEdit}>
                  <img src={BlackPencilIcon} alt="edit" />
                </Button>
              )}
              {action?.can_delete && (
                <Button onClick={handleDelete}>
                  <img src={DustbinIcon} alt="delete" />
                </Button>
              )}
              {!!action?.info && (
                <img src={InfoIcon} data-tip={action.info} alt={action.info} />
              )}
              <ReactTooltip />
            </div>
          </td>
        )}
      </tr>
    );
  });
  return (
    <>
      <table className="w-full print:break-inside-avoid mt-4">
        <thead>
          <tr className="bg-[#4471B0] text-xl font-medium text-white">
            <th className="p-4 border border-2 border-white border-l-0 w-1/12 rounded-tl-xl">
              Meeting
            </th>
            <th className="p-4 border border-2 border-white w-2/12">Date</th>
            <th
              className={`p-4 border border-2 border-white w-7/12 ${
                isPDF ? "rounded-tr-xl" : ""
              }`}
            >
              Feedback/Review/MOM/Discussions
            </th>
            {!isPDF && (
              <th className="p-4 border border-2 border-white border-r-0 w-2/12 rounded-tr-xl">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>{valuesItems}</tbody>
      </table>
    </>
  );
};

export default QuestionValuesTable;
