export const REPORT_TYPE_BACKEND_MAPPER = {
  META: "meta",
  VISITS: "visits",
  TITLE: "title",
  MEETINGS: "meetings",
  OVERALL_FEEDBACK: "overall-feedback",
  MEDIA: "media",
  VIDHAN_SABHA: "vidhan-sabha",
  PAGEBREAK: "pagebreak",
  PRAVAS_DAYS: "pravas_days",
  SUBTITLE: "sub_title",
};
