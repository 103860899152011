import { ClientApi } from "./client";
import { EXTERNAL_LINKS } from "./links";

const fetchReportItems = ({ currentPermission, phase }) => {
  return ClientApi(currentPermission.id, phase).get(EXTERNAL_LINKS.REPORT_ITEMS.SINGLE)
}

const fetchReportItemsByReportType = ({ currentPermission, reportType, phase }) => {
  return ClientApi(currentPermission.id, phase).get(EXTERNAL_LINKS.REPORT_ITEMS.SINGLE_TYPE(reportType))
}

const fetchMeetingAgendaStatus = ({ currentPermission, phase }) => {
  return ClientApi(currentPermission.id, phase).get(EXTERNAL_LINKS.REPORT_ITEMS.MEETING_AGENDA_STATUS)
}

const fetchMeetingStatus = ({ currentPermission, phase }) => {
  return ClientApi(currentPermission.id, phase).get(EXTERNAL_LINKS.REPORT_ITEMS.MEETING_STATUS);
}

const fetchMeetingPriorities = ({ currentPermission, phase }) => {
  return ClientApi(currentPermission.id, phase).get(EXTERNAL_LINKS.REPORT_ITEMS.MEETING_PRIORITIES);
}

export {
  fetchReportItems,
  fetchReportItemsByReportType,
  fetchMeetingAgendaStatus,
  fetchMeetingStatus,
  fetchMeetingPriorities
};

