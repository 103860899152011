export { REPORT_TYPE_BACKEND_MAPPER } from "../components/ClusterReportItems/constants"

export const MEETING_STATUS_BACKEND_MAPPING = {
  PENDING: "pending",
  DONE: "done",
  POSTPONED: "postponed"
}

export const MEETING_AGENDA_STATUS_BACKEND_MAPPING = {
  YES: "yes",
  NO: "no",
  NOT_APPLICABLE: "not-applicable"
}

export const DEFINED_BY_BACKEND_MAPPING = {
  USER: "user",
  SYSTEM: "system"
}

export const ERROR_STATUS_CODE = {
  NOT_AUTHORIZED: 401,
  SEE_OTHER: 303
}