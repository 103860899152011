import classNames from "classnames";
import React from "react";

const Title = ({ title, description, imageSrc, imageClassName }) => {
  const imageClassNames = classNames(
    "mr-3 print:inline-flex break-before-avoid break-after-avoid w-7 h-7 object-contain",
    imageClassName
  );
  return (
    <div className="flex print:inline-flex break-before-avoid break-after-avoid items-start">
      {imageSrc && (
        <img className={imageClassNames} src={imageSrc} alt={title} />
      )}
      <div>
        <h2 className="text-[22px] inline-block break-before-avoid break-after-avoid font-medium poppins">
          {title}
        </h2>
        {description && (
          <p className="text-[12px] break-before-avoid break-after-avoid block font-medium my-[5px] text-ls-dark-gray">
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

export default Title;
