import React, { memo } from "react";
import t from "prop-types";
import BorderedText from "../BorderedText";
import InformationMeetingTable from "../Meetings/InformationMeetingTable";
import EditBtn from "./EditBtn";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../utils/internalLinks";
import { useParams } from "react-router-dom";
import usePhase from "../../utils/usePhase";

const Meeting = ({ meeting, item, items, isReadOnly, visit, type, isPDF }) => {
  if (!meeting) return null;

  const { clusterId, pcId } = useParams();
  const phase = usePhase();
  const { name } = meeting;
  const editLink = `${PARLIAMENT_CONSTITUENCY_LINKS.VISIT_TYPE(
    clusterId,
    pcId,
    visit.id,
    type,
    phase
  )}?meeting_id=${meeting.id}`;

  return (
    <div className="box-border shadow mt-8 bg-white rounded-lg py-4 px-6">
      <BorderedText text={name} />
      <div className="mt-3">
        <InformationMeetingTable
          isReadOnly={isReadOnly}
          isPDF={isPDF}
          meetingData={item}
        />
      </div>
      {!isPDF && (
        <div className="mt-9 flex items-center justify-end">
          <EditBtn link={editLink} />
        </div>
      )}
    </div>
  );
};

Meeting.propTypes = {
  meeting: t.object,
  items: t.array,
  isReadOnly: t.bool,
  isPDF: t.bool,
  type: t.string,
  visit: t.object,
};

Meeting.defaultProps = {
  meeting: null,
  items: [],
  isReadOnly: false,
  isPDF: false,
  type: "",
  visit: null,
};

export default memo(Meeting);
