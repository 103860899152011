import React, { createContext, useEffect, useState } from "react";
import {
  CLUSTER_LINKS,
  DEFAULT_PAGE_VS_APP_PERMISSION,
  PHASE_LINKS,
} from "./internalLinks";

export let AuthContext = createContext(null);

const LOCALSTORAGE_AUTH = "app_permission";

const AuthProvider = ({ user, signOutUrl, signInUrl, children }) => {
  const [currentPermission, setCurrentPermission] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [homeLink, setHomeLink] = useState();
  const [currentUser, setCurrentUser] = useState(user || null);

  const handleHomeLinkChange = () => {
    const link = user
      ? currentPermission &&
        currentPermission.name &&
        DEFAULT_PAGE_VS_APP_PERMISSION[currentPermission.name]
        ? DEFAULT_PAGE_VS_APP_PERMISSION[currentPermission.name]
        : user.app_permissions &&
          user.app_permissions.length &&
          DEFAULT_PAGE_VS_APP_PERMISSION[user.app_permissions[0].name]
        ? DEFAULT_PAGE_VS_APP_PERMISSION[user.app_permissions[0].name]
        : PHASE_LINKS.ALL
      : "/login";
    setHomeLink(link);
  };

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
      if (user.app_permissions) {
        let alreadySetPermission = localStorage.getItem(LOCALSTORAGE_AUTH);
        if (alreadySetPermission) {
          alreadySetPermission = JSON.parse(alreadySetPermission);
          let permissionExists = user.app_permissions.filter(
            (permission) => permission.id === parseInt(alreadySetPermission.id)
          );
          if (permissionExists && permissionExists.length) {
            setCurrentPermission(alreadySetPermission);
          } else {
            setCurrentPermission(user.app_permissions[0]);
          }
        } else {
          setCurrentPermission(user.app_permissions[0]);
        }
        setPermissions(user.app_permissions);
      }
    } else {
      setCurrentUser(null);
    }
  }, [user]);

  const handlePermissionChange = (newId) => {
    let newPermission = permissions.filter(
      (permission) => permission.id === newId
    )[0];
    localStorage.setItem(LOCALSTORAGE_AUTH, JSON.stringify(newPermission));
    setCurrentPermission(newPermission);
    return (
      DEFAULT_PAGE_VS_APP_PERMISSION[newPermission.name] || CLUSTER_LINKS.ALL
    );
  };

  useEffect(() => {
    handleHomeLinkChange();
  }, [currentPermission, user]);

  const signin = (user, callback) => {
    // sign in user
    setCurrentUser(user);
    callback();
  };

  const signout = () => {
    // sign out user
    localStorage.removeItem(LOCALSTORAGE_AUTH);
    setCurrentUser(null);
  };

  let value = {
    user: currentUser,
    signOutUrl,
    signInUrl,
    signin,
    signout,
    permissions,
    currentPermission,
    handlePermissionChange,
    homeLink,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
