import React, { memo } from "react";
import t from "prop-types";
import Comment from "./Comment";
import { dateFormat } from "../../utils/helper";
import EditBtn from "./EditBtn";
import { useParams } from "react-router-dom";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../utils/internalLinks";
import { REPORT_TYPE_BACKEND_MAPPER } from "./constants";
import PageBreak from "./PageBreak";
import { v4 as uuidv4 } from "uuid";
import MediaItems from "./Media/MediaItems";
import usePhase from "../../utils/usePhase";

const Media = ({ items, type, visit, isPDF }) => {
  if (!items?.length) return null;
  const { clusterId, pcId } = useParams();
  const phase = usePhase();
  const visitEditLink = !isPDF
    ? PARLIAMENT_CONSTITUENCY_LINKS.VISIT_TYPE(
        clusterId,
        pcId,
        visit.id,
        type,
        phase
      )
    : null;
  return (
    <div className="rounded-lg py-8 px-14 bg-white mt-14">
      <MediaItems items={items} isPDF={isPDF} />
      {!isPDF && (
        <div className="flex items-center justify-center mt-9">
          <EditBtn link={visitEditLink} />
        </div>
      )}
    </div>
  );
};

Media.propTypes = {
  items: t.array,
  type: t.string,
  isPDF: t.bool,
};

Media.defaultPropTypes = {
  items: [],
  type: "",
  isPDF: false,
};

export default memo(Media);
