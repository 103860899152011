import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { parliamentConstituencies, reportItems } from "../../services";
import {
  DEFINED_BY_BACKEND_MAPPING,
  MEETING_STATUS_BACKEND_MAPPING,
} from "../../utils/constants";
import useAuth from "../../utils/useAuth";
import BorderedText from "../BorderedText";
import Button from "../Button";
import SimpleSelect from "../FormInputs/SimpleSelect";
import FullPageLoader from "../Loaders/FullPageLoader";
import MetaBar from "../MetaBar";
import AddMeeting from "../Modals/AddMeeting";
import RemoveConfirmation from "../RemoveConfirmation/RemoveConfirmation";
import Title from "../Title";
import scrollIntoView from "scroll-into-view";
import InformationMeetingTable from "../Meetings/InformationMeetingTable";
import { countWords } from "../../utils/helper";
import usePhase from "../../utils/usePhase";

const InformationMeetingAgendaStatus = ({
  currentStatus,
  handleUpdate,
  meetingAgendaStatus,
}) => {
  const agendaStatusItems = meetingAgendaStatus.map((agendaStatus) => {
    const handleClick = () => handleUpdate(agendaStatus);
    const buttonStyle = {
      ...(currentStatus.id === agendaStatus.id && {
        borderColor: agendaStatus.color,
        color: agendaStatus.color,
      }),
    };
    return (
      <Button
        type="button"
        className="inline-flex items-center px-4 py-3 border border-gray-300 shadow-sm text-base leading-4 font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-50 ml-3"
        style={buttonStyle}
        onClick={handleClick}
        key={agendaStatus.id}
      >
        {agendaStatus.name}
      </Button>
    );
  });
  return <div>{agendaStatusItems}</div>;
};

const InformationMeetingAgendaItems = ({
  agendas,
  meetingAgendaStatus,
  handleAgendaUpdate,
}) => {
  const agendaItems = agendas.map((agenda) => {
    const { id, name, agenda_status } = agenda;

    const handleUpdate = (status) => {
      const newAgendaData = {
        ...agenda,
        agenda_status: status,
      };
      handleAgendaUpdate(newAgendaData);
    };

    return (
      <li key={id} className="flex items-center justify-between mb-4">
        <span className="text-base max-w-sm lg:max-w-auto">{name}</span>
        <InformationMeetingAgendaStatus
          handleUpdate={handleUpdate}
          meetingAgendaStatus={meetingAgendaStatus}
          currentStatus={agenda_status || {}}
        />
      </li>
    );
  });
  return <ul className="mt-6 list-none	p-0 m-0">{agendaItems}</ul>;
};

const InformationMeetingForm = ({
  selectedMeeting,
  meetingStatus,
  handleMeetingUpdate,
  submitting,
  meetingAgendaStatus,
  handleSelectedMeetingAgendaUpdate,
}) => {
  if (!selectedMeeting || !meetingStatus) return null;

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      ...selectedMeeting,
      remark: values.remark,
      meeting_status: meetingStatus,
    };
    handleMeetingUpdate(payload);
    setSubmitting(false);
  };

  const handleAgendaUpdate = (newAgendaData) =>
    handleSelectedMeetingAgendaUpdate(newAgendaData);

  const validateRemarks = (value) => {
    let error;
    if (
      meetingStatus?.comment?.required &&
      meetingStatus?.comment?.word_count &&
      countWords(value) < meetingStatus?.comment?.word_count
    ) {
      error = `Please enter minimum ${meetingStatus?.comment?.word_count} words`;
    }
    return error;
  };

  return (
    <div id="meeting-form" className="mt-8">
      <BorderedText text="Meeting Agendas &amp; Discussion Points" />
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize={true}
        initialValues={{ remark: selectedMeeting.remark || "" }}
      >
        {({ isSubmitting, values, errors, touched, validateField }) => {
          const commentCount = meetingStatus?.comment?.word_count
            ? countWords(values.remark)
            : undefined;

          const commentCountString =
            typeof commentCount !== "undefined"
              ? `${commentCount} word${commentCount <= 1 ? "" : "s"}`
              : null;

          useEffect(() => {
            if (meetingStatus?.comment?.required) {
              validateField("remark");
            }
          }, [meetingStatus]);
          return (
            <Form>
              {meetingStatus.status === MEETING_STATUS_BACKEND_MAPPING.DONE && (
                <InformationMeetingAgendaItems
                  meetingAgendaStatus={meetingAgendaStatus}
                  handleAgendaUpdate={handleAgendaUpdate}
                  agendas={selectedMeeting.meeting_agendas || []}
                />
              )}
              {commentCountString && (
                <p className="-mb-4 text-right text-xs text-gray-500">
                  {commentCountString}
                </p>
              )}
              <Field
                component="textarea"
                maxLength={2000}
                placeholder={
                  meetingStatus?.comment?.placeholder || "Remarks (if any)"
                }
                className="w-full resize-none rounded-lg mt-10 h-28"
                required={meetingStatus?.comment?.required || false}
                validate={validateRemarks}
                name="remark"
              />
              {errors.remark && touched.remark && (
                <p className="text-right text-red-500 text-xs">
                  {errors.remark}
                </p>
              )}
              <div className="mt-7 flex items-center justify-end">
                <Button.Rounded
                  type="submit"
                  disabled={submitting || isSubmitting}
                >
                  {submitting || isSubmitting ? "Saving..." : "Save"}
                </Button.Rounded>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const INITIAL_VALUES = {
  meeting: "",
  status: "",
};

const InformationMeeting = ({
  meetingTypes,
  meeting,
  handleMeetingChange,
  handleFetchingMeeting,
}) => {
  const auth = useAuth();
  const { currentPermission } = auth;
  const { clusterId, pcId, visitId, reportType } = useParams();
  const phase = usePhase();
  const [fetching, setFetching] = useState(false);
  const [meetingForm, setMeetingForm] = useState(INITIAL_VALUES);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [meetingData, setMeetingData] = useState(null);
  const [meetingStatus, setMeetingStatus] = useState([]);
  const [meetingAgendaStatus, setMeetingAgendaStatus] = useState([]);
  const [meetingPriorities, setMeetingPriorities] = useState([]);
  const [meetingMeta, setMeetingMeta] = useState([]);
  const [meetingToBeRemoved, setMeetingToBeRemoved] = useState(null);
  const [isAddingNewCustomMeeting, setIsAddingNewCustomMeeting] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isUpdatingCustomMeeting, setIsUpdatingCustomMeeting] = useState(null);

  const meetingsOptions = useMemo(() => {
    if (!meetingData || (meetingData && !meetingData.meetings)) return [];
    const arr = Object.keys(meetingData.meetings)
      .map((key) => meetingData.meetings[key])
      .filter(
        (meeting) => meeting.defined_by === DEFINED_BY_BACKEND_MAPPING.SYSTEM
      )
      .map((meeting) => {
        const { id, name } = meeting;
        return (
          <option key={id} value={id}>
            {name}
          </option>
        );
      });
    return arr;
  }, [meetingData]);

  const meetingStatusItems = meetingStatus
    .filter(
      (status) => status.status !== MEETING_STATUS_BACKEND_MAPPING.PENDING
    )
    .map((meetingStatus) => {
      const { id, name } = meetingStatus;
      return (
        <option key={id} value={id}>
          {name}
        </option>
      );
    });

  useEffect(() => {
    const getMeetingItems = async () => {
      if (!meeting || !currentPermission || fetching) return;
      setFetching(true);
      handleFetchingMeeting(true);
      const pcItem = {
        reportId: meeting.id,
        clusterId,
        pcId,
        currentPermission,
        visitId,
        reportType,
        phase,
      };
      const meetingItemsData =
        await parliamentConstituencies.getParliamentryConstituencyVisitReport(
          pcItem
        );
      setFetching(false);
      handleFetchingMeeting(false);
      setSelectedMeeting(null);
      setMeetingData(meetingItemsData);
      setMeetingMeta(meetingItemsData?.meta || []);
      setMeetingForm(INITIAL_VALUES);
    };

    getMeetingItems();
  }, [meeting, currentPermission]);

  const handleUpdateMeetingMeta = async () => {
    const pcItem = {
      reportId: meeting.id,
      clusterId,
      pcId,
      currentPermission,
      visitId,
      reportType,
      phase,
    };
    const meetingItemsData =
      await parliamentConstituencies.getParliamentryConstituencyVisitReport(
        pcItem
      );
    setMeetingMeta(meetingItemsData?.meta || []);
    setMeetingData(meetingItemsData);
  };

  useEffect(() => {
    if (meetingData?.meetings) {
      setSelectedMeeting(meetingData.meetings[meetingForm.meeting] || null);
    }
  }, [meetingForm.meeting]);

  useEffect(() => {
    const getMeetingStatus = async () => {
      if (!currentPermission) return;
      const meetingStatusData = await reportItems.fetchMeetingStatus({
        currentPermission,
        phase,
      });
      setMeetingStatus(meetingStatusData?.data?.data || []);
    };
    const getMeetingAgendaStatus = async () => {
      if (!currentPermission) return;
      const meetingAgendaStatusData =
        await reportItems.fetchMeetingAgendaStatus({
          currentPermission,
          phase,
        });
      setMeetingAgendaStatus(meetingAgendaStatusData?.data?.data || []);
    };
    const getMeetingPriorities = async () => {
      if (!currentPermission) return;
      const meetingPrioritiesData = await reportItems.fetchMeetingPriorities({
        currentPermission,
        phase,
      });
      setMeetingPriorities(meetingPrioritiesData?.data?.data || []);
    };
    getMeetingStatus();
    getMeetingAgendaStatus();
    getMeetingPriorities();
  }, [currentPermission]);

  const handleInputChange = (e) => {
    const {
      target: { name, value },
    } = e;
    let currentValue = value;

    if (name === "status") {
      currentValue = meetingStatus.filter(
        (status) => status.id === parseInt(value)
      )[0];
    }

    if (name === "meeting" && value === "custom_meeting") {
      setIsAddingNewCustomMeeting(true);
      return;
    }

    setMeetingForm({
      ...meetingForm,
      [name]: currentValue,
    });
  };

  const meetingsTypesOptions = meetingTypes.map((meetingType) => {
    const { name, id } = meetingType;
    return (
      <option key={id} value={id}>
        {name}
      </option>
    );
  });

  const handleMeetingTypeChange = (e) => {
    const selectedMeeting = meetingTypes.filter(
      (meetingType) => meetingType.id === parseInt(e.target.value)
    )[0];
    const meetingIds = meetingTypes.map((meeting) => meeting.id);
    const selectedMeetingIndex = meetingIds.indexOf(selectedMeeting.id);
    resetMeetingForm();
    handleMeetingChange(selectedMeeting, selectedMeetingIndex);
  };

  const handleSelectedMeetingAgendaUpdate = (newAgendaData) => {
    setSelectedMeeting({
      ...selectedMeeting,
      meeting_agendas: selectedMeeting.meeting_agendas.map((agenda) => {
        if (agenda.id !== newAgendaData.id) return agenda;
        return newAgendaData;
      }),
    });
  };

  const resetMeetingForm = () => {
    setMeetingForm({
      meeting: "",
      status: "",
    });
  };

  const updateMeeting = async (payload, isRemove) => {
    if (submitting) return;
    setSubmitting(true);
    const updateMeetingData =
      await parliamentConstituencies.updateOrCreateParliamentryConstituencyVisitReport(
        {
          reportId: meeting.id,
          data: payload,
          pcId,
          visitId,
          reportType,
          clusterId,
          currentPermission,
          phase,
        }
      );
    setSubmitting(false);
    if (updateMeetingData?.data?.data) {
      setSelectedMeeting(null);
      handleUpdateMeetingMeta();
      resetMeetingForm(null);
      if (isRemove) {
        toast("Meeting deleted successfully");
        return;
      }
      toast("Meeting updated successfully");
    }
  };

  const handleMeetingEdit = (meetingId) => {
    const meetingObj =
      meetingData && meetingData.meetings && meetingData.meetings[meetingId]
        ? meetingData.meetings[meetingId]
        : null;
    if (!meetingObj) return null;
    const isCustom = meetingObj.defined_by === DEFINED_BY_BACKEND_MAPPING.USER;
    const currentStatus = meetingObj?.meeting_status
      ? meetingObj?.meeting_status
      : meetingObj[`phase_${phase}`] &&
        meetingObj[`phase_${phase}`].meeting_status
      ? meetingObj[`phase_${phase}`].meeting_status
      : null;
    if (isCustom) {
      setIsUpdatingCustomMeeting(meetingObj);
      return;
    }
    const currentMeetingStatus =
      meetingStatus &&
      currentStatus &&
      meetingStatus.length &&
      meetingStatus.filter((status) => status.id === parseInt(currentStatus.id))
        .length
        ? meetingStatus.filter(
            (status) => status.id === parseInt(currentStatus.id)
          )[0]
        : null;
    setMeetingForm({
      meeting: meetingId,
      status: currentMeetingStatus,
    });
    setTimeout(() => {
      let ele = document.getElementById("meeting-form");
      if (!ele) {
        ele = document.getElementsByClassName("select-container")
          ? document.getElementsByClassName("select-container")[0]
          : null;
      }
      if (ele) {
        scrollIntoView(ele, { time: 0 });
        if (!currentMeetingStatus) {
          toast("Please select meeting status to continue...");
        }
      }
    }, 0);
  };

  const handleMeetingRemoveConfirmation = (meetingId = null) => {
    setMeetingToBeRemoved(
      meetingId && meetingData.meetings[meetingId]
        ? meetingData.meetings[meetingId]
        : null
    );
  };

  const handleMeetingRemove = () => {
    if (!meetingToBeRemoved) return false;
    const pendingStatusObj = meetingStatus.filter(
      (status) => status.status === MEETING_STATUS_BACKEND_MAPPING.PENDING
    )[0];
    updateMeeting(
      {
        ...meetingToBeRemoved,
        meeting_status: pendingStatusObj,
        remark: "",
      },
      true
    );
    setMeetingToBeRemoved(null);
  };

  const handleCreateOrUpdateMeetingModalClose = () => {
    setIsUpdatingCustomMeeting(null);
    setIsAddingNewCustomMeeting(false);
  };

  const handleCustomMeetingCreateOrUpdate = (newMeetingData) => {
    handleUpdateMeetingMeta();
    if (isUpdatingCustomMeeting) {
      handleCreateOrUpdateMeetingModalClose();
      toast("Meeting updated successfully.");
      return;
    }
    toast("Meeting created successfully.");
    handleCreateOrUpdateMeetingModalClose();
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <Title imageSrc={meeting.image} title={meeting.name} />
        <SimpleSelect
          onChange={handleMeetingTypeChange}
          className="disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={fetching}
          value={meeting.id}
        >
          {meetingsTypesOptions}
        </SimpleSelect>
      </div>
      {fetching ? (
        <div className="mt-10">
          <FullPageLoader />
        </div>
      ) : (
        <>
          <MetaBar meta={meetingMeta} />
          <div className="flex mt-9">
            <div className="select-container border-r border-gray-200 pr-10">
              <select
                onChange={handleInputChange}
                className="block font-bold max-w-xs	pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none rounded-md"
                name="meeting"
                value={meetingForm.meeting}
              >
                <option value="">Select Meeting Title</option>
                {meetingsOptions}
                <option value="custom_meeting">Create New Meeting</option>
              </select>
            </div>
            <div className="pl-10">
              <select
                onChange={handleInputChange}
                className="block font-bold pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none rounded-md"
                name="status"
                value={meetingForm?.status?.id || ""}
              >
                <option value="">Meeting Status</option>
                {meetingStatusItems}
              </select>
            </div>
          </div>
          <InformationMeetingForm
            selectedMeeting={selectedMeeting}
            handleSelectedMeetingAgendaUpdate={
              handleSelectedMeetingAgendaUpdate
            }
            handleMeetingUpdate={updateMeeting}
            submitting={submitting}
            meetingAgendaStatus={meetingAgendaStatus}
            meetingStatus={meetingForm.status}
          />
          <div className="mt-10">
            <InformationMeetingTable
              onEdit={handleMeetingEdit}
              onDelete={handleMeetingRemoveConfirmation}
              meetingData={meetingData}
            />
          </div>
        </>
      )}
      <RemoveConfirmation
        onCancel={handleMeetingRemoveConfirmation}
        onDelete={handleMeetingRemove}
        title="Remove Meeting Data"
        description={
          meetingToBeRemoved
            ? `Are you sure you want to remove meeting data for ${meetingToBeRemoved.name}`
            : ""
        }
        isOpen={!!meetingToBeRemoved}
      />
      <AddMeeting
        isOpen={!!isUpdatingCustomMeeting || isAddingNewCustomMeeting}
        onClose={handleCreateOrUpdateMeetingModalClose}
        meetingMeta={{
          clusterId,
          pcId,
          visitId,
          reportType,
          reportId: meeting.id,
        }}
        meetingStatus={meetingStatus}
        onSubmit={handleCustomMeetingCreateOrUpdate}
        editMeeting={isUpdatingCustomMeeting}
        meetingAgendaStatus={meetingAgendaStatus}
        meetingPriorities={meetingPriorities}
      />
    </div>
  );
};

InformationMeeting.propTypes = {
  meeting: PropTypes.object,
};

export default InformationMeeting;
