import classNames from "classnames";
import React from "react";
import CardsGroup from "../components/ReusableComponents/CardsGroup";
import Heading from "../components/ReusableComponents/Heading";
import LSClustersReportsTable from "../components/ReusableComponents/LSClustersReportsTable";
import Title from "../components/Title";

const DASHBOARD_TYPES_BACKEND_MAPPER = {
  HEADING: "heading",
  BLOCK_META: "block_meta",
  TABLE: "table",
  TITLE: "title",
};

const DASHBOARD_COMPONENT = {
  // [DASHBOARD_TYPES_BACKEND_MAPPER.HEADING]: Heading,
  [DASHBOARD_TYPES_BACKEND_MAPPER.TITLE]: Title,
  [DASHBOARD_TYPES_BACKEND_MAPPER.BLOCK_META]: CardsGroup,
  [DASHBOARD_TYPES_BACKEND_MAPPER.TABLE]: LSClustersReportsTable,
};

const UPDATE_DASHBOARD_DATA_MAPPER = {
  [DASHBOARD_TYPES_BACKEND_MAPPER.TITLE]: ({ title, icon }) => ({
    imageSrc: icon,
    title,
  }),
  [DASHBOARD_TYPES_BACKEND_MAPPER.TABLE]: ({
    is_searchable,
    items,
    title,
  }) => ({
    isSearchable: is_searchable || false,
    headers: items.headers || [],
    data: items.data || [],
    title,
  }),
};

const DASHBOARD_DATA_CLASSNAMES = {
  [DASHBOARD_TYPES_BACKEND_MAPPER.TITLE]: "mt-9 print:hidden",
  [DASHBOARD_TYPES_BACKEND_MAPPER.BLOCK_META]: "mt-8 print:hidden",
  [DASHBOARD_TYPES_BACKEND_MAPPER.TABLE]:
    "bg-white mt-16 rounded-lg box-border pt-4 px-6 pb-8",
  [DASHBOARD_TYPES_BACKEND_MAPPER.HEADING]: "print:hidden",
};

const DashboardItems = ({ dashboardItems, phase, ...props }) => {
  if (!dashboardItems.length) return null;
  const items = dashboardItems.map((item, index) => {
    const { type } = item;
    const Component = DASHBOARD_COMPONENT[type];

    if (!Component) return null;

    const customProps = UPDATE_DASHBOARD_DATA_MAPPER[type]
      ? UPDATE_DASHBOARD_DATA_MAPPER[type](item)
      : item;
    const componentClassname = classNames(
      DASHBOARD_DATA_CLASSNAMES[type] || ""
    );

    return (
      <div key={`dashboard-${index}`} className={componentClassname}>
        <Component {...props} phase={phase} {...customProps} item={item} />
      </div>
    );
  });
  return items;
};

export default DashboardItems;
