import React from 'react';
import PropTypes from "prop-types";
import {useDropzone} from 'react-dropzone';
import UploadBig from "images/upload-big.svg";
import UploadIcon from "images/upload.svg";
import { v4 as uuidv4 } from "uuid";
import { toast } from 'react-toastify';
import classNames from 'classnames';
import Button from '../../Button';
import { XIcon } from '@heroicons/react/outline';
import PDFIcon from 'images/pdf.svg';
import heic2any from 'heic2any';

function MultipleFileUpload({ files,
  handleAddFiles,
  handleRemoveFile,
  handleGallery,
  maxCount }) {
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
        'image/*': [],
        'application/pdf': []
    },
    ...(typeof maxCount !== 'undefined' && {
      disabled: files.length === maxCount
    }),
    onDrop: async acceptedFiles => {
      let filesToSend = [];
      let skippedFilesCount = 0;
      for (let i = 0; i < acceptedFiles.length; i++) {
        if (((files?.length || 0) + filesToSend.length) < maxCount) {
          const currentFile = acceptedFiles[i];
          if (!(currentFile.type.includes('pdf') || currentFile.type.includes('image'))) {
            skippedFilesCount = skippedFilesCount + 1;
            continue;
          }
          const isPDF  = currentFile.type.includes('pdf');
          const isHEICOrHEIF = currentFile.type.includes('heic') || currentFile.type.includes('heif');
          if (isPDF) {
            filesToSend.push(Object.assign(currentFile, {
              preview: PDFIcon,
              pdfPreview: PDFIcon,
              isPreviewPDF: true,
              customId: uuidv4(),
            }))
          } else if (isHEICOrHEIF) {
            const outputBlob = await heic2any({
              blob: currentFile,
              toType: "image/png",
              quality: 0.8
            });
            const preview = URL.createObjectURL(outputBlob);;
            const processedFile = new File([outputBlob], `${currentFile.name}.png`, {
              type: 'image/png'
            })
            filesToSend.push(Object.assign(processedFile, {
              preview,
              customId: uuidv4()
            }));
          } else {
            filesToSend.push(Object.assign(currentFile, {
              preview: URL.createObjectURL(acceptedFiles[i]),
              customId: uuidv4(),
            }))
          }
          if (skippedFilesCount) {
            toast.error(`${skippedFilesCount} unsupported file${skippedFilesCount <= 1 ? '' : 's'} skipped`);
          }
        } else {
          toast.error('Max file count reached');
          break;
        }
      }
      handleAddFiles(filesToSend);
    }
  });

  const fileThumbs = files
    && files.length 
    ? files.map((file, index) => {
      const { url, id, customId, preview, mime_type, image_path, isPreviewPDF } = file;

      const onRemove = (e) => {
        e.stopPropagation();
        const data = {
          fileId: customId || id,
          isLocalFile: !!customId,
          file
        }
        handleRemoveFile(data)
      }

      const isPDF = mime_type && mime_type.includes('pdf');

      const fileURL = isPDF ? image_path : url;

      const galleryOrPdfClick = () => {
        if (!id) return;
        if (isPDF)  {
          window.open(url);
          return;
        }
        if (!handleGallery) return;
        handleGallery(index)
      }
      
      const imageClassnames = classNames("w-80 h-72 block rounded-lg object-cover bg-white object-top");
      const containerClassnames = classNames("relative w-80 h-72 shrink-0 rounded-lg overflow-hidden mr-14 mb-14 bg-white",
        id ? "cursor-pointer" : "");
      const key = customId || id;
      return (<div onClick={galleryOrPdfClick} className={containerClassnames} key={key}>
        {
          !!id
          ? <img className={imageClassnames} src={fileURL} />
          : <img className={imageClassnames}
              src={preview}
            />
        }
        <Button onClick={onRemove} type="button" className="absolute top-2.5 right-2.5">
          <XIcon className="h-8 w-8 text-white drop-shadow-md" />
        </Button>
      </div>)
    })
    : null;

  const bannerClassName = classNames(files &&files.length 
    ? "mt-14 flex items-center justify-center"
    : "mt-5 min-h-[456px] border border-gray-700 flex flex-col items-center py-9 box-border border-dashed rounded-lg",
    typeof maxCount !== 'undefined' && files && files.length === maxCount ? 'opacity-50 cursor-not-allowed	' : '',
    'cursor-pointer');

  return (
    <section>
      {fileThumbs && <div className="flex items-center justify-center flex-wrap mt-9">{fileThumbs}</div>}
      <div {...getRootProps({ className: bannerClassName })}>
        { (!files || (files && !files.length)) 
          && <img width={277} height={222} src={UploadBig} alt="upload" /> }
        <input multiple={true} {...getInputProps()} />
        { 
          (!files || (files && !files.length)) 
          && <p className="mt-8 font-medium text-[#828282] text-lg mb-12">You can Drag and Drop your files here</p> }
        <img width={305} height={65} src={UploadIcon} alt="upload" />
      </div>
      <aside>
      </aside>
    </section>
  );
}

MultipleFileUpload.propTypes = {
    files: PropTypes.array,
    maxCount: PropTypes.number,
    handleAddFiles: PropTypes.func.isRequired,
    handleRemoveFile: PropTypes.func.isRequired,
    handleGallery: PropTypes.func
}

MultipleFileUpload.propTypes = {
  handleGallery: () => {}
}

export default MultipleFileUpload;