import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { CLUSTER_LINKS, linksWithPhase } from "../../utils/internalLinks";

const PhaseItem = ({ phase }) => {
  const { name, icon, id, percentage } = phase;
  const buttonTitle = percentage ? "Update/Edit" : "Start Now";
  const link = linksWithPhase(id, CLUSTER_LINKS.ALL);
  return (
    <div className="min-h-80 w-[360px] shrink-0 p-8 bg-white shadow text-center rounded-lg xl:px-10">
      {icon && <img className="mx-auto w-24 h-auto" src={icon} alt={name} />}

      <h2 className="text-4xl poppins font-medium mt-4">{phase.name}</h2>
      <p className="font-medium text-sm mt-1.5">
        Click to get all the details of {phase.name}
      </p>
      <Button.Rounded
        as={Link}
        to={link}
        className={`inline-block bg-gradient-to-t min-w-[162px] mt-8 to-[#5AA3F3] from-[#3B6CE4]`}
      >
        {buttonTitle}
      </Button.Rounded>
    </div>
  );
};

export default PhaseItem;
