import classNames from "classnames";
import CheckedImage from "images/checked.svg";
import OrangeEllipsis from "images/orange_ellipsis.svg";
import ParliamentImage from "images/parliament.svg";
import RedEllipsis from "images/red_ellipsis.svg";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Button from "../../components/Button";
import CircleProgress from "../../components/CircleProgress";
import Title from "../../components/Title";
import { CLUSTER_LINKS } from "../../utils/internalLinks";
import usePhase from "../../utils/usePhase";

const ClusterItem = ({ cluster, onPercentageClick }) => {
  const currentPhase = usePhase();
  const {
    id: clusterId,
    name,
    phase_list,
    parliament_constituencies,
  } = cluster;
  const pcItems = parliament_constituencies.map((pc) => {
    const { name, percentage, number, state, id: pcId } = pc;
    const buttonTitle = percentage
      ? percentage === 100
        ? "View Report"
        : percentage === 0
        ? "Start Now"
        : "Update/Edit"
      : "Start Now";
    const link =
      percentage === 100
        ? CLUSTER_LINKS.REPORT(clusterId, pcId, currentPhase)
        : CLUSTER_LINKS.SINGLE_LOK_SABHA(clusterId, pcId, currentPhase);
    const percentageIcon = !percentage
      ? RedEllipsis
      : percentage === 100
      ? null
      : OrangeEllipsis;
    const canClick = percentage !== 100;
    const percentageCompletionText = !percentage ? (
      <p className="mt-7 pt-0.5 text-xs text-ls-dark-gray">
        <span className="text-red-500">Not Started?</span> Click below to start
      </p>
    ) : percentage === 100 ? (
      <p className="mt-7 pt-0.5 text-xs text-ls-dark-gray">
        Yay ! You have successfully completed
      </p>
    ) : (
      <p className="mt-7 pt-0.5 text-xs text-ls-dark-gray">
        Only {parseInt(100 - percentage)}% left, complete now
      </p>
    );
    const buttonGradient =
      percentage === 100
        ? "to-[#32BA7C] from-[#0AA06E] shadow-[#32BA7C]"
        : "to-[#5AA3F3] from-[#3B6CE4]";
    const phaseItems = phase_list
      ? phase_list.map((phase) => {
          const phaseReportLink = CLUSTER_LINKS.REPORT_PDF(
            clusterId,
            pcId,
            phase.id
          );
          return (
            <Button.Rounded
              as={Link}
              className="to-white border poppins border text-[#262626] border-[#262626] text-lg from-white shadow-white"
              to={phaseReportLink}
              key={phase.id}
            >
              {phase.name} Report
            </Button.Rounded>
          );
        })
      : null;

    const handleClick = () => {
      if (!canClick) return;
      onPercentageClick(clusterId, pcId);
    };

    const className = classNames(
      "rounded-full w-44	h-44 p-3.5 box-border -mt-24 mx-auto bg-white",
      canClick && "cursor-pointer"
    );
    return (
      <div
        key={pcId}
        className="mt-20 p-4 bg-white shadow text-center rounded-lg xl:px-10"
      >
        <div
          onClick={handleClick}
          data-tip={canClick ? `Click to view the \n Pending Task details` : ""}
          className={className}
        >
          {!percentage || (percentage && percentage !== 100) ? (
            <CircleProgress percentage={percentage || 0} />
          ) : (
            <img src={CheckedImage} alt="completed" />
          )}
        </div>
        <p className="flex items-center justify-center mt-3.5">
          <img className="mr-4 block" src={ParliamentImage} alt={name} />
          <span className="flex flex-col">
            <span className="text-lg font-bold">
              {number} - {name}
            </span>
            {state && <span className="text-xs mt-">{state}</span>}
          </span>
          {percentageIcon && (
            <img className="ml-3 block" src={percentageIcon} alt={percentage} />
          )}
        </p>
        {percentageCompletionText}
        <div className="flex flex-col gap-y-4">
          <Button.Rounded
            as={Link}
            to={link}
            className={`inline-block bg-gradient-to-t min-w-[162px] mt-3.5 ${buttonGradient}`}
          >
            {buttonTitle}
          </Button.Rounded>
          {phaseItems}
        </div>
      </div>
    );
  });
  return (
    <div className="mb-[34px]">
      <Title
        title={`Lok Sabha coming under ${name}`}
        description="Select a Lok Sabha to proceed"
      />
      <div className="my-[32px] sm:grid sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 lg:gap-8">
        {pcItems}
      </div>
      <ReactTooltip />
    </div>
  );
};

ClusterItem.propTypes = {
  cluster: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    description: PropTypes.string,
    parliament_constituencies: PropTypes.array,
  }),
};

export default React.memo(ClusterItem);
