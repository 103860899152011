import React from "react";
import TaskItem from "./task-item";

function Tasks({ tasks }) {
  if (!tasks || (tasks && !tasks.length)) return null;
  const taskItems = tasks.map((task, i) => {
    return <TaskItem key={i} task={task} />;
  });

  return <div className="flex flex-col gap-y-6">{taskItems}</div>;
}

export default Tasks;
