export const EXTERNAL_LINKS = {
  PHASES: {
    ALL: `phases`, // GET
  },
  CLUSTERS: {
    SINGLE: `clusters`, // GET
    PC: (clusterId, pcId) =>
      `clusters/${clusterId}/parliamentry-constituencies/${pcId}`, // GET
    PC_PERCENTAGE_DETAILS: ({ clusterId, pcId }) =>
      `${EXTERNAL_LINKS.CLUSTERS.PC(clusterId, pcId)}/percentage-details`, // GET
    PC_INSIGHTS: ({ clusterId, pcId }) =>
      `clusters/${clusterId}/parliamentry-constituencies/${pcId}/insight`, // GET
    PC_VISIT: (clusterId, pcId) =>
      `${EXTERNAL_LINKS.CLUSTERS.PC(clusterId, pcId)}/visits`, // POST | GET,
    PC_VISIT_ID: ({ clusterId, pcId, visitId }) =>
      `${EXTERNAL_LINKS.CLUSTERS.PC_VISIT(clusterId, pcId)}/${visitId}`, // GET | PATCH | DELETE
    PC_SINGLE_VISIT: ({ clusterId, pcId, visitId, reportType, reportId }) =>
      `${EXTERNAL_LINKS.CLUSTERS.PC_VISIT(
        clusterId,
        pcId
      )}/${visitId}/report-items/${reportType}/${reportId}`,
    VIDHAN_SABHA: {
      SECTION: ({ clusterId, pcId, visitId, reportType, sectionId }) =>
        `${EXTERNAL_LINKS.CLUSTERS.PC_VISIT(
          clusterId,
          pcId
        )}/${visitId}/report-items/${reportType}/section/${sectionId}`, // GET | POST
    },
    FORM: ({ clusterId, pcId, visitId, reportType }) =>
      `${EXTERNAL_LINKS.CLUSTERS.PC_VISIT(
        clusterId,
        pcId
      )}/${visitId}/report-items/${reportType}`, // GET | POST
    REPORT: ({ clusterId, pcId }) =>
      `${EXTERNAL_LINKS.CLUSTERS.PC(clusterId, pcId)}/report`, // GET
    REPORT_PDF: ({ clusterId, pcId }) =>
      `${EXTERNAL_LINKS.CLUSTERS.REPORT({ clusterId, pcId })}/pdf`, // GET
  },
  OVERALL_FEEDBACK: {
    QUESTIONS: ({ clusterId, pcId, visitId, reportType }) =>
      `${EXTERNAL_LINKS.CLUSTERS.PC_VISIT(
        clusterId,
        pcId
      )}/${visitId}/report-items/${reportType}/question`, // GET
  },
  REPORT_ITEMS: {
    SINGLE: `report-items`, // GET
    SINGLE_TYPE: (reportType) => `report-items/${reportType}`, // GET
    MEETING_AGENDA_STATUS: `agenda-status`, // GET
    MEETING_PRIORITIES: `priority-list`, // GET
    MEETING_STATUS: `meeting-status`, // GET
  },
  MEDIA: {
    SINGLE_FILE: ({ clusterId, pcId, visitId, reportType, fileId }) =>
      `${EXTERNAL_LINKS.CLUSTERS.FORM({
        clusterId,
        pcId,
        visitId,
        reportType,
      })}/${fileId}`,
    FOLDER: ({ clusterId, pcId, visitId, reportType }) =>
      `${EXTERNAL_LINKS.CLUSTERS.FORM({
        clusterId,
        pcId,
        visitId,
        reportType,
      })}/folder`, // POST | GET
    SINGLE_FOLDER: ({ clusterId, pcId, visitId, reportType, folderId }) =>
      `${EXTERNAL_LINKS.MEDIA.FOLDER({
        clusterId,
        pcId,
        visitId,
        reportType,
      })}/${folderId}`, // GET | POST | DELETE
    ATTACHMENT: ({ clusterId, pcId, visitId, reportType, folderId }) =>
      `${EXTERNAL_LINKS.MEDIA.SINGLE_FOLDER({
        clusterId,
        pcId,
        visitId,
        reportType,
        folderId,
      })}/attach`, // GET
    SINGLE_ATTACHMENT: ({
      clusterId,
      pcId,
      visitId,
      reportType,
      folderId,
      attachmentId,
    }) =>
      `${EXTERNAL_LINKS.MEDIA.ATTACHMENT({
        clusterId,
        pcId,
        visitId,
        reportType,
        folderId,
      })}/${attachmentId}`, // DELETE
  },
  VIDHAN_SABHA: {
    SECTION: ({ clusterId, pcId, visitId, reportType }) =>
      `${EXTERNAL_LINKS.CLUSTERS.PC_VISIT(
        clusterId,
        pcId
      )}/${visitId}/report-items/${reportType}`,
  },
  DASHBOARD: {
    NATIONAL: `dashboard/national`, // GET
    STATE: `dashboard/state`, // GET
  },
  PRAVAS_DAYS: {
    QUESTION: ({ clusterId, pcId, visitId, reportType, questionId }) =>
      `${EXTERNAL_LINKS.CLUSTERS.FORM({
        clusterId,
        pcId,
        visitId,
        reportType,
      })}/question/${questionId}`, //POST
    QUESTION_VALUE: ({
      clusterId,
      pcId,
      visitId,
      reportType,
      questionId,
      valueId,
    }) =>
      `${EXTERNAL_LINKS.CLUSTERS.FORM({
        clusterId,
        pcId,
        visitId,
        reportType,
      })}/question/${questionId}/value/${valueId}`, //POST | DELETE
  },
  STATE: {
    LIST: `state/list`, // GET
  },
  VISTARAK: {
    DASHBOARD: "dashboard/overview", // GET
    FILTERS: {
      ZONAL_INCHARGE: "dashboard/filter/zonal_incharge", // GET
      STATE: "dashboard/filter/state", // GET
      PC: "dashboard/filter/pc", // GET
      AC: "dashboard/filter/ac", // GET
    },
    REPORT: "dashboard/report", //GET
    REPORT_EXCEL: `dashboard/report/excel`,
    TASK: {
      OVERVIEW: ({ pcId, taskType }) =>
        `dashboard/report/pc/${pcId}/task/${taskType}/overview`, // GET
      REPORT: ({ pcId, taskType }) =>
        `dashboard/report/pc/${pcId}/task/${taskType}`, // GET
      MEETING: {
        REVIEW: ({ meetingId }) => `dashboard/task/meeting/${meetingId}/review`, // POST
        MEDIA: ({ meetingId }) => `dashboard/task/meeting/${meetingId}/media`, //GET
        PARTICIPANTS: ({ meetingId }) =>
          `dashboard/task/meeting/${meetingId}/participants`, // GET
      },
    },
  },
};
