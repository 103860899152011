import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CircleStepper from "../../components/CircleStepper/CircleStepper";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import Success from "../../components/Modals/Success/Success";
import NotAuthorized from "../../components/NotAuthorized";
import Title from "../../components/Title";
import VidhanSabhaForm from "../../components/VidhanSabhaForm";
import {
  fetchVidhanSabhaSectionFormSection,
  fetchVidhanSabhaSectionFormSections,
  updateVidhanSabhaSectionFormSection,
} from "../../services/vidhanSabha";
import { ERROR_STATUS_CODE } from "../../utils/constants";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../utils/internalLinks";
import useAuth from "../../utils/useAuth";
import usePhase from "../../utils/usePhase";

const VidhanSabha = () => {
  const auth = useAuth();
  const { currentPermission } = auth;
  const navigate = useNavigate();
  const { clusterId, pcId, visitId, reportType } = useParams();
  const currentPhase = usePhase();
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);
  const [stepForm, setStepForm] = useState(null);
  const [acId, setAcId] = useState(null);
  const [successFormShow, setSuccessFormShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [sectionFetching, setSectionFetching] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const handlePrev = () => setCurrentStep(currentStep - 1);

  const handleNext = async () => {
    if (!acId) {
      toast.error("Please Select Vidhan Sabha");
      return;
    }
    // checking if required fields have value or not
    const anyPendingRequiredField =
      stepForm?.form && stepForm?.form.filter((_) => !!_.required && !_.value);
    if (anyPendingRequiredField?.length) {
      toast.error("Please fill all mandatory fields");
      return;
    }
    if (isSubmitting) return;
    setIsSubmitting(true);
    // api calls
    const data = {
      ...stepForm,
      ...(acId && {
        ac_id: acId,
      }),
    };
    const response = await updateVidhanSabhaSectionFormSection({
      sectionId: stepForm.id,
      phase: currentPhase,
      currentPermission,
      clusterId,
      pcId,
      visitId,
      reportType,
      data,
    });
    setIsSubmitting(false);
    if (response) {
      // in case of user cant proceed to the next step
      // i.e. status code of 303 see other
      // finally submit the form
      if (response.isAxiosError) {
        if (response?.response?.status === ERROR_STATUS_CODE.SEE_OTHER) {
          setSuccessFormShow(true);
          return;
        }
      }
      toast.success("Data updated successfully");
      if (currentStep + 1 === steps.length) {
        setSuccessFormShow(true);
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      toast.error("Error saving form");
    }
  };

  useEffect(() => {
    const getVidhanSabhaSections = async () => {
      if (!currentPermission || !currentPhase || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      const response = await fetchVidhanSabhaSectionFormSections({
        phase: currentPhase,
        currentPermission,
        clusterId,
        pcId,
        visitId,
        reportType,
      });
      setFetching(false);
      if (response.isAxiosError) {
        if (response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED) {
          setIsUnauthorized(true);
          return;
        }
        toast.error("Something went wrong!");
        return;
      }
      if (response && response.length) {
        setSteps(response);
        setCurrentStep(0);
      }
    };
    getVidhanSabhaSections();
  }, [currentPermission, currentPhase]);

  const getVidhanSabhaSection = async () => {
    if (sectionFetching) return;
    setSectionFetching(true);
    setIsUnauthorized(false);
    const currentStepItem = steps[currentStep];
    const params = {
      ...(acId && {
        ac_id: acId,
      }),
    };
    const response = await fetchVidhanSabhaSectionFormSection({
      sectionId: currentStepItem.id,
      phase: currentPhase,
      currentPermission,
      clusterId,
      pcId,
      visitId,
      reportType,
      params,
    });
    if (response.isAxiosError) {
      if (response?.response?.status === ERROR_STATUS_CODE.NOT_AUTHORIZED) {
        setIsUnauthorized(true);
        return;
      }
      toast.error("Something went wrong!");
      return;
    }
    setSectionFetching(false);
    if (response) {
      setStepForm(response);
    }
  };

  useEffect(() => {
    const sectionData = () => {
      if (typeof currentStep === "number") {
        getVidhanSabhaSection();
      }
    };
    sectionData();
  }, [currentStep]);

  const updateForm = (newForm) => {
    setStepForm({
      ...stepForm,
      form: newForm,
    });
  };

  const handleSuccessFormClose = () => {
    setSuccessFormShow(false);
    navigate(
      PARLIAMENT_CONSTITUENCY_LINKS.VISIT(
        clusterId,
        pcId,
        visitId,
        currentPhase
      )
    );
  };

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  return (
    <div>
      <Title title="Vidhan Sabha wise Information" />
      <div>
        <div className="mt-16 lg:max-w-[873px] max-w-[580px] mx-auto">
          <CircleStepper currentStep={currentStep} steps={steps} />
        </div>
        {stepForm && (
          <VidhanSabhaForm
            handleNext={handleNext}
            handlePrev={handlePrev}
            isSubmitting={isSubmitting}
            handleUpdate={updateForm}
            fetching={sectionFetching}
            currentStep={currentStep}
            handleACId={setAcId}
            totalSteps={steps.length}
            disablePrev={currentStep === 0 || isSubmitting}
            isFinalStep={currentStep === steps.length - 1}
            stepForm={stepForm}
          />
        )}
      </div>
      <Success
        isOpen={successFormShow}
        title="Form submitted successfully"
        onClose={handleSuccessFormClose}
        showButton={true}
        buttonText="Close"
      />
    </div>
  );
};

export default VidhanSabha;
