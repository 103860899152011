import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import MultipleFileUpload from "../FormInputs/FileUpload/MultipleFileUpload";
import BorderedText from "../BorderedText";
import TextArea from "../FormInputs/TextArea";
import Button from "../Button";
import RemoveConfirmation from "../RemoveConfirmation/RemoveConfirmation";
import {
  deleteFolderAttachments,
  updateFolderAttachments,
} from "../../services/media";
import { useParams } from "react-router-dom";
import useAuth from "../../utils/useAuth";
import { toast } from "react-toastify";
import { REPORT_TYPE_BACKEND_MAPPER } from "../ClusterReportItems/constants";
import usePhase from "../../utils/usePhase";

const FORM_TYPES = {
  FILE: "file",
  TEXTAREA: "textarea",
};

const FolderForm = ({ form, onSubmit, handleGallery, folderId }) => {
  if (!form.length) return null;
  const auth = useAuth();
  const currentPhase = usePhase();
  const { clusterId, pcId, visitId, reportType } = useParams();
  const { currentPermission } = auth;
  const [formItems, setFormItems] = useState(form);
  const [deleting, setDeleting] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fileToBeRemoved, setFileToBeRemoved] = useState(null);

  useEffect(() => {
    const transformForm = () => {
      const formData = form.map((item) => {
        const customId = uuidv4();
        return {
          ...item,
          customId,
        };
      });
      setFormItems(formData);
    };
    transformForm();
  }, [form]);

  if (!formItems.length) return null;

  const addFilesToFormItem = (id, files) => {
    const newFormItems = formItems.map((item) => {
      if (item.customId !== id) return item;
      return {
        ...item,
        value: item.value ? item.value.concat(files) : files,
      };
    });
    setFormItems(newFormItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    let formData = new FormData();
    formItems.forEach((item) => {
      if (item.type === FORM_TYPES.FILE) {
        const { value } = item;
        if (value && value.length) {
          value.forEach((file) => {
            if (file.customId) {
              formData.append(`${item.name}[]`, file);
            }
          });
        }
      }
      if (item.type === FORM_TYPES.TEXTAREA) {
        formData.append(item.name, item.value ? item.value : "");
      }
    });
    const response = await updateFolderAttachments({
      phase: currentPhase,
      reportType: reportType || REPORT_TYPE_BACKEND_MAPPER.MEDIA,
      folderId,
      currentPermission,
      clusterId,
      pcId,
      visitId,
      formData,
    });
    setSubmitting(false);
    if (response.isAxiosError) {
      toast.error("Something went wrong");
      return;
    }
    if (response) {
      toast.success("Files uploaded successfully");
      onSubmit(response);
    }
  };

  const handleFileRemove = ({ fieldId, fileId, isLocalFile, file }) => {
    if (isLocalFile) {
      const newFormItems = formItems.map((item) => {
        if (item.customId !== fieldId) return item;
        return {
          ...item,
          value: item.value.filter((file) => file.customId !== fileId),
        };
      });
      setFormItems(newFormItems);
      return;
    }
    setFileToBeRemoved({ ...file, fieldId });
  };

  const closeFileToBeRemoveModal = () => setFileToBeRemoved(null);

  const handleFileRemoveConfirmation = async () => {
    if (!fileToBeRemoved || deleting) return;
    setDeleting(true);
    let response = await deleteFolderAttachments({
      phase: currentPhase,
      reportType: reportType || REPORT_TYPE_BACKEND_MAPPER.MEDIA,
      attachmentId: fileToBeRemoved.id,
      currentPermission,
      clusterId,
      pcId,
      visitId,
      folderId,
    });
    setDeleting(false);
    if (response.isAxiosError) {
      toast.error("Something went wrong");
      return;
    }
    if (response) {
      let newFormItems = formItems.map((item) => {
        if (item.customId !== fileToBeRemoved.fieldId) return item;
        return {
          ...item,
          value: item.value.filter((file) => file.id !== fileToBeRemoved.id),
        };
      });
      setFormItems(newFormItems);
    }
    closeFileToBeRemoveModal();
  };

  const handleInputChange = (fieldId, value) => {
    let newFormItems = formItems.map((item) => {
      if (item.customId !== fieldId) return item;
      return {
        ...item,
        value,
      };
    });
    setFormItems(newFormItems);
  };

  const folderItems = formItems.map((item, index) => {
    const { value, count, customId, required } = item;

    const addFiles = (files) => {
      addFilesToFormItem(customId, files);
    };

    const removeFiles = (rest) => {
      handleFileRemove({ fieldId: customId, ...rest });
    };

    const inputChange = (e) => handleInputChange(customId, e.target.value);

    return (
      <div key={customId || index}>
        {item.type === FORM_TYPES.FILE && (
          <div>
            <MultipleFileUpload
              handleAddFiles={addFiles}
              handleRemoveFile={removeFiles}
              handleGallery={handleGallery}
              files={value || []}
              maxCount={count}
            />
          </div>
        )}
        {item.type === FORM_TYPES.TEXTAREA && (
          <div className="mt-9">
            <BorderedText text="Picture Description" />
            <div className="px-3.5 box-border mt-4">
              <TextArea
                value={value}
                onChange={inputChange}
                name="comment"
                required={required || false}
                placeholder={form.placeholder}
              />
            </div>
          </div>
        )}
      </div>
    );
  });
  return (
    <form onSubmit={handleSubmit}>
      {folderItems}
      <div className="items-center flex mt-16 justify-center">
        <Button.Rounded disabled={submitting} type="submit">
          Save Folder
        </Button.Rounded>
      </div>
      <RemoveConfirmation
        onCancel={closeFileToBeRemoveModal}
        onDelete={handleFileRemoveConfirmation}
        title="Remove File"
        deleteBtnText={deleting ? "Deleting..." : "Delete"}
        deleting={deleting}
        description="Are you sure you want to remove the file?"
        isOpen={!!fileToBeRemoved}
      />
    </form>
  );
};

FolderForm.propTypes = {
  form: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleGallery: PropTypes.func,
};

export default FolderForm;
