import React from "react";
const FullPageLoader = () => {
  return (<div className="rounded-md  w-full mx-auto">
    <div className="animate-pulse ">
        <div className="h-10 w-2/3 bg-gray-200 rounded"></div>
        <div className="w-full bg-gray-200 h-10 mt-5 rounded"></div>
        <div className="grid grid-cols-2 gap-3 mt-5">
        <div className=" h-32 bg-gray-200 rounded"></div>
        <div className=" h-32 bg-gray-200 rounded"></div>
        <div className=" h-32 bg-gray-200 rounded"></div>
        <div className=" h-32 bg-gray-200 rounded"></div>
        </div>
    </div>
    </div>);
    }

export default FullPageLoader;