import { Dialog } from "@headlessui/react";
import React from "react";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal/Modal";
import { XIcon } from "@heroicons/react/outline";
import BorderedText from "../../../../components/BorderedText";
import useAuth from "../../../../utils/useAuth";
import { useVistarakReportTaskParticipants } from "../../../../libs/vistarak/hooks";
import FullPageLoader from "../../../../components/Loaders/FullPageLoader";

function TaskParticipants({ isOpen, meetingId, onClose }) {
  const auth = useAuth();
  const { currentPermission } = auth;
  const { data, isLoading } = useVistarakReportTaskParticipants({
    currentPermission,
    meetingId,
    isOpen,
  });

  const thClassName =
    "p-4 align-top text-center text-base mulish text-white border-r-2 border-white bg-[#4471B0]";

  const tdClassName = "border-r-2 py-4 text-base border-white";
  const rows = data?.map((row, i) => {
    const no = i + 1;
    return (
      <tr
        key={i}
        className="bg-[#F3F7FF] text-center border-b border-b-[#9DAED2] last:border-b-0"
      >
        <td className={tdClassName}>{no}</td>
        <td className={tdClassName}>{row.name}</td>
        <td className={tdClassName}>{row.phone}</td>
        <td className={tdClassName}>{row.designation}</td>
      </tr>
    );
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 lg:max-w-screen-lg sm:max-w-sm sm:w-full sm:p-6">
        <div className="absolute top-0 right-0 hidden z-10 pt-4 pr-4 sm:block">
          <Button
            type="button"
            aria-hidden="true"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </Button>
        </div>
        <BorderedText text="Participants" />
        <div className="mt-10">
          {isLoading ? (
            <FullPageLoader />
          ) : (
            <div>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className={thClassName}>S.No.</th>
                    <th className={thClassName}>Name</th>
                    <th className={thClassName}>Number</th>
                    <th className={thClassName}>Designation</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          )}
        </div>
      </Dialog.Panel>
    </Modal>
  );
}

export default TaskParticipants;
