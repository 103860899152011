import PropTypes from "prop-types";
import React from "react";

const ReportBlock = ({ blockData }) => {
  const { title, description, image, onClick } = blockData;
  return (<div className="cursor-pointer bg-white flex items-center p-4 lg:p-6 rounded-lg shadow-sm box-border" onClick={onClick}>
    <img className="w-[80px] h-[80px] mr-6 lg:w-[100px] lg:h-[100px] block lg:mr-12 object-contain" src={image} alt={title} />
    <div>
      <h3 className="text-lg lg:text-xl font-bold">{title}</h3>
      <p className="text-xs text-gray-400 whitespace-pre-line mt-3 leading-4">{description}</p>
    </div>
  </div>);
}

ReportBlock.propTypes = {
  blockData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    onClick: PropTypes.func
  })
}

export default React.memo(ReportBlock);