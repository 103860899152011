import React from "react";
import { useParams } from "react-router-dom";
import ReportTypes from "../ReportType";

const ParliamentConstituencyVisitReportType = () => {
  const { reportType } = useParams();
  const ReportComponent = ReportTypes[reportType];
  if (!ReportComponent) return null;
  return (<ReportComponent />);
}

export default ParliamentConstituencyVisitReportType;