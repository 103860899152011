import React, { memo } from "react";
import t from "prop-types";
import { REPORT_TYPE_BACKEND_MAPPER } from "./constants";
import MetaBar from "../MetaBar";
import Title from "../Title";
import Visit from "./Visit";
import OverallFeedback from "./OverallFeedback";
import Meeting from "./Meeting";
import Media from "./Media";
import VidhanSabha from "./VidhanSabha";
import PravasDays from "./PravasDays";
import Subtitle from "./Subtitle";

const ClusterReports = ({ report }) => {
  if (!report.length) return null;
  const reportItems = report.map((item, index) => {
    const key = `report-${index}`;
    switch (item.type) {
      case REPORT_TYPE_BACKEND_MAPPER.META:
        return <MetaBar key={key} meta={item.items} />;
      case REPORT_TYPE_BACKEND_MAPPER.VISITS:
        return <Visit key={key} data={item} />;
      case REPORT_TYPE_BACKEND_MAPPER.TITLE:
        return (
          <div key={key} className="mt-9">
            <Title title={item.title} imageSrc={item?.icon || ""} />
          </div>
        );
      case REPORT_TYPE_BACKEND_MAPPER.MEETINGS:
        return (
          <Meeting
            items={item.items}
            isReadOnly={true}
            visit={item?.visit}
            key={key}
            item={item}
            type={item?.type}
            meeting={item.meeting || null}
          />
        );
      case REPORT_TYPE_BACKEND_MAPPER.VIDHAN_SABHA:
        return (
          <VidhanSabha
            data={item}
            key={key}
            visit={item?.visit}
            type={item?.type}
            tableData={item.items}
          />
        );
      case REPORT_TYPE_BACKEND_MAPPER.OVERALL_FEEDBACK:
        return (
          <OverallFeedback
            key={key}
            data={item}
            type={item?.type}
            visit={item?.visit}
            items={item?.items || []}
          />
        );
      case REPORT_TYPE_BACKEND_MAPPER.MEDIA:
        return (
          <Media
            key={key}
            type={item?.type}
            visit={item?.visit}
            items={item?.items || []}
          />
        );
      case REPORT_TYPE_BACKEND_MAPPER.PRAVAS_DAYS:
        return (
          <PravasDays
            type={item?.type}
            visit={item?.visit}
            key={key}
            isReadOnly={true}
            data={item}
          />
        );
      // case REPORT_TYPE_BACKEND_MAPPER.SUBTITLE:
      //   return <Subtitle key={key} title={item.title || ""} />;
      default:
        return null;
    }
  });
  return reportItems;
};

ClusterReports.propTypes = {
  report: t.array,
};

ClusterReports.defaultProps = {
  report: [],
};

export default memo(ClusterReports);
