import ParliamentImage from "images/parliament.svg";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import LokSabhaInsights from "../../components/LokSabha/LokSabhaInsights";
import LokSabhaVisitCreateOrUpdateForm from "../../components/LokSabha/LokSabhaVisitCreateOrUpdateForm";
import MetaBar from "../../components/MetaBar";
import NotAuthorized from "../../components/NotAuthorized";
import ReportBlock from "../../components/ReportBlock/ReportBlock";
import Title from "../../components/Title";
import { parliamentConstituencies, reportItems } from "../../services";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../utils/internalLinks";
import useAuth from "../../utils/useAuth";
import usePhase from "../../utils/usePhase";

const ReportTypes = ({ reportTypes }) => {
  const navigate = useNavigate();
  const currentPhase = usePhase();
  const { clusterId, pcId, visitId } = useParams();
  const reportTypesItems = reportTypes.map((report) => {
    const blockData = {
      title: report.name,
      description: report.description,
      onClick: () =>
        navigate(
          PARLIAMENT_CONSTITUENCY_LINKS.VISIT_TYPE(
            clusterId,
            pcId,
            visitId,
            report.report_type,
            currentPhase
          )
        ),
      image: report.image,
    };
    return <ReportBlock key={report.id} blockData={blockData} />;
  });
  return (
    <div className="mt-4 lg:mt-8 grid grid-cols-2 gap-3 lg:gap-8">
      {reportTypesItems}
    </div>
  );
};

const LokSabhaVisit = () => {
  const { clusterId, pcId, visitId } = useParams();
  const [meta, setMeta] = useState([]);
  const auth = useAuth();
  const currentPhase = usePhase();
  const [fetching, setFetching] = useState(false);
  const { currentPermission } = auth;
  const [visit, setVisit] = useState(null);
  const [pcData, setPcData] = useState(null);
  const [reportTypes, setReportTypes] = useState([]);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  useEffect(() => {
    const getParliamentConstituentiesAndReportItems = async () => {
      if (!currentPermission || !currentPhase || fetching) return;
      setFetching(true);
      setIsUnauthorized(false);
      const pcInformation =
        await parliamentConstituencies.fetchParliamentryConstituenciesDetails({
          currentPermission,
          clusterId,
          pcId,
          phase: currentPhase,
        });
      if (
        pcInformation &&
        pcInformation.isAxiosError &&
        pcInformation?.response?.status === 401
      ) {
        setFetching(false);
        setIsUnauthorized(true);
        return;
      }
      setPcData(pcInformation);
      const visitData =
        await parliamentConstituencies.fetchParliamentryConstituenciesVisit({
          currentPermission,
          clusterId,
          pcId,
          visitId,
          phase: currentPhase,
        });
      const availableReportTypes = await reportItems.fetchReportItems({
        currentPermission,
        phase: currentPhase,
      });
      setFetching(false);
      if (pcInformation) {
        setMeta(pcInformation.meta);
      }
      if (availableReportTypes?.data?.data) {
        setReportTypes(availableReportTypes.data.data);
      }
      if (visitData) {
        setVisit(visitData);
      }
    };
    getParliamentConstituentiesAndReportItems();
  }, [currentPermission, currentPhase]);

  if (fetching) return <FullPageLoader />;

  if (isUnauthorized) return <NotAuthorized />;

  const onSubmit = () => {
    toast("Visit dates updated successfully.");
  };

  const title = pcData ? `Lok Sabha - ${pcData.name}` : `Lok Sabha`;

  return (
    <div>
      <Title title={title} imageSrc={ParliamentImage} />
      {!!reportTypes.length && (
        <>
          <MetaBar meta={meta} />
          <div className="bg-white mt-6 shadow-sm p-5 box-border rounded-lg">
            <LokSabhaVisitCreateOrUpdateForm
              values={visit}
              afterSubmit={onSubmit}
            />
          </div>
          <LokSabhaInsights />
          <ReportTypes reportTypes={reportTypes} />
        </>
      )}
    </div>
  );
};

export default LokSabhaVisit;
