import React, { memo } from "react";
import t from "prop-types";
import EditBtn from "./EditBtn";
import { useParams } from "react-router-dom";
import { PARLIAMENT_CONSTITUENCY_LINKS } from "../../utils/internalLinks";
import usePhase from "../../utils/usePhase";
import OverallFeedbackTable from "../../pages/ReportType/OverallFeedback/components/OverallFeedbackTable";

const OverallFeedback = ({ type, data, visit, isReadOnly, isPDF }) => {
  const { clusterId, pcId } = useParams();
  const phase = usePhase();

  const editLink = PARLIAMENT_CONSTITUENCY_LINKS.VISIT_TYPE(
    clusterId,
    pcId,
    visit?.id || "",
    type,
    phase
  );

  return (
    <div className="bg-white shadow print:mt-2 mt-8 rounded-lg p-6 box-border">
      <OverallFeedbackTable
        isReadOnly={isReadOnly}
        isPDF={isPDF}
        overallFeedbackData={data}
      />
      {!isPDF && (
        <div className="mt-9 flex items-center justify-end">
          <EditBtn link={editLink} />
        </div>
      )}
    </div>
  );
};

OverallFeedback.propTypes = {
  type: t.string,
  isPDF: t.bool,
  visit: t.object,
};

OverallFeedback.defaultProps = {
  type: "",
  isPDF: false,
  visit: null,
};

export default memo(OverallFeedback);
