import React from "react";

const Cards = ({ title, value }) => {
  return (
    <div className="bg-white px-3 flex-1 min-w-36 shadow rounded-lg text-center flex flex-col justify-center items-center h-24">
        <p className="text-[#FF9955] whitespace-pre mb-2 font-semibold text-xs mulish">{title}</p>
        <p className="text-center font-bold text-lg poppins">{value}</p>
    </div>
  )
}

export default Cards;