import React from "react";
import LandingImage from "images/landing.svg";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../utils/useAuth";
import Button from "../../components/Button";
import { HOME_LINK } from "../../utils/internalLinks";

const Login = () => {
  const location = useLocation();
  const auth = useAuth();
  const { homeLink } = auth;

  if (auth.user) {
    return <Navigate to={homeLink || HOME_LINK} state={{ from: location }} replace />;
  }

  return <div className="min-h-screen bg-gradient-to-b lg:text-left text-center lg:flex items-center justify-around from-[#E6F0FF] to-[#FAFAFB] py-28 mx-auto max-w-8xl px-24 sm:px-6 lg:px-16 bg-neutral-50">
      <img src={LandingImage} className="lg:w-auto w-full h-auto" alt="welcome" />
      <div className="lg:w-[340px] w-full mt-16 lg:mt-0">
        <h1 className="text-[28px] text-[#23385B] font-semibold poppins">Welcome!</h1>
        <p className="poppins text-[22px] text-[#23385B] font-medium">To Cluster Incharge Reporting Portal </p>
        <Button.Rounded as="a" className="mt-10 block text-center" href={auth.signInUrl} rel="noopener noreferrer">Continue with SSO Login</Button.Rounded>
    </div>
  </div>
}

export default Login;
