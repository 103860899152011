export const HOME_LINK = "/";

export const PHASE_LINKS = {
  ALL: `/phases`,
  SINGLE: (phaseId) => `/phases/${phaseId}`,
};

export const linksWithPhase = (phase, text = "") => {
  return `${PHASE_LINKS.SINGLE(phase)}${text}`;
};

export const CLUSTER_LINKS = {
  ALL: "/clusters",
  SINGLE_LOK_SABHA: (clusterId, pcId, phase) =>
    `${linksWithPhase(
      phase
    )}/clusters/${clusterId}/parliament-constituencies/${pcId}`,
  REPORT: (clusterId, pcId, phase) =>
    `${CLUSTER_LINKS.SINGLE_LOK_SABHA(clusterId, pcId, phase)}/report`,
  REPORT_PDF: (clusterId, pcId, phase) =>
    `${CLUSTER_LINKS.REPORT(clusterId, pcId, phase)}/pdf`,
};

export const PARLIAMENT_CONSTITUENCY_LINKS = {
  VISIT: (clusterId, pcId, visitId, phase) =>
    `${CLUSTER_LINKS.SINGLE_LOK_SABHA(
      clusterId,
      pcId,
      phase
    )}/visit/${visitId}`,
  VISIT_TYPE: (clusterId, pcId, visitId, type, phase) =>
    `${PARLIAMENT_CONSTITUENCY_LINKS.VISIT(
      clusterId,
      pcId,
      visitId,
      phase
    )}/${type}`,
};

export const OVERALL_FEEDBACK_LINK = {
  ALL: ({ clusterId, pcId, visitId, reportType, phase }) =>
    `${PARLIAMENT_CONSTITUENCY_LINKS.VISIT_TYPE(
      clusterId,
      pcId,
      visitId,
      reportType,
      phase
    )}`,
  QUESTIONS: ({ clusterId, pcId, visitId, reportType, phase }) =>
    `${PARLIAMENT_CONSTITUENCY_LINKS.VISIT_TYPE(
      clusterId,
      pcId,
      visitId,
      reportType,
      phase
    )}/question`,
};

export const MEDIA_LINK = {
  SINGLE_FOLDER: ({ clusterId, pcId, visitId, reportType, folderId, phase }) =>
    `${PARLIAMENT_CONSTITUENCY_LINKS.VISIT_TYPE(
      clusterId,
      pcId,
      visitId,
      reportType,
      phase
    )}/${folderId}`,
};

export const DASHBOARD_LINKS = {
  NATIONAL: "/dashboard/national",
  STATE: "/dashboard/state",
  INCHARGE_DASHBOARD: "/dashboard/vistarak",
};

export const APP_PERMISSION_BACKEND_MAPPER = {
  CLUSTER_MANTRI: "ClusterMantri",
  CLUSTER_INCHARGE: "ClusterIncharge",
  PRAVAS_MANTRI: "PravasMantri",
  NATIONAL_DASHBOARD: "NationalDashboard",
  STATE_DASHBOARD: "StateDashboard",
  INCHARGE_DASHBOARD: "InchargeDashboard",
  VS_INCHARGE_DASHBOARD: "VSInchargeDashboard",
};

export const DEFAULT_PAGE_VS_APP_PERMISSION = {
  [APP_PERMISSION_BACKEND_MAPPER.CLUSTER_MANTRI]: PHASE_LINKS.ALL,
  [APP_PERMISSION_BACKEND_MAPPER.CLUSTER_INCHARGE]: PHASE_LINKS.ALL,
  [APP_PERMISSION_BACKEND_MAPPER.PRAVAS_MANTRI]: PHASE_LINKS.ALL,
  [APP_PERMISSION_BACKEND_MAPPER.NATIONAL_DASHBOARD]: DASHBOARD_LINKS.NATIONAL,
  [APP_PERMISSION_BACKEND_MAPPER.STATE_DASHBOARD]: DASHBOARD_LINKS.STATE,
  [APP_PERMISSION_BACKEND_MAPPER.INCHARGE_DASHBOARD]:
    DASHBOARD_LINKS.INCHARGE_DASHBOARD,
  [APP_PERMISSION_BACKEND_MAPPER.VS_INCHARGE_DASHBOARD]:
    DASHBOARD_LINKS.INCHARGE_DASHBOARD,
};

export const VISTARAK_LINKS = {
  REPORT: `/dashboard/vistarak/report`,
  REPORT_TYPE: (pcId, linkType) =>
    `/dashboard/vistarak/report/pc/${pcId}/${linkType}`,
};
