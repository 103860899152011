import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const MediaFolders = (props) => {
    const { folder, comment, value } = props;
    if (!value?.length) return null;
    const { name } = folder;
    const mediaItems = value.slice(0, 4).map((item) => {
        const customId = uuidv4();
        const { url, image_path } = item;
        const imageSrc = image_path || url;
        return <img className="w-full h-48 object-cover print:break-inside-avoid rounded-lg object-top overflow-hidden" key={customId} src={imageSrc} alt="meeting image" />
    })
    return <div className="mt-4 first:mt-0 print:break-inside-avoid">
        <p className="mb-5 poppins text-xl font-medium">{name}</p>
        <div className="grid grid-cols-4 gap-4">{mediaItems}</div>
        { !!comment && !!comment.value && <p className="text-md mt-4 whitespace-pre-line break-words">{comment.value}</p> }
    </div>
}

export default MediaFolders;