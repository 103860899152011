import React, { useState, useRef } from "react";
import UploadFrame from "images/upload-frame.svg";
import classnames from "classnames";
import Button from "../../Button";
import ImageIcon from "images/image.svg";


const FileUpload = ({ name, value, handleRemove, handleGallery }) => {
  const [dataUri, setDataUri] = useState('');
  const [fileName, setFileName] = useState('');
  const fileToDataURI = (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    reader.readAsDataURL(file);
  });

  const handleChange = async (e) => {
    let file = e.target.files ? e.target.files[0] : null;
    if (!file) return;
    let fileDataUri = await fileToDataURI(file);
    setDataUri(fileDataUri);
    setFileName(file.name);
  }

  const removeFile = (e) => {
    e.preventDefault();
    handleRemove();
  }

  const removeLocalFile = (e) => {
    e.preventDefault();
    if (fileInput && fileInput.current) {
      setDataUri('');
      setFileName('');
      fileInput.current.value = null;
    }
  }

  const fileInput = useRef();

  const imageClassname = classnames("rounded-lg object-contain w-80 h-80 rounded");

  return (<div className="relative min-h-[410px] mr-4 mb-4 border border-dashed p-4 border-[#B3B3B3] border w-80 rounded-lg box-border">
    <label className="cursor-pointer shrink-0 w-80 rounded-lg">
    {
       value
       ? <div onClick={handleGallery}>
          <img src={value.url} 
            alt="upload" 
            className={imageClassname} />
       </div>
      : dataUri
        ? <span>
          <img src={dataUri} className="rounded-lg object-contain w-80 h-80 rounded" alt="uploading" />
        </span>
        : <span className="flex flex-col items-center h-full justify-between">
            <span className="pt-9 text-center  h-full block">
              <img src={ImageIcon}  alt="upload image" />
              <span className="mulish text-lg text-[#828282] tracking-[0.08em] block">No image Uploaded</span>
            </span>
          <img src={UploadFrame} className="rounded-lg" alt="upload" />
        </span>    
    }
    {
      !(value) 
      && <input onChange={handleChange}
        ref={fileInput}
        name={name}
        readOnly={dataUri}
        accept="image/*"
        type="file"
        className="pointer-events-none absolute opacity-0"  
      />
    }
    {
      (dataUri || value) 
      && <Button as="button" 
        type="button" 
        onClick={dataUri ? removeLocalFile : removeFile}
        className="mulish bg-[#ED5F61] w-full p-2 box-border font-bold text-white tracking-[0.08em] mt-3 text-lg">
        Remove
      </Button>
    }
  </label>
  </div>)
}

export default FileUpload;